import { get } from 'lodash';
import axios from 'axios';

import AdminLoginApiValidator from 'client/api/validators/AdminLoginApiValidator';
import LoginApi from 'client/api/LoginApi';
import LoginConsts from 'client/util/Constants/LoginConstants';

import type {
  AdminLoginPayload,
  LoginChallengeResponse,
  LoginPayload,
  LoginResponse,
  NewOrgVerificationPayload,
  NewOrgVerificationResponse,
  RequestPasswordResetPayload,
  RequestPasswordResetResponse,
  ResetPasswordPayload,
  VerifyMfaResetTOTPPayload,
  VerifyTotpPayload,
  VerifyTotpResponse,
} from 'client/types/Auth';
import type {
  OidcConsentGetResponse,
  OidcConsentPostPayload,
  OidcConsentPostResponse,
} from 'client/types/OidcConsent';

const { admin } = LoginConsts.clientType;

class AdminLoginApi extends LoginApi {
  /**
   * @description Gets the status of the current admin user session.
   */
  // eslint-disable-next-line class-methods-use-this
  async getSession() {
    // not using `this` in this function because the url is off of the root,
    // and the interface that results when this is made static is ugly.
    // https://github.com/TheJumpCloud/SI/blob/90c54341b987a78fb2ab58b247d302e599fc4cc4/routes/webui/index.yaml#L1547
    const response = await axios.get('/session');

    return (response && response.data) || '';
  }

  /**
   * Primary authentication for an admin.
   * @param  {LoginPayload} payload [email, password]
   * @return {LoginResponse} [returns the promise from the axios request]
   */
  async login(payload: LoginPayload) {
    const path = (LoginConsts.authURLs as { [key: string]: string })[admin];

    try {
      return await this.axios.post<LoginResponse>(path, payload);
    } catch (error) {
      return this.retryIfInvalidCsrf<LoginResponse>(error as Error, path, payload);
    }
  }

  /**
   * Email verification for a user.
   * @param  {Object} payload [email]
   * @return {AxiosPromise} [returns the promise from the axios request]
   */
  async submitEmail(payload: AdminLoginPayload) {
    const path = LoginConsts.authURLs.adminIdentity;
    try {
      return await this.axios.post<LoginResponse>(path, payload);
    } catch (error) {
      return this.retryIfInvalidCsrf<LoginResponse>(error as Error, path, payload);
    }
  }

  /**
   * MFA authentication for an admin
   * @param {VerifyTotpPayload} payload [otp: token to be verified]
   * @return {VerifyTotpResponse} [returns the promise from the axios request]
   */
  async verifyTotp(payload: VerifyTotpPayload) {
    const path = '/auth/mfa';

    try {
      return await this.axios.post<VerifyTotpResponse>(path, payload);
    } catch (error) {
      return this.retryIfInvalidCsrf<VerifyTotpResponse>(error as Error, path, payload);
    }
  }

  /**
   * Admin registration verification when admin signs up via Google
   * @param  {NewOrgVerificationPayload} payload [containes user registration questions]
   * @return {NewOrgVerificationResponse} [returns the promise from the axios request]
   */
  newOrgVerification(payload: NewOrgVerificationPayload) {
    return this.axios.post<NewOrgVerificationResponse>('/login/new-org-verification', payload);
  }

  /**
  * Requests for a password change. Upon success the admin will
  * receive an email.
  * @param  {RequestPasswordResetPayload} payload [contains email that will receive the email]
  * @return {RequestPasswordResetResponse} [returns the promise from the axios request]
  */
  requestPasswordReset(payload: RequestPasswordResetPayload) {
    return this.axios.post<RequestPasswordResetResponse>('/passwordreset', payload);
  }

  /**
  * Requests for a TOTP change. Upon success the admin will
  * receive an email.
  * @return {undefined | RequestTOTPResetError} [returns the promise from the axios request]
  */
  requestTOTPReset() {
    // No response schema in SI, defaulting to `any`
    // https://github.com/TheJumpCloud/SI/blob/90c54341b987a78fb2ab58b247d302e599fc4cc4/routes/webui/api/index.yaml#L5583
    return this.axios.post('/api/users/resettotp/self');
  }

  /**
  * Resets an admin's password. If an admin has MFA enabled, the admin will be required
  * to verify with a new TOTP key. A request to this API endpoint will return an error
  * and a payload of a TOTP Key if the user has MFA enabled. The admin must use the new
  * TOTP Key to verify with an OTP.
  * @param  {ResetPassword} ResetPassword
    * @param  {String} ResetPassword.password [The user's password]
    * @param  {String} ResetPassword.confirm [The user's password again]
    * @param  {String} ResetPassword.confirmationCode [The request key generated in the email]
    * @param  {Boolean} ResetPassword.license [Whether the user has accepted the Terms of Use]
    * @param  {String} ResetPassword.otp One-time password used to confirm new TOTP key
  * @return {undefined | ResetPasswordError} [returns Axios promise]
  */
  resetPassword(resetPasswordPayload: ResetPasswordPayload) {
    // No response schema in SI, defaulting to `any`
    // https://github.com/TheJumpCloud/SI/blob/90c54341b987a78fb2ab58b247d302e599fc4cc4/routes/webui/index.yaml#L1165
    return this.axios.post('/passwordreset/verify', resetPasswordPayload);
  }

  /**
  * Resets an admin's password. If an admin has MFA enabled, the admin will be required
  * to verify with a new TOTP key. A request to this API endpoint will return an error
  * and a payload of a TOTP Key if the user has MFA enabled. The admin must use the new
  * TOTP Key to verify with an OTP.
  * @param  {VerifyMfaResetTOTPPayload} VerifyMfaResetTOTPPayload
    * @param  {String} ResetPassword.password [The user's password]
    * @param  {String} ResetPassword.confirmationCode [The request key generated in the email]
    * @param  {String} ResetPassword.otp One-time password used to confirm new TOTP key
  * @return {undefined | VerifyMfaResetTOTPError} [returns Axios promise]
  */
  verifyMfaResetTOTP(verifyMfaResetPayload: VerifyMfaResetTOTPPayload) {
    // No response schema in SI, defaulting to `any`
    // https://github.com/TheJumpCloud/SI/blob/90c54341b987a78fb2ab58b247d302e599fc4cc4/routes/webui/index.yaml#L1106
    return this.axios.post('/totpsetup/confirm', verifyMfaResetPayload);
  }

  /**
   * Fetch a new CSRF token when the invalid CSRF error is received in the
   * response. The admin user likely got logged out after 10 minutes of
   * inactivity, and then remained on the login page for more than 10 minutes
   * so that the CSRF token associated with their new session is now invalid.
   */
  retryIfInvalidCsrf<T>(error: Error, path: string, payload: any) {
    const { invalidCsrf, invalidCsrfOidc } = LoginConsts.responseMessages;
    const message = get(error, 'response.data.message', '');

    if (message === invalidCsrf || message === invalidCsrfOidc) {
      this.clearCsrfToken();
      return this.axios.post<T>(path, payload);
    }

    throw error;
  }

  /**
  * Confirms the validity of a given confirmation key
  * @param  {String} key [the confirmation key for resetting the password]
  * @return {Object} [returns the promise from the axios request]
  */
  validateKey(key: string) {
    // No response schema in SI, defaulting to `any`
    // https://github.com/TheJumpCloud/SI/blob/90c54341b987a78fb2ab58b247d302e599fc4cc4/routes/webui/index.yaml#L1033
    return this.axios.get(`/passwordreset/${key}`);
  }

  /**
  * Confirms the validity of a given confirmation key for resetting totp
  * @param  {String} key [the confirmation key for resetting the password]
  * @return {Object} [returns the promise from the axios request]
  */
  validateTotpKey(key: string) {
    // No response schema in SI, defaulting to `any`
    // https://github.com/TheJumpCloud/SI/blob/90c54341b987a78fb2ab58b247d302e599fc4cc4/routes/webui/index.yaml#L1072
    return this.axios.get(`/totpsetup/${key}`);
  }

  /**
   * Completes the login step for OIDC
   * @param {String} loginChallenge [the login challenge provided at the start of the OIDC flow]
   * @returns {Object} [returns the promise from the axios request]
   */
  async completeLoginChallengeOIDC(loginChallenge: string) {
    const path = '/auth/oauth';
    const payload = {
      login_challenge: loginChallenge,
    };

    try {
      return await this.axios.post<LoginChallengeResponse>(path, payload);
    } catch (e) {
      return this
        .retryIfInvalidCsrf<LoginChallengeResponse>(e as Error, path, payload);
    }
  }

  /**
   * Completes the consent step for OIDC
   * @param {OidcConsentPostPayload} payload [the consent payload]
   * @returns {OidcConsentPostResponse} [returns the promise from the axios request]
   */
  async completeConsentChallengeOIDC(payload: OidcConsentPostPayload) {
    const path = '/auth/consent';

    const payloadValidator = AdminLoginApiValidator
      .validateCompleteConsentChallengeOidcPayload(payload);

    if (!payloadValidator.isValid) {
      throw new Error(payloadValidator.message);
    }

    try {
      return await this.axios.post<OidcConsentPostResponse>(path, payload);
    } catch (e) {
      return this.retryIfInvalidCsrf<OidcConsentPostResponse>(e as Error, path, payload);
    }
  }

  /**
   * Fetches OIDC consent data for a given consent challenge
   * @param {String} challenge [the login challenge provided at the start of the OIDC flow]
   * @returns {OidcConsentGetResponse} [the oidc scopes, app logo, app name and confirmations]
   */
  fetchOidcConsentData(challenge: string) {
    const path = '/auth/consent';

    return this.axios.get<OidcConsentGetResponse>(`${path}/${challenge}`);
  }
}

export default new AdminLoginApi();
