
// Libraries
import { defineComponent } from 'vue';

// Components
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginTextField from 'client/components/LoginTextField.vue';

// Constants and Types
import { useLoginLabels } from 'client/util/Constants/LoginLabels';

// Utils
import routerService from 'client/services/routerService';
import userLoginService from 'client/services/userLoginService';

// Store
import { mapStores } from 'pinia';
import UserLoginStore from 'client/stores/UserLoginStore';

export default defineComponent({
  name: 'PasswordEntry',

  components: {
    LoginActionButton,
    LoginTextField,
  },

  props: {
    context: {
      type: String,
      default: '',
    },
    controllingQueryParams: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const uiLabel = useLoginLabels();
    return { uiLabel };
  },

  beforeDestroy() {
    this.userLoginStore.password = '';
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapStores(UserLoginStore),

    disableButton(): boolean {
      return !this.userLoginStore.password.length;
    },

    isContextSso(): boolean {
      return this.context === 'sso';
    },
  },

  methods: {
    handleError(error: any) {
      this.$emit('error', error);
    },

    handleSuccess() {
      this.$emit('success');
    },

    resetErrorMessage() {
      this.$emit('resetErrorMessage');
    },

    resetPasswordRequest() {
      routerService.goToUserPasswordResetRequest({ query: { ...this.controllingQueryParams } });
    },

    async submit() {
      this.resetErrorMessage();
      this.isLoading = true;

      try {
        await userLoginService.submitCredentials(this.userLoginStore.$state);
        this.handleSuccess();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
