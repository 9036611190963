export default {
  contexts: {
    push: {
      passwordReset: {
        buttonText: 'Send Request To Phone',
        subheaderText: 'This extra step shows you\'re really you. We\'ll send a push notification to your phone through JumpCloud Protect.',
      },
      login: {
        buttonText: 'Send Login Request To Phone',
        subheaderText: 'This extra step shows you\'re really you. We\'ll send a push notification to your phone through JumpCloud Protect.',
      },
    },
    totp: {
      passwordReset: {
        buttonText: 'Reset Password',
        subheaderText: 'This extra step shows you\'re really you. You\'ll need to use your mobile authenticator app on your phone.',
      },
      login: {
        buttonText: 'Login',
        subheaderText: 'Use an authenticator app on your phone for this extra verification step.',
      },
    },
    webauthn: {
      passwordReset: {
        buttonText: 'Verify With Security Key or Device Biometric',
        subheaderText: 'This extra step shows you\'re really you. You\'ll need your USB security key or device biometric (such as Touch ID or Windows Hello).',
      },
      login: {
        buttonText: 'Login with Security Key or Device Biometric',
        subheaderText: 'This extra step shows you\'re really you. You\'ll need your USB security key or device biometric (such as Touch ID or Windows Hello).',
      },
    },
  },
};
