/**
 * This file contains utility functions to be used throughout the code base. New functions should
 * be included here instead of `client/util/index.js`. `client/util/index.js` contains legacy
 * utility functions and imports files that can cause failures if included in `client/signup` for
 * example.
 */

import Constants from 'client/util/Constants';

export default {
  /**
   * Convert an ArrayBuffer to a base64 encoded string.
   * @see https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string#11562550
   * @see https://github.com/brianloveswords/base64url/blob/master/src/base64url.ts#L14
   */
  arrayBufferToString(arrayBuffer) {
    return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  },

  /**
   * Build a url with query params
   * @param {string} url The base url. It can include its own query params.
   * @param { [id: string] : string; } params Dictionary of query params to add to the url
   * @returns string The url with the new query params
   */
  buildUrl(url, params) {
    const urlObj = new URL(url);
    const searchParams = new URLSearchParams(urlObj.search);
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    urlObj.search = searchParams.toString();
    return urlObj.toString();
  },

  /**
   * Format words depending of case
   * @param {String} [word] - The  word param
   * @return {String} The word formmatted
   */
  convertWordsToPascalCase(word) {
    if (word.indexOf(' ') !== -1) {
      return (word.split(' ').join('')).charAt(0).toLowerCase()
      + (word.split(' ').join('')).slice(1);
    }

    return word.toLowerCase();
  },

  /**
   * Format words depending of event
   * @param {String} [word] - The  word param
   * @param {String} [event] - The event to control
   * @return {String} The word formmatted
   */
  convertPascalCaseToWords(word, event) {
    switch (event) {
      case 'remove':
        return (word.split(/(?=[A-Z])/).join(' ').charAt(0).toUpperCase()
        + word.split(/(?=[A-Z])/).join(' ').slice(1));
      default:
        return word;
    }
  },

  /**
   * Delay for x milliseconds
   * @param {Number} [ms] - Milliseconds
   * @return {Promise}
   */
  delay(ms) {
    return new Promise((resolve) => { setTimeout(resolve, ms); });
  },

  /**
   * Format decimal values
   * @param {Number} [value] - value that needs decimal fixing param
   * @return {String} value with fixed decimals
   */
  fixDecimals(value) {
    return value.toString().includes('.')
      ? value.toFixed(2) : value.toString();
  },

  /**
   * Determine the type of script to include based on the user's environment
   * @param {String} [hostname] - The full domain name
   * @return {String} The environment the user is in
   */
  getEnvironment(hostname = window.location.hostname) {
    const { environments } = Constants;

    if (hostname === 'localhost' || hostname === 'console.jumpcloud.local' || hostname.includes('.jcplatform.dev')) {
      return environments.local;
    }
    if (hostname === 'console.stg01.jumpcloud.com') {
      return environments.staging;
    }
    return environments.production;
  },

  /**
   * Searches through the query string and finds the value for the given key, if any
   * @param {String} key - The key of the query parameter to be searched
   * @param {String} [queryString] - The complete query string to be searched
   * @return {String} The value of the query parameter. If none, empty string.
   */
  getQueryStringParameter(key, queryString = window.location.search) {
    const params = new URLSearchParams(queryString.substring(1));

    return params.get(key);
  },

  /**
   * Searches through the query string and delete the value for the given key, if any
   * and modifies the current history entry to the base with rest of params
   * @param {string} key - The key of the query parameter to be searched
   * @param {string} queryString - The complete query string
   */
  removeQueryStringParameter(key, queryString = window.location.search) {
    const params = new URLSearchParams(queryString);
    params.delete(key);

    window.history.replaceState(null, '', `/${params}${window.location.hash}`);
  },

  getUnitFromSize(bytes) {
    const result = +(Math.floor(Math.log(bytes) / Math.log(1024)));
    const sizes = ['bytes', 'KB', 'MB', 'GB'];
    const size = +(bytes / (1024 ** result));
    const unit = sizes[result];

    return {
      unit,
      size,
    };
  },

  /**
   * Converts bytes to appropriate unit
   * @param {Number} numBytes - The bytes you want to convert
   * @param {Boolean} isWindows - OS of system determines the base number for conversion
   * @return {Object} Contains the converted number and unit
   */
  getTextToDisplayBytes(numBytes, isWindows = false) {
    // Windows systems calculate memory and storage with base 2 but display it with a base 10 unit
    const baseNum = (isWindows ? 1024 : 1000);

    // Show in PB
    const numPb = numBytes / (baseNum ** 5);
    if (numPb >= 1) {
      if (numPb < 10) {
        // Show with a single decimal point if less than 10 (e.g. 1.5 PB)
        return { number: Math.floor(numPb * 10) / 10, unit: 'PB' };
      }
      return { number: Math.floor(numPb), unit: 'PB' };
    }

    // Show in TB
    const numTb = numBytes / (baseNum ** 4);
    if (numTb >= 1) {
      if (numTb < 10) {
        // Show with a single decimal point if less than 10 (e.g. 1.5 TB)
        return { number: Math.floor(numTb * 10) / 10, unit: 'TB' };
      }
      return { number: Math.floor(numTb), unit: 'TB' };
    }

    // Show in GB
    const numGb = numBytes / (baseNum ** 3);
    if (numGb >= 1) {
      if (numGb < 10) {
        // Show with a single decimal point if less than 10 (e.g. 3.9 GB)
        return { number: Math.floor(numGb * 10) / 10, unit: 'GB' };
      }
      return { number: Math.floor(numGb), unit: 'GB' };
    }

    // Show in MB
    const numMb = numBytes / (baseNum ** 2);
    return { number: Math.floor(numMb), unit: 'MB' };
  },

  initials(string1, string2) {
    let initials;
    if (string1 && string2) {
      initials = `${string1.charAt(0)}${string2.charAt(0)}`;
    } else {
      initials = `${string1.charAt(0)}`;
    }
    return initials.toUpperCase();
  },

  openUrl(path) {
    window.open(path, '_blank');
  },

  /**
   * Redirects the user to the given path
   * @param {String} path - The absolute or relative URL to redirect the user to
   */
  redirect(path, location = window.location) {
    location.assign(path);
  },

  /**
   * Reverses the sort field.
   * e.g. '-active lastContact' => 'active -lastContact'
   */
  reverseSortBy(sortBy) {
    return sortBy.split(' ').map((v) => {
      if (v.charAt(0) === '-') {
        return v.slice(1);
      }
      return `-${v}`;
    }).join(' ');
  },

  /**
   * Serialize an object to be a query string
   * @param {Object} obj - The object to be serialized
   * @return {String} The serialized string
   */
  serialize(obj) {
    const strArray = [];
    Object.keys(obj).forEach((key) => {
      strArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    });
    return strArray.join('&');
  },

  /**
   * Convert a base64 encoded string to an ArrayBuffer.
   * @see https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer/21797381#41106346
   * @see https://github.com/brianloveswords/base64url/blob/master/src/base64url.ts#L23
   */
  stringToArrayBuffer(string) {
    const stringWithReplacements = string.replace(/-/g, '+').replace(/_/g, '/');
    return Uint8Array.from(window.atob(stringWithReplacements), (c) => c.charCodeAt(0)).buffer;
  },
  /**
   * Creates a function that chains all the pipeline segments in the same order they came
   */
  chainPipelineSegments(pipeline, parameters = {}) {
    const newPipeline = pipeline.concat([]);
    const nextSegment = newPipeline.shift();

    if (!newPipeline.length) {
      return () => nextSegment(() => {});
    }

    return () => nextSegment({
      next: this.chainPipelineSegments(newPipeline, parameters),
      parameters,
    });
  },

  tryParseJson(str) {
    try {
      return JSON.parse(str || '{}');
    } catch (e) {
      return {};
    }
  },

  /**
   * Creates an unique Id
   */
  getUniqueId() {
    // Use crypto API if available
    // Fallback to hex-encoded (radix 16) date value
    // Random hex value appended to ensure unique-ness if being used in a loop
    // @todo: It would probably be a good idea to replace this with a lib that supports IE11
    return window?.crypto?.randomUUID?.() || `${Date.now().toString(16)}-${Math.random().toString(16).slice(2)}`;
  },

  isSmallScreen() {
    return Boolean(window.screen.width < 768);
  },
};
