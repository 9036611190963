/* eslint-disable max-classes-per-file */
import { LoginConsts } from 'client/util/Constants';
import { MfaFactorResponse } from 'client/services/response/MfaRequiredError';
import MfaConstants from 'client/containers/Login/MfaConstants';
import RouteGenerator from 'client/routes/RouteGenerator';
import type { MfaFactor } from 'client/types/Auth';

const { login } = RouteGenerator;

const { ssoStepUp } = LoginConsts.loginRoutes;
const { user } = LoginConsts.clientType;
const { mfaStatus, mfaNames } = MfaConstants;

const loginPaths = {
  login,
  ssoStepUp,
};

const componentNameByPath = {
  [login]: 'Login',
  [ssoStepUp]: 'Stepup Choose MFA',
};

interface MfaTypeOptions {
  loginType?: 'login' | 'ssoStepUp';
  name?: string;
  redirectTo?: string;
  challenge?: any;
  passwordResetInfo?: any;
  context?: string;
  controllingQueryParams?: any;
  token?: string;
}

export class Duo {
  name: string;

  description: string;

  status: string;

  iconUrl: string;

  route: { path?: string; name?: string; query?: any; params?: any; };

  constructor(factor: { clientType?: string, status: string} = {
    clientType: user,
    status: mfaStatus.unavailable,
  }, options: MfaTypeOptions = {}) {
    const {
      controllingQueryParams = {},
      name,
    } = options;

    this.name = mfaNames.duo;
    this.description = 'Duo Security';
    this.status = (factor && factor.status) || mfaStatus.unavailable;
    this.iconUrl = '/login/img/icons/duo-logo.svg';
    this.route = {
      name,
      query: {
        ...controllingQueryParams,
        step: 'mfa',
        mfaType: this.name,
      },
    };
  }
}

export class Go {
  name: string;

  description: string;

  iconUrl: string;

  status: string;

  route: { path?: string; name?: string; query?: any; params?: any; };

  constructor(factor: { clientType?: string, status: string} = {
    clientType: user,
    status: mfaStatus.available,
  }, options: MfaTypeOptions = {}) {
    const {
      controllingQueryParams = {},
      name,
    } = options;

    this.name = mfaNames.durt;
    this.description = 'Jumpcloud Go';
    this.status = (factor && factor.status) || mfaStatus.unavailable;
    this.iconUrl = '';
    this.route = {
      name,
      query: {
        ...controllingQueryParams,
        step: 'mfa',
        mfaType: this.name,
      },
    };
  }
}

export class GoUv {
  name: string;

  description: string;

  iconUrl: string;

  status: string;

  route: { path?: string; name?: string; query?: any; params?: any; };

  constructor(factor: { clientType?: string, status: string} = {
    clientType: user,
    status: mfaStatus.unavailable,
  }, options: MfaTypeOptions = {}) {
    const {
      controllingQueryParams = {},
      name,
    } = options;

    this.name = mfaNames.durt_uv;
    this.description = 'Jumpcloud Go';
    this.status = (factor && factor.status) || mfaStatus.unavailable;
    this.iconUrl = '';
    this.route = {
      name,
      query: {
        ...controllingQueryParams,
        step: 'mfa',
        mfaType: this.name,
      },
    };
  }
}

export class Push {
  name: string;

  description: string;

  status: string;

  iconUrl: string;

  route: { path?: string; name?: string; query?: any; params?: any; };

  token: string;

  constructor(factor: { clientType?: string, status: string} = {
    clientType: user,
    status: mfaStatus.unavailable,
  }, options: MfaTypeOptions = {}) {
    const {
      token = '',
      controllingQueryParams = {},
      name,
    } = options;

    this.name = mfaNames.push;
    this.description = 'JumpCloud Protect';
    this.status = (factor && factor.status) || mfaStatus.unavailable;
    this.token = token;
    this.iconUrl = '/login/img/icons/icon-jumpcloud-protect-primary.svg';
    this.route = {
      name,
      query: {
        ...controllingQueryParams,
        step: 'mfa',
        mfaType: this.name,
      },
    };
  }
}

export class Totp {
  name: string;

  description: string;

  status: string;

  iconUrl: string;

  route: { path?: string; name?: string; query?: any; params?: any; };

  constructor(factor: { clientType?: string, status: string} = {
    clientType: user,
    status: mfaStatus.unavailable,
  }, options: MfaTypeOptions = {}) {
    const {
      controllingQueryParams = {},
      name,
    } = options;

    this.name = mfaNames.totp;
    this.description = 'Use an Authenticator App';
    this.status = (factor && factor.status) || mfaStatus.unavailable;
    this.iconUrl = '/login/img/icons/icon-totp.svg';
    this.route = {
      name,
      query: {
        ...controllingQueryParams,
        step: 'mfa',
        mfaType: this.name,
      },
    };
  }
}

export class WebAuthn {
  name: string;

  description: string;

  status: string;

  iconUrl: string;

  route: { path?: string; name?: string; query?: any; params?: any; };

  challenge: any;

  constructor(factor: { clientType?: string, status: string} = {
    clientType: user,
    status: mfaStatus.unavailable,
  }, options: MfaTypeOptions = {}) {
    const {
      controllingQueryParams = {},
      name,
    } = options;

    this.name = mfaNames.webauthn;
    this.description = 'Security Key or Built-In Sensor';
    this.status = (factor && factor.status) || mfaStatus.unavailable;
    this.iconUrl = '/login/img/icons/icon-security-key.svg';
    this.route = {
      name,
      query: {
        ...controllingQueryParams,
        step: 'mfa',
        mfaType: this.name,
      },
    };
  }
}

const MfaTypes = new Map([
  [mfaNames.duo, Duo],
  [mfaNames.durt, Go],
  [mfaNames.durt_uv, GoUv],
  [mfaNames.push, Push],
  [mfaNames.totp, Totp],
  [mfaNames.webauthn, WebAuthn],
]);

// eslint-disable-next-line max-len
export default function MfaType(mfa: {factor?: MfaFactorResponse, challenge?: any} = {}, options: MfaTypeOptions = {}): MfaFactor {
  const factorType = mfa.factor && mfa.factor.type;
  const name = (factorType && mfaNames[factorType]) || mfaNames.totp;

  const loginType = options.loginType || 'login';
  const path = loginPaths[loginType];
  const componentName = componentNameByPath[path];

  const constructor = MfaTypes.get(name);

  if (!constructor) {
    throw Error(`Unknow MFA type: ${name}`);
  }

  return new constructor(
    mfa.factor,
    {
      name: componentName,
      controllingQueryParams: options.controllingQueryParams,
    },
  );
}
