
// Libraries
import { defineComponent } from 'vue';

// Components
import TotpInput from 'client/components/TotpInput.vue';

// Constants and types
import { useMfaLabels } from 'client/util/Constants/MfaLabels';

// Utils
import userLoginService from 'client/services/userLoginService';

export default defineComponent({
  name: 'TotpUserLoginHandler',

  components: {
    TotpInput,
  },

  setup() {
    const uiLabel = useMfaLabels();
    return { uiLabel };
  },

  data() {
    return {
      token: '',
      hasAlreadySubmitted: false,
      isLoading: false,
      childInterface: {
        clearInput: () => {},
        focusInput: () => {},
      },
    };
  },

  methods: {
    clearInput() {
      this.childInterface.clearInput();
    },

    focusInput() {
      this.childInterface.focusInput();
    },

    getChildInterface(childInterface: { clearInput: () => void, focusInput: () => void }) {
      this.childInterface = childInterface;
    },

    async submit() {
      this.isLoading = true;
      try {
        await userLoginService.verifyTotp(this.token);
        this.$emit('success');
      } catch (error) {
        // check that displays a manual submit button
        this.hasAlreadySubmitted = true;
        this.$emit('error', error);
        this.clearInput();
        this.focusInput();
      } finally {
        this.isLoading = false;
      }
    },
  },
});
