<template>
  <div>
    <img
      alt="lock"
      src="~public/img/icons/icon-secure.svg"
    >
    <span>Verifying Device and User</span>
  </div>
</template>

<script>
export default {
  name: 'JCGoVerifyStep',
};
</script>

<style scoped module>
img {
  margin-right: 0.25rem;
}
</style>
