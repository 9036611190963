
import { PropType, defineComponent } from 'vue';
import { durtApiResponseType, infoResponse } from 'client/services/durtConstants';
import { useLoginLabels } from 'client/util/Constants/LoginLabels';
import EmailEntry from 'client/containers/user/login/EmailEntry.vue';
import JumpcloudGoButton from 'client/components/JumpcloudGoButton.vue';
import LoginAlert from 'client/components/LoginAlert.vue';

export default defineComponent({
  name: 'Access',

  components: {
    EmailEntry,
    JumpcloudGoButton,
    LoginAlert,
  },

  setup() {
    const loginLabels = useLoginLabels();
    return { loginLabels };
  },

  data() {
    return {
      shouldShowEmail: false,
      isLoading: false,
    };
  },

  props: {
    durtInfo: {
      type: Object as PropType<infoResponse>,
      default: () => ({}),
    },
    durtErrorMessage: {
      type: String,
      default: '',
    },
  },

  computed: {
    ManualLoginText() {
      return this.durtErrorMessage === durtApiResponseType.userNotActive ? 'Log in with credentials' : 'Log in to a different account';
    },

    title() {
      return this.$route.query.context === 'sso'
        ? this.loginLabels.loginToApp
        : this.loginLabels.welcomeBack;
    },

    shouldShowErrorMessage() {
      return !!this.durtErrorMessage && this.durtErrorMessage !== durtApiResponseType.userNotActive;
    },
  },

  methods: {
    toggleEmail() {
      this.shouldShowEmail = !this.shouldShowEmail;
    },

    handleContinue(payload: any) {
      this.$emit('submitEmail', payload);
    },

    handleJumpcloudGoClick() {
      this.$emit('resetErrorMessage');
      this.isLoading = true;
      this.$emit('goAccess');
    },
  },

  mounted() {
    if (!this.durtInfo.is_locked && this.$route.query.autoGo !== 'false' && !this.durtErrorMessage) {
      this.isLoading = true;
      this.$emit('goAccess');
    }
  },
});
