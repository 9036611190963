// eslint-disable-next-line import/prefer-default-export
export function useMfaLabels() {
  const extraStep = 'This extra step shows you’re really you.';
  return {
    chooseDifferent: 'Choose A Different Way',
    or: 'OR',
    passwordResetHeader: 'User Password Reset',
    verifyIdentity: 'Verify Your Identity',
    chooser: {
      help: `${extraStep} Choose a method to verify yourself:`,
    },
    duo: {
      help: `${extraStep} You’ll need to verify yourself with Duo Security.`,
      error: {
        load: 'Unable to load Duo Web JavaScript file!',
        redirect: 'An error occurred while redirecting to Duo Security. Try again, or use an alternate verification option.',
        retrieve: 'Unable to retrieve Duo configuration!',
        verify: 'Unable to verify Duo. If this problem persists, contact your IT Admin.',
      },
      button: {
        disabled: 'Redirecting To Duo Security',
        enabled: 'Verify With Duo Security',
      },
    },
    push: {
      help: `${extraStep} We’ll send a push notification to your phone through JumpCloud Protect.`,
      button: {
        disabled: 'Request Sent. Approve It On Your Phone',
        enabled: 'Send Request To Phone',
      },
    },
    totp: {
      help: `${extraStep} You’ll need to use your mobile authenticator app on your phone.`,
      label: 'Enter code from your mobile authenticator app:',
    },
    webauthn: {
      help: `${extraStep} You’ll need your USB security key or device biometric (such as Touch ID or Windows Hello).`,
      login: 'Login with Security Key or Device Biometric',
      unsupported: 'Security Keys are not supported on this browser.',
    },
  };
}
