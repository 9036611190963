import { datadogRum } from '@datadog/browser-rum-slim';
import Constants, { LoginConsts } from 'client/util/Constants';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginAlert from 'client/components/LoginAlert.vue';
import LoginTextField from 'client/components/LoginTextField.vue';
import LogoIcon from 'client/components/LogoIcon.vue';

const { admin } = LoginConsts.clientType;

/*
  These are states, components, and props that are common to many of the
  login pages. Used mainly for container components.
 */
export default {
  props: {
    clientType: {
      type: String,
      required: true,
    },
  },

  components: {
    Alert: LoginAlert, // TODO: remove the rename
    LoginAlert,
    LoginActionButton,
    LoginTextField,
    LogoIcon,
  },

  data: () => ({
    autofocus: '',
    errorMessage: '',
    hasTracked: false,
    isLoading: false,
  }),

  methods: {
    updateAutofocus(lastFocusedInput) {
      this.autofocus = lastFocusedInput;

      if (!this.hasTracked) {
        this.hasTracked = true;
        datadogRum.addAction(`${this.clientType}-ready`);
      }
    },

    /**
     * Use VueRouter to change location
     */
    vueRouteChange(name = '', params = {}) {
      this.$router.push({
        name,
        params,
      });
    },

    isAdmin() {
      return this.clientType === admin;
    },
    /**
     * Add wrapper method which can be stubbed in tests since stubbing global
     * objects throws errors in Chrome and eslint.
     */
    setLocation(url) {
      window.location = url;
    },

    /**
     * ensures the request state is set & the form is validated before the api
     * request is sent. Also prevents regular form submission.
     * @param  {Object} e [DOM event from the form being submitted]
     */
    submit(e) {
      if (e) {
        e.preventDefault();
      }
      this.validate().then((result) => {
        const customValidationResult = this.customValidation();
        if (customValidationResult && result) {
          this.setRequestState();
          this.request();
        }
      });
    },

    /**
     * Default custom validation function. Override this at the component level
     * to add custom errors at the time of validation.
     * @return {Boolean} [represents if the custom validation passes]
     */
    customValidation: () => true,

    /**
     * Run vee-validate on all form elements.
     * @return {Object} [returns the promise returned from the validation]
     */
    validate() {
      const { formComponent } = this.$refs;
      const component = formComponent || this;
      return component.$validator.validateAll();
    },

    /**
     * If redirectTo exists in Session Storage and is valid, return it;
     * otherwise, return an empty string; in order for redirectTo to be valid,
     * it must match the "/#" or "/rootRoute" pattern in order to redirect to the session URL
     * @return {String}
     */
    getRedirectTo() {
      const redirectTo = window.sessionStorage.getItem(Constants.redirectTo);
      window.sessionStorage.removeItem(Constants.redirectTo);
      return redirectTo && /^\/(\w|#)/.test(redirectTo) ? redirectTo : '';
    },

    /**
     * Redirect to the requested url. This happens between login routes as well
     * as at the completion of login. Using this between routes needs to be changed
     * to instead use the app's router.
     *
     * Or if an admin user requests a page that requires authentication and is
     * not yet authenticated then instead of redirecting the admin user to the
     * root of the admin portal, instead redirect them to the initially
     * requested page.
     *
     * @param  {String} url [where we wish to redirect to]
     * @see client/util/backbone-util.js
     * @todo When vue-router is integrated this _may_ not be needed, but the
     * redirection functionality will need to be preserved!
     */
    redirect(url) {
      let redirectUrl = url;

      const isExternalRoute = (routeUrl) => {
        const destRoute = this.$router.match(routeUrl);
        // All non-configured routes fall back to the default Login route.
        const unknownRouteName = 'Login';
        // When the wildcard route matches for unknown routes the paths
        // will be defaulted to be the same.
        return destRoute.name === unknownRouteName && destRoute.fullPath === destRoute.path;
      };

      if (isExternalRoute(redirectUrl)) {
        const externalRedirect = this.getRedirectTo();
        if (externalRedirect) {
          redirectUrl = externalRedirect;
        }
      }

      this.setLocation(redirectUrl);
    },

    /**
     * Before requests are made, set the state of the component. Loading is used
     * to display loading spinners. We also reset the errorMessage before the
     * request so the user gets feedback that a request has been sent out.
     */
    setRequestState() {
      this.errorMessage = '';
      this.isLoading = true;
    },

    setErrorMessage(message = '') {
      this.errorMessage = message;
    },
  },
};
