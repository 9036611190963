import { LoginConsts } from 'client/util/Constants';
import { get } from 'lodash';
import { pollFailureCondition, pollSuccessCondition } from 'client/util/verifyPushHelpers';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import PushMfaAuthApi from 'client/api/PushMfaAuthApi';
import WebAuthnService from 'client/services/webAuthnService';
import api from 'client/api';
import poll from 'client/containers/Login/poll';

// Errors
import MfaRequiredError from 'client/services/response/MfaRequiredError';
import PushError from 'client/services/response/PushError';
import RequestError from 'client/services/response/RequestError';

const userApi = api.user;

const {
  pushStatusMessages, responseMessages,
} = LoginConsts;
const mfaLabels = useMfaLabels();

interface UserForm {
  email: string;
  password: string;
  shouldRememberEmail: boolean;
}

export default {
  /** Email/Password methods */
  generatePayload(credentials: UserForm): {email: string, password: string} {
    const { email, password } = credentials;

    return {
      email,
      password,
    };
  },

  submitEmail(payload: { email: string, redirectTo?: string }): Promise<void> {
    return userApi.submitEmail(payload);
  },

  submitCredentials(credentials: UserForm): Promise<void> {
    const payload = this.generatePayload(credentials);

    return userApi.login(payload).catch((error: unknown) => {
      if (error instanceof Error) {
        const errorMessage = get(error, 'response.data.message', responseMessages.loginError);
        const statusCode = get(error, 'response.status');

        if (errorMessage === responseMessages.mfaRequired) {
          const factors = get(error, 'response.data.factors', []);
          throw new MfaRequiredError(factors, undefined, '');
        }
        throw new RequestError(statusCode, errorMessage);
      }
      throw error;
    });
  },
  /* ******************************* */

  /** DUO methods */
  getDuoInfo(): Promise<any> {
    return userApi.getDuoAuth();
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  submitDuo(form: any, token: string): Promise<any> {
    const payload = { sig_response: form.elements.sig_response.value, token };

    return userApi.postDuoSignature(payload).catch((error) => {
      if (error instanceof Error) {
        const statusCode = get(error, 'response.status');
        throw new RequestError(statusCode, mfaLabels.duo.error.verify);
      } else {
        throw error;
      }
    });
  },

  async getDuoRedirectUrl(payload?: { redirectTo: string }): Promise<any> {
    try {
      const response = await userApi.postDuoChallengeUrl(payload);

      return response.data.location;
    } catch (error) {
      const statusCode = get(error, 'response.status');
      throw new RequestError(statusCode, mfaLabels.duo.error.redirect);
    }
  },
  /* ******************************* */

  /** PUSH methods */
  verifyPush(): Promise<any> {
    // eslint-disable-next-line max-len
    const startPushAuthentication = () => PushMfaAuthApi.startPushAuthentication().catch((error) => {
      const status = get(error, 'response.status');
      const message = get(error, 'response.data.message');
      if (status === 429 && message) {
        const displayDuration = get(error, 'response.headers.retry-after');

        throw new RequestError(status, message, undefined, displayDuration);
      } else {
        throw error;
      }
    });

    // eslint-disable-next-line max-len
    const pollForResponse = ({ id: pushId }: {id: string}) => new Promise<{pushId: string}>((resolve, reject) => {
      const pollConfig = { delay: 1000 };

      poll(
        () => PushMfaAuthApi
          .getPushAuthentication(pushId)
          .catch(reject),
        {
          successCondition: pollSuccessCondition,
          successHandler: resolve,
          failureCondition: pollFailureCondition,
          // an expected error, all other errors will not be a PushError
          failureHandler: (error: { status: string, pushId: string }) => (
            reject(new PushError(error?.status, error?.pushId))
          ),
        },
        pollConfig,
      );
    });

    return startPushAuthentication()
      .then(pollForResponse)
      .then(({ pushId }) => PushMfaAuthApi.finishPushAuthentication(pushId))
      .catch((error: unknown) => {
        if (error instanceof PushError) {
          throw error;
        }

        if (error instanceof RequestError) {
          throw error;
        }

        throw new RequestError(0, pushStatusMessages.errored);
      });
  },
  /* ******************************* */

  /** TOTP methods */
  verifyTotp(otp = ''): Promise<object> {
    const payload = { otp };

    return userApi.verifyTotp(payload)
      .catch((error: unknown) => {
        if (error instanceof Error) {
          const statusCode = get(error, 'response.status');
          const errorMessage = statusCode === 401 ? responseMessages.invalidOtpError : get(error, 'response.data.message');
          throw new RequestError(statusCode, errorMessage);
        } else {
          throw error;
        }
      });
  },
  /* ******************************* */

  /** WEBAUTHN methods */
  async verifyWebAuthnSignature(): Promise<any> {
    try {
      const payload = await WebAuthnService.prepareLoginRequest();

      return await userApi.postWebAuthnSignature(payload);
    } catch (error: unknown) {
      // errors from prepareLoginRequest will be RequestError
      // errors from postWebAuthnSignature will be AxiosError
      let formattedError = error;
      if (error instanceof Error && !(error instanceof RequestError)) {
        formattedError = new RequestError(get(error, 'response.status'), get(error, 'response.data.message', error.message));
      }
      throw formattedError;
    }
  },
  /* ******************************* */

  /** Oauth methods */
  oauthChangeEmail(): Promise<any> {
    // eslint-disable-next-line no-console
    return userApi.oauthPostChangeRequest().catch((error) => {
      throw new RequestError(get(error, 'response.status'), get(error, 'response.data.message', error.message));
    });
  },
  /* ******************************* */
};
