<template>
  <PasswordResetSteps
    :steps="steps"
    :image="windowsResetImg"
  />
</template>

<script>
import PasswordResetSteps from 'client/components/PasswordResetSteps.vue';
import windowsResetImg from 'public/img/windows-pwb-graphic.png';

export default {
  name: 'WindowsPasswordReset',

  components: {
    PasswordResetSteps,
  },

  data() {
    return {
      windowsResetImg,
      steps: [
        {
          step: 1,
          text: 'Press Ctrl + Alt + Del and select "Change Password"',
        },
        {
          step: 2,
          text: 'Fill in password fields* and press "Enter"',
        },
        {
          step: 3,
          text: 'Click "OK". Your password has been updated.',
        },
      ],
    };
  },
};
</script>
