import { LoginConsts } from 'client/util/Constants';
import { UserLoginApiHelper } from 'client/api/UserLoginApi';

const { authURLs } = LoginConsts;

export const PushAuthenticationStatus = {
  ACCEPTED: 'accepted',
  DENIED: 'denied',
  ERRORED: 'errored',
  EXPIRED: 'expired',
  PENDING: 'pending',
};

const PushAuthentication = {
  fromApi: (pushAuthentication) => ({
    status: PushAuthenticationStatus[
      pushAuthentication.status && pushAuthentication.status.toUpperCase()
    ] || PushAuthenticationStatus.ERRORED,
    failureStatus: pushAuthentication.failureStatus || null,
    pushId: pushAuthentication.id,
  }),
};

export class PushAuthApi extends UserLoginApiHelper {
  constructor() {
    super();
    this.pushUrl = `${authURLs.user}/push`;
  }

  async startPushAuthentication(store) {
    // inject the state ID into start push authentication to provide notification & detail info
    const response = await this.mfaVerificationPost(`${this.pushUrl}`, {}, store);

    return response.data;
  }

  async getPushAuthentication(id) {
    const response = await this.axios.get(`${this.pushUrl}/${id}`);

    return PushAuthentication.fromApi(response.data);
  }

  async finishPushAuthentication(id, store) {
    const response = await this.mfaVerificationPost(`${this.pushUrl}/${id}/login`, {}, store);

    return response.data;
  }
}

export default new PushAuthApi();
