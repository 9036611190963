import { find, get, isFunction } from 'lodash';

const pollHandler = { timeoutId: '' };

export const sleep = (delay) => new Promise((resolve) => {
  pollHandler.timeoutId = setTimeout(resolve, delay);
});

export const cancelPoll = () => {
  if (pollHandler.timeoutId) {
    clearTimeout(pollHandler.timeoutId);
  }
};

const getHandlerOrDefault = (handlers) => (handlerName) => (
  isFunction(get(handlers, handlerName))
    ? handlers[handlerName]
    : () => {}
);

const buildConditions = (action, handlers) => ([
  {
    key: 'failure',
    check: (response, options) => {
      const checkFailure = getHandlerOrDefault(handlers)('failureCondition');

      return checkFailure(response, options);
    },
    handle: (response) => {
      const failureHandler = getHandlerOrDefault(handlers)('failureHandler');

      failureHandler(response);
    },
  },
  {
    key: 'success',
    check: (response, options) => {
      const checkSuccess = getHandlerOrDefault(handlers)('successCondition');

      return checkSuccess(response, options);
    },
    handle: (response) => {
      const successHandler = getHandlerOrDefault(handlers)('successHandler');

      successHandler(response);
    },
  },
  {
    key: 'continue',
    check: () => true,
    handle: (_, options) => {
      handlers.continueHandler(action, handlers, options);
    },
  },
]);

const continueHandler = async (action, handlers, options) => {
  await sleep(options.delay);

  // eslint-disable-next-line no-use-before-define
  await poll(action, handlers, { ...options, currentAttempt: options.currentAttempt + 1 });
};

const poll = async (action, handlers, options = {}) => {
  const handlersWithDefaults = {
    ...handlers,
    continueHandler,
  };

  const optionsOrDefaults = {
    currentAttempt: 0,
    delay: 500,
    ...options,
    maximumAttempts: options.maximumAttempts !== undefined
      ? options.maximumAttempts
      : null,
  };

  if (
    optionsOrDefaults.maximumAttempts
    && optionsOrDefaults.currentAttempt >= optionsOrDefaults.maximumAttempts
  ) {
    const timeoutHandler = getHandlerOrDefault(handlers)('timeoutHandler');

    timeoutHandler();

    return;
  }

  const conditions = buildConditions(action, handlersWithDefaults, optionsOrDefaults);

  const response = await action();

  const matchingCondition = find(conditions, (condition) => (
    condition.check(response, optionsOrDefaults)
  ));

  matchingCondition.handle(response, optionsOrDefaults);
};

export default poll;
