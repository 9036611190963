var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.resetPassword},[_c('span',{class:[
      _vm.$style.fadeTransition,
      _vm.$style.resetTotpContainer,
      { [_vm.$style.fadeTo]: _vm.isLoadingReset }
    ]},[_c('button',{class:{
        [_vm.$style.resetPasswordLink]: true,
      },attrs:{"aria-label":"Reset MFA or Enroll (opens a new page)"},on:{"click":_vm.handleResetTOTP}},[_vm._v("\n      Click Here\n    ")]),_vm._v("To Reset MFA Or Enroll\n  ")]),_vm._v(" "),_c('transition',{attrs:{"enterClass":_vm.$style.fadeTo,"enterActiveClass":_vm.$style.fadeTransition,"leaveActiveClass":_vm.$style.fadeTransition,"leaveToClass":_vm.$style.fadeTo}},[(_vm.isLoadingReset)?_c('div',{class:_vm.$style.loadingIconContainer},[_c('LoadingIcon')],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }