<template>
  <component
    :clientType="clientType"
    :context="$route.query.context"
    :email="$route.query.email"
    :error="error"
    :form="form"
    :is="componentName"
    :mfaType="$route.query.mfaType"
    :redirectTo="$route.query.redirectTo"
    :requestKey="$route.query.key"
    :step="$route.query.step"
    :success="success"
  />
</template>

<script>
import { LegacyQueryRoutes } from 'client/routes/RouteGenerator';
import AdminPasswordResetAndSetup from 'client/containers/AdminPasswordResetAndSetup.vue';
import AdminTOTPReset from 'client/containers/AdminTOTPReset.vue';
import AdminTotpSetup from 'client/containers/AdminTotpSetup/AdminTotpSetup.vue';
import ChooseOS from 'client/containers/ChooseOS.vue';
import Constants, { LoginConsts } from 'client/util/Constants';
import Cookies from 'js-cookie';
import MacPasswordReset from 'client/containers/MacPasswordReset.vue';
import OauthLoginWrapper from 'client/containers/Login/OauthLoginWrapper.vue';
import RequestPasswordReset from 'client/containers/RequestPasswordReset.vue';
import RequestTOTPReset from 'client/containers/RequestTOTPReset.vue';
import SsoLoginWrapper from 'client/containers/Login/SsoLoginWrapper.vue';
import UserLoginWrapper from 'client/containers/Login/UserLoginWrapper.vue';
import UserRegistration from 'client/containers/UserRegistration.vue';
import UserResetPasswordController from 'client/containers/user/UserResetPassword/UserResetPasswordController.vue';
import VerifyTOTP from 'client/containers/AdminVerifyTOTP.vue';
import WindowsPasswordReset from 'client/containers/WindowsPasswordReset.vue';
import api from 'client/api';

import { mapStores } from 'pinia';
import UserLoginStore from 'client/stores/UserLoginStore';

const { clientTypeCookie } = LoginConsts;
const { admin, user } = LoginConsts.clientType;
const { timezoneOffsetMinutes } = Constants;

export default {
  name: 'LoginApp',
  data: () => ({
    clientType: admin,
    componentName: '',
  }),
  props: {
    error: {
      type: String,
      default: undefined,
    },
    form: {
      type: Object,
      default: () => ({
        email: '',
        password: '',
        shouldRememberEmail: false,
      }),
    },
    success: {
      type: String,
      default: undefined,
    },
  },
  components: {
    AdminPasswordResetAndSetup,
    AdminTOTPReset,
    AdminTotpSetup,
    ChooseOS,
    UserLoginWrapper,
    VerifyTOTP,
    MacPasswordReset,
    OauthLoginWrapper,
    RequestPasswordReset,
    RequestTOTPReset,
    SsoLoginWrapper,
    UserRegistration,
    UserResetPasswordController,
    WindowsPasswordReset,
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query && to.query.step) {
      const hasQuery = from.query;
      // no query and/or no previous step signifies email page
      const isComingFromEmailEntry = !(hasQuery && from.query.step);
      const isComingFromPaswordEntry = (hasQuery && from.query.step === 'password');
      const isComingFromGoRegistration = (hasQuery && from.query.step === 'goRegister');
      const isComingFromGoAccess = (hasQuery && from.query.step === 'goAccess');
      const isComingFromEmailOrPasswordEntry = isComingFromEmailEntry || isComingFromPaswordEntry;
      const shouldInterceptLoginNavigationToMfa = (
        isComingFromEmailOrPasswordEntry && !this.userLoginStore.password
      );

      const isComingFromPasswordReset = from.query.template === 'resetPasswordUserForm';

      const shouldInterceptNavigationToMfa = (
        shouldInterceptLoginNavigationToMfa
        && !isComingFromPasswordReset
      );

      switch (to.query.step) {
        case 'password':
          // if going to password step but not from email or JCGO steps, redirect to email entry
          // redirect is important as it will prevent the user from using browser forward button
          // params are not carried over on back button, so !to.params.error is always false
          // when !to.params.error true, it is an mfa error that directs user to password
          if (
            (!isComingFromEmailEntry && !isComingFromGoRegistration && !isComingFromGoAccess)
            && !to.params?.error
          ) {
            this.userLoginStore.password = '';
            this.interceptNavigation(from, next);
            return;
          }
          break;
        case 'mfa':
          // if going to mfa page without a password, it is from the back button
          // scenarios that cause this are after a user is locked out and after push denial
          // check the previous step to allow navigation inside mfa component
          if (shouldInterceptNavigationToMfa) {
            this.interceptNavigation(from, next);
            return;
          }
          break;
        default:
      }
    }
    next();
  },

  /**
   * Used to render the current login view on initial render of the app
   */
  created() {
    this.userLoginStore.pageLoadUrl = window.location.href;
    this.renderLoginRoute();
    // when user switches to user login from admin login, keep the form data
    this.userLoginStore.email = this.form.email;
    this.userLoginStore.password = this.form.password;
  },
  computed: {
    ...mapStores(UserLoginStore),
  },
  methods: {
    interceptNavigation(from, next) {
      // if context/redirect exist include as query params so user stays in proper flow
      // when navigating back to email entry (e.g. SSO)
      const { context, redirectTo } = from.query;
      const query = context && redirectTo ? { context, redirectTo } : {};

      next({ name: 'Login', query });
    },

    /**
     * Initializes the api specified by client type.
     * @param  {String} componentName [Name of the page rendered by vue]
     */
    async initializeApi(componentName) {
      if (componentName === 'UserLoginWrapper' || componentName === 'SsoLoginWrapper') {
        await api.user.validateDeviceCert().catch(() => {});
      }
    },
    /**
     * If there is a previous client type then return it, otherwise user is the default.
     * @return {String} [can have values 'admin' || 'user']
     */
    getPreviousClientType() {
      return Cookies.get(clientTypeCookie) || user;
    },

    /**
     * Gets the users current timezone offset and sets it in the cookie.
     * @param {String} the window location protocol
     */
    setTimezoneCookie(protocol) {
      const cookieOption = (protocol === 'https:') ? { secure: true } : null;
      Cookies.set(timezoneOffsetMinutes, new Date().getTimezoneOffset(), cookieOption);
    },

    renderLoginRoute() {
      // Three possible legacy query params that determine which Vue component to render
      const { template, context } = this.$route.query;
      const { hash } = this.$route;
      // Whichever query param is present will be used to get the route
      const LegacyRouteKey = template || context || hash;
      // get the data specific to the route, else get the default
      const routeData = LegacyQueryRoutes[LegacyRouteKey] || LegacyQueryRoutes.default;
      let { clientType } = routeData;
      // csrfType does not seem to be used anywhere
      const { componentName } = routeData;

      // set the client type cookie
      if (!clientType) {
        clientType = this.getPreviousClientType();
      }

      // Set the previous client type cookie to the current client type
      Cookies.set(clientTypeCookie, clientType);

      this.setTimezoneCookie(window.location.protocol);
      this.initializeApi(componentName).then(() => {
        this.clientType = clientType;
        this.componentName = componentName;
      });
    },
  },
  watch: {
    /**
     * The route object needs to be watched for when the url changes without a full window refresh.
     * For instance when the user navigates to #/new-org-verification the LoginApp will not rerender
     * without this.
     */
    $route() {
      this.renderLoginRoute();
    },
  },
};
</script>
