import Util from 'client/util/Util';

const {
  arrayBufferToString,
  stringToArrayBuffer,
} = Util;

export default {
  encodePublicKey(publicKey: PublicKeyCredentialRequestOptions): EncodedPublicKeyCredential {
    const allowCredentials = publicKey ? publicKey.allowCredentials?.map((cred) => ({
      ...cred,
      id: stringToArrayBuffer(cred.id),
    })) : [];

    return {
      ...publicKey,
      challenge: stringToArrayBuffer(publicKey.challenge),
      allowCredentials,
    };
  },

  decodePublicKeyCredential(publicKeyCredential: Credential): DecodedPublicKeyCredential {
    return {
      id: publicKeyCredential.id,
      rawId: arrayBufferToString(publicKeyCredential.rawId),
      type: publicKeyCredential.type,
      response: {
        clientDataJSON: arrayBufferToString(publicKeyCredential.response.clientDataJSON),
        authenticatorData: arrayBufferToString(publicKeyCredential.response.authenticatorData),
        signature: arrayBufferToString(publicKeyCredential.response.signature),
        userHandle: publicKeyCredential.response.userHandle === null
          ? null : arrayBufferToString(publicKeyCredential.response.userHandle),
      },
    };
  },
};
