import LocalStorageConstants from 'client/util/Constants/LocalStorageConstants';
import LoginConstants from 'client/util/Constants/LoginConstants';
import SignupConstants from 'client/util/Constants/SignupConstants';

/**
 * Create Constants object that can be referenced globally
 * instead of magic numbers or strings
 */
export default {
  // define the csrf url, needs the slash to be directed to base url
  csrf: '/csrf',
  dataNotAvailable: '—', // Note this is actually the &mdash; character!
  dateFormat: 'MM-DD-YYYY',
  dateTimeFormat: 'MMM DD, YYYY [@] hh:mma',
  dateTimeLongFormat: 'MM-DD-YYYY [at] hh:mma',
  // dateTimeSecondsFormat is used within the Activity Log. The format has been changed
  // because MM-DD-YYYY isn't supported for the vue.js datepicker. The vue.js
  // datepicker uses Date.parse('MM-DD-YYYY') which returns NaN on firefox and safari.
  dateTimeSecondsFormat: 'MMM DD, YYYY [@] HH:mm:ss.SS',
  darwin: 'Mac OS X',
  environments: {
    local: 'local',
    staging: 'staging',
    production: 'production',
  },
  error: 'error',
  info: 'info',
  iOS: 'iOS',
  warn: 'warn',
  jcUndefinedLogo: 'img/logoUndefined.png',
  jcUndefinedLogoPlaceholder: 'img/logoUndefinedPlaceholder.png',
  linux: 'Linux',
  mac: 'Mac OS X',
  macOS: 'Mac OS',
  macOSX: 'Mac OS X',
  modalFrameDelay: 60,
  neverExpiresText: 'Never expires',
  orgNameNotSet: '[ org name not set ]',
  orgNameNotSetLongVersion: 'organization name not set',
  filterNames: {
    os: 'os',
    mdmVendor: 'mdm.vendor',
  },
  redirectTo: 'redirectTo',
  success: 'success',
  sudoerTypes: {
    standardUser: 'standard-user',
    sudo: 'sudo',
    sudoWithoutPassword: 'sudo-without-password',
  },
  systemGroup: 'systemGroup',
  timezoneOffsetMinutes: 'timezone_offset_minutes',
  unknown: 'Unknown',
  userGroup: 'userGroup',
  windows: 'Windows',
  xsrf: 'xsrf',
};

export const ApplePackageManagers = {
  appleCustom: 'APPLE_CUSTOM',
  appleVpp: 'APPLE_VPP',
};

export const CheckboxStateConsts = {
  checked: 'checked',
  indeterminate: 'indeterminate',
  unchecked: 'unchecked',
};

export const DashboardConsts = {
  dashboardMenuItem: 'Multi-Tenant Portal Dashboard',
  locationLabel: 'Dashboard',
  menuTitle: 'Multi-Tenant Portal',
  route: 'dashboard',
};

export const DropDownSectionConsts = {
  multiSelect: 'multi-select',
  selectOne: 'select one',
};

export const ExportFileTypes = {
  csv: 'csv',
  json: 'json',
};

export const FileSizes = {
  megaByte: 1024,
};

export const FilterComponentConsts = {
  GroupDropDownFilter: 'GroupDropDownFilter',
  GroupAsideSystemDropDownFilter: 'GroupAsideSystemDropDownFilter',
  GSuiteImportSelectionDropDownFilter: 'GSuiteImportSelectionDropDownFilter',
  O365ImportSelectionDropDownFilter: 'O365ImportSelectionDropDownFilter',
  PolicyDropDownFilter: 'PolicyDropDownFilter',
  PolicySystemDropDownFilter: 'PolicySystemDropDownFilter',
  PolicySystemStatusSearchFilter: 'PolicySystemStatusSearchFilter',
  PolicySystemStatusDropDownFilter:
    'PolicySystemStatusDropDownFilter',
  SystemDropDownFilter: 'SystemDropDownFilter',
  SystemSearchFilter: 'SystemSearchFilter',
  SystemUserDropDownFilter: 'SystemUserDropDownFilter',
  SystemUserSearchFilter: 'SystemUserSearchFilter',
};

export const IdentityManagement = {
  test: 'test',
  activate: 'activate',
};

export const ItemActionConsts = {
  create: 'create',
  update: 'update',
};

export const ItemTableConsts = {
  keepFreshInterval: 30 * 1000,

  // Column names that are used in more than one ItemTable parent component are
  // added here
  columnTypes: {
    adminMfaStatusColumn: 'adminMfaStatusColumn',
    codeColumn: 'codeColumn',
    dateColumn: 'dateColumn',
    editableFieldColumn: 'editableFieldColumn',
    emailColumn: 'emailColumn',
    iconColumn: 'iconColumn',
    mfaStatusColumn: 'mfaStatusColumn',
    nameColumn: 'nameColumn',
    osColumn: 'osColumn',
    passwordStatusColumn: 'passwordStatusColumn',
    radiusMfaStatusColumn: 'radiusMfaStatusColumn',
    selectColumn: 'selectColumn',
    statusColumn: 'statusColumn',
    sudoStatusColumn: 'sudoStatusColumn',
  },

  detailsButtonType: {
    default: 'default',
    caret: 'caret',
    rowExpand: 'rowExpand',
  },
  panelType: {
    adDomainAsidePanel: 'adDomainAsidePanel',
    adminModal: 'adminModal',
    applicationAsidePanel: 'applicationAsidePanel',
    commandAsidePanel: 'commandAsidePanel',
    directoryAsidePanel: 'directoryAsidePanel',
    groupAsidePanel: 'groupAsidePanel',
    mainPanel: 'mainPanel',
    modal: 'modal',
    mtp: 'mtp',
    policiesAsidePanel: 'policiesAsidePanel',
    softwareAppsAsidePanel: 'softwareAppsAsidePanel',
    systemAsidePanel: 'systemAsidePanel',
    userAsidePanel: 'userAsidePanel',
  },

  parentOSType: {
    windows: 'windows',
    linux: 'linux',
    darwin: 'darwin',
    ios: 'ios',
  },
};

export const LocalStorageConsts = LocalStorageConstants;
export const LoginConsts = LoginConstants;

export const MdmEventsStatus = {
  Acknowledged: 'Success',
  Error: 'Error',
  Idle: 'Idle',
  NotNow: 'Device Offline',
};

export const MdmTabsIndex = {
  mdm: 0,
  vpp: 1,
};

export const DeviceTabs = {
  linux: 'linux',
  macos: 'macos',
  windows: 'windows',
};

export const MdmVendorStates = {
  internal: 'internal',
  external: 'external',
  none: 'none',
  unknown: 'unknown',
};

export const ModuleConsts = {
  systemUser: {
    errors: {
      failedActivationEmailResend:
        'Problem resending activation email to selected user(s).',
      selectedItemsEmpty: 'No users selected.',
      unableToFetchItem: 'Unable to fetch user.',
      usersCouldNotBeDeleted: 'The user(s) could not be deleted.',
      usersCouldNotBeSuspended: 'The user(s) could not be suspended.',
      usersCouldNotBeRestored: 'The user(s) could not be restored.',
    },

    accountStates: {
      activated: 'ACTIVATED',
      suspended: 'SUSPENDED',
    },

    passwordStates: {
      active: 'active',
      expired: 'expired',
      inactive: 'inactive',
      locked: 'locked',
      pending: 'pending',
    },

    mfaStates: {
      active: 'active',
      enrollment: 'enrollment',
      enrollmentExpired: 'enrollment expired',
      inactive: 'inactive',
    },
  },

  user: {
    accountStates: {
      active: 'active',
      suspended: 'suspended',
    },

    passwordStates: {
      pending: 'pending',
    },

    mfaStates: {
      active: 'active',
      inactive: 'inactive',
    },
  },

  system: {
    errors: {
      selectedItemsEmpty: 'No systems selected.',
      systemsCouldNotBeDeleted: 'The system(s) could not be deleted.',
      unableToFetchItem: 'Unable to fetch system.',
    },
  },
};

export const ChatConsts = {
  delayTime: 2 * 60 * 1000, // Delay opening by 2 minutes
  popoverBodyText: 'Accelerate your learning and implementation with live chat support from our JumpCloud engineers for your first 10 days.',
  popoverHeaderText: '24x7 Premium Support Live Chat',
  trialLength: 10, // Length of chat trial: 10 days
};

export const GSuiteConsts = {
  wistiaIframeGSuiteVideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/td1tyo67xn',
  wistiaGSuiteVideoUrl: 'https://jumpcloud-1.wistia.com/medias/td1tyo67xn',
};

const wistiaDirectoryInsightsVideoId = '1t4v7uqdjx';
const wistiaJumpCloudDemoVideoId = 'zvlqfs2pgc';
const wistiaMdmGettingSetupVideoId = 'kx7a6y116f';

export const GettingStartedConsts = {
  popoverBodyText: 'Our easy-to-follow onboarding guide makes getting started a breeze.',
  popoverHeaderText: 'Get Started with JumpCloud',
  wistiaDirectoryInsightsGettingSetupVideoUrl: `https://fast.wistia.net/embed/iframe/${wistiaDirectoryInsightsVideoId}`,
  wistiaJumpCloudDemoVideoId,
  wistiaMdmGettingSetupVideoId,
  wistiaJumpCloudDemoIframeVideoUrl: `https://jumpcloud-1.wistia.com/embed/iframe/${wistiaJumpCloudDemoVideoId}`,
  wistiaJumpCloudDemoVideoUrl: `https://jumpcloud-1.wistia.com/medias/${wistiaJumpCloudDemoVideoId}`,
  wistiaMdmGettingSetupVideoUrl: `https://fast.wistia.net/embed/iframe/${wistiaMdmGettingSetupVideoId}`,
};

export const ContextualHelpLinks = {
  // Devices
  supportArticleGettingStartedDevices: 'https://support.jumpcloud.com/support/s/article/getting-started-systems1-2019-08-21-10-36-47',
  supportArticleHowJCAgentWorks: 'https://support.jumpcloud.com/support/s/article/how-the-jumpcloud-agent-works1',
  supportArticleAgentInfo: 'https://support.jumpcloud.com/support/s/article/jumpcloud-agent-compatibility-system-requirements-and-impacts1',
  videoWistiaAddingMacSystems: 'https://fast.wistia.net/embed/channel/6mrjdjaaty?wchannelid=6mrjdjaaty&wmediaid=bp5b3ykoo9',
  videoWistiaAddingWindowsSystems: 'https://fast.wistia.net/embed/channel/6mrjdjaaty?wchannelid=6mrjdjaaty&wmediaid=l9i6buim2f',
  videoWistiaAddingLinuxSystems: 'https://fast.wistia.net/embed/channel/6mrjdjaaty?wchannelid=6mrjdjaaty&wmediaid=a59xx8pnma',
  // MDM
  supportArticleGettingStartedMdm: 'https://support.jumpcloud.com/support/s/article/Getting-Started-MDM',
  supportArticleCreatingMacOsConfig: 'https://support.jumpcloud.com/support/s/article/Create-Custom-MDM-P',
  supportArticleSecureManageMacOsRemotely: 'https://jumpcloud.com/wp-content/uploads/2020/11/How-to-Secure-Manage-macOS-Devices-Remotely.pdf',
  videoWistiaSettingUpMdm: GettingStartedConsts.wistiaMdmGettingSetupVideoUrl,
  // SystemUser
  supportArticleGettingStartedSystemUser: 'https://support.jumpcloud.com/support/s/article/getting-started-users1-2019-08-21-10-36-47',
  supportArticleConnectingUsersToResources: 'https://support.jumpcloud.com/support/s/article/connecting-users-to-resources---grant-access?r=32&ui-knowledge-components-aura-actions.KnowledgeArticleVersionCreateDraftFromOnlineAction.createDraftFromOnlineArticle=1',
  supportArticleGSuiteJC: 'https://support.jumpcloud.com/support/s/article/g-suite-user-import-provisioning-and-sync1',
  videoWistiaCreatingUsersManually: 'https://fast.wistia.net/embed/channel/6mrjdjaaty?wchannelid=6mrjdjaaty&wmediaid=vefpa8go7k',
  videoWistiaGSuiteSync: 'https://fast.wistia.net/embed/channel/6mrjdjaaty?wchannelid=6mrjdjaaty&wmediaid=mprovtf8sx',
  videoWistiaUserNamingConventions: 'https://fast.wistia.net/embed/channel/6mrjdjaaty?wchannelid=6mrjdjaaty&wmediaid=aykt37sh54',
};

export const GroupsConsts = {
  userGroupsWistiaIframeVideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/vlvyk0fu8h',
  userGroupsWistiaVideoUrl: 'https://jumpcloud-1.wistia.com/medias/vlvyk0fu8h',
  systemGroupsWistiaIframeVideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/i933051caz',
  systemGroupsWistiaVideoUrl: 'https://jumpcloud-1.wistia.com/medias/i933051caz',
};

export const LoginModalConsts = {
  excludeModalTestingCookie: 'jc_excludeModalTesting',
};

export const NotificationConsts = {
  groupedTypes: {
    groupConflicts: 'GROUP_CONFLICTS',
    securityAlerts: 'SECURITY_ALERTS',
    userConflicts: 'USER_CONFLICTS',
    userFailures: 'USER_FAILURES',
  },
  notificationTypes: {
    gidConflictType: 'GID-CONFLICT',
    groupGIDConflictType: 'GroupGID-CONFLICT',
    md5SecAlertType: 'MD5SUM-CHANGE',
    uidConflictType: 'UID-CONFLICT',
  },
};

export const O365Consts = {
  wistiaIframeO365VideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/91n9cqcgi1',
  wistiaO365VideoUrl: 'https://jumpcloud-1.wistia.com/medias/91n9cqcgi1',
};

export const OnboardingChecklistConsts = {
  dismissedKey: 'jcOnboardingChecklistDismissed',
  inProgressKey: 'jcOnboardingChecklistInProgress',
  minimizedKey: 'jcOnboardingChecklistMinimized',
  wistiaLinuxSystemSetupVideo: 'https://fast.wistia.net/embed/iframe/65eskvdepy',
  wistiaMacSystemSetupVideo: 'https://fast.wistia.net/embed/iframe/2vqd58y0fm',
  wistiaWindowsSystemSetupVideo: 'https://fast.wistia.net/embed/iframe/2t35gnwh9q',
};

export const PasswordConsts = {
  passwordCompliance: {
    custom: 'custom',
    pci3: 'pci3',
    windows: 'windows',
  },
};

export const PolicyConsts = {
  FILE_VAULT_TEMPLATE_NAME: 'filevault-2_darwin',
  BITLOCKER_TEMPLATE_NAME: 'bitlocker_fde_windows',

  policyTemplateTypes: {
    mac: 'darwin',
    linux: 'linux',
    windows: 'windows',
  },

  policyTemplateDisplayValues: {
    darwin: 'mac',
    linux: 'linux',
    windows: 'windows',
  },
};

export const PolicyStatusConsts = {
  failed: 'failed',
  pending: 'pending',
  success: 'success',
  uninstallFailed: 'uninstall_failed',
  uninstallPending: 'uninstall_pending',
};

export const PolicyStatusSearchConsts = {
  systemID: 'JumpCloud system ID',
  exitStatus: 'Exit status',
};

export const PolicyStatusSearchValueMap = {
  [PolicyStatusSearchConsts.systemID]: 'system_id',
  [PolicyStatusSearchConsts.exitStatus]: 'exit_status',
};

export const PricingLink = {
  pricing: 'https://jumpcloud.com/pricing?gclid=CjwKCAjw47eFBhA9EiwAy8kzND6pC-BuYWhNPInQNc1DhhTTOJO3f_9W-1ypQV_I_q61ZjIx71fqJRoCbAsQAvD_BwE&creative=489719249267&keyword=jumpcloud%20pricing&matchtype=e&network=g&device=c&utm_source=GoogleAds-Paid&utm_medium=Paid-Search&utm_term=jumpcloud%20pricing&utm_content=489719249267&utm_campaign=Search_US_Brand&gclid=CjwKCAjw47eFBhA9EiwAy8kzND6pC-BuYWhNPInQNc1DhhTTOJO3f_9W-1ypQV_I_q61ZjIx71fqJRoCbAsQAvD_BwE',
};

export const SignupConsts = SignupConstants;

export const Sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const SoftwareAppDesiredStatusConsts = {
  INSTALL: {
    value: 'INSTALL',
    notificationLabel: 'Installing to devices.',
    bindingAction: 'INSTALL',
  },
  UNINSTALL: {
    value: 'UNINSTALL',
    notificationLabel: 'Uninstalling from devices.',
    bindingAction: 'UNINSTALL',
  },
  autoUpdate: {
    value: 'autoUpdate',
    notificationLabel: 'Installing to devices and keeping app up to date.',
    bindingAction: 'INSTALL',
  },
};

export const SoftwareAppBindingModalConsts = {
  INSTALL: {
    bindingPreposition: 'to',
    bindingActionLabel: 'be installed to',
    bindingInfoText: '<p>After installation the software will be managed by JumpCloud.</p> <p><em>Pro Tip: We recommend that you test installing managed software prior to binding to your entire fleet.</em></p>',
    unbindingPreposition: 'on',
    unbindingActionLabel: 'no longer be managed on',
  },
  UNINSTALL: {
    bindingPreposition: 'from',
    bindingActionLabel: 'be uninstalled from',
    bindingInfoText: '<p>Software that is uninstalled is still managed by JumpCloud.</p>',
    unbindingPreposition: 'on',
    unbindingActionLabel: 'no longer be managed on',
  },
};

export const SoftwareAppStatusConsts = {
  FAILED: {
    iconColorClass: 'critical',
  },
  PENDING: {
    iconColorClass: 'pending',
  },
  SUCCESS: {
    iconColorClass: 'active',
  },
  UNINSTALL_FAILED: {
    iconColorClass: 'critical', message: 'FAILURE', value: 'UNINSTALL_FAILED', status: 'Uninstall Failed',
  },
  UNINSTALL_PENDING: {
    iconColorClass: 'pending', message: 'PENDING', value: 'UNINSTALL_PENDING', status: 'Uninstall Pending',
  },
  UNINSTALL_SUCCESS: {
    iconColorClass: 'active', message: 'SUCCESS', value: 'UNINSTALL_SUCCESS', status: 'Uninstall Success',
  },
};

export const StatusColorNames = {
  active: 'active',
  critical: 'critical',
  info: 'info',
  inactive: 'inactive',
  warning: 'warning',
};

export const StatusPageConsts = {
  componentIds: {
    ADMIN_PORTAL: 'c3yzt88nkrsz',
    GENERAL_ACCESS_API: 'mxvd5kwmcx1v',
    SSO: 'lwn12jvytbmh',
    USER_PORTAL: 'plywvbnrx9bc',
  },
  componentStatuses: {
    OPERATIONAL: 'operational',
    DEGRADED: 'degraded_performance',
    PARTIAL_OUTAGE: 'partial_outage',
    MAJOR_OUTAGE: 'major_outage',
    MAINTENANCE: 'under_maintenance',
  },
};

export const TableWithPaginationConsts = {
  rowsPerPageOptions: [10, 20, 50],
};

export const UniversityConsts = {
  url: 'http://www.jumpcloud.com/university',
};

export const UnsavedChangesModalConsts = {
  headerText: 'Unsaved Changes',
  bodyText: 'The changes you\'ve made to this item will be lost if you leave this page. Would you like to continue?',
};

export const UserRoleConsts = {
  administrator: 'Administrator',
  administratorWithBilling: 'Administrator With Billing',
  commandRunner: 'Command Runner',
  commandRunnerWithBilling: 'Command Runner With Billing',
  helpDesk: 'Help Desk',
  manager: 'Manager',
  readOnly: 'Read Only',
};

export const UserSearchConsts = {
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  userID: 'User ID',
  username: 'Username',
  displayName: 'Display name',
  department: 'Department',
  employeeID: 'Employee ID',
  employeeType: 'Employee type',
  jobTitle: 'Job title',
  location: 'Location',
  systemUsername: 'Local User Account',
};

export const UserSearchValueMap = {
  [UserSearchConsts.email]: 'email',
  [UserSearchConsts.firstName]: 'firstname',
  [UserSearchConsts.lastName]: 'lastname',
  [UserSearchConsts.userID]: '_id',
  [UserSearchConsts.username]: 'username',
  [UserSearchConsts.displayName]: 'displayname',
  [UserSearchConsts.department]: 'department',
  [UserSearchConsts.employeeID]: 'employeeIdentifier',
  [UserSearchConsts.employeeType]: 'employeeType',
  [UserSearchConsts.jobTitle]: 'jobTitle',
  [UserSearchConsts.location]: 'location',
  [UserSearchConsts.systemUsername]: 'systemUsername',
};

export const ViewportBannerConsts = {
  types: {
    danger: 'bannerDanger',
    info: 'bannerInfo',
    secondary: 'bannerSecondary',
    warning: 'bannerWarning',
  },
};

export const WorkdayConsts = {
  importResultStatus: {
    pending: 'pending',
    started: 'started',
    inProgress: 'in progress',
    finished: 'finished',
    errored: 'errored',
    canceled: 'canceled',
  },
};

/**
 * Default values for XMLHttpRequests
 */
export const XhrConsts = {
  XsrfInputName: '_xsrf',
  xCSRFToken: 'X-CSRF-Token',
  xOrgIdHeader: 'X-Org-Id',
  xOrgIdKey: 'jc-orgId',
  xTotalCount: 'X-Total-Count',
  xXsrfToken: 'X-Xsrftoken',
  xRedirectHeader: 'x-redirect-to',
};
