<template>
  <div :class="$style.formContainer">
    <LoginCard
      header="Set Administrator Password"
      :showCustomLogo="false"
      v-if="!shouldShowTOTPInput"
    >
      <Alert
        v-if="errorMessage"
        :message="errorMessage"
      />
      <Alert
        centered
        v-if="isExpired"
      >
        <p :class="$style.alertHeader">
          This password reset link has expired.
        </p>
        <p :class="$style.alertText">
          Check your inbox for the most recent link or reset your password
          again. For security purposes, this link expires after 1 hour.
        </p>
      </Alert>

      <ResetPassword
        :clientType="clientType"
        :isLoading="isLoading"
        :email="$route.query.email"
        :error="errorMessage"
        :form="form"
        :disabled="isDisabled"
        :ref="formRef"
        :submit="submit"
      />
    </LoginCard>
    <VerifyTOTP
      v-if="shouldShowTOTPInput"
      :clientType="clientType"
      :passwordResetInfo="form"
      :shouldShowCustomLogo="true"
      :disabled="false"
      :mfaDisplayText="mfaDisplayText"
    />
    <!-- This component is also in VerifyTOTP, so only show it here if necessary -->
    <LogoIcon v-if="!shouldShowTOTPInput" />
  </div>
</template>

<script>
import { LoginConsts } from 'client/util/Constants';
import { get } from 'lodash';
import ConfirmValidation from 'client/components/mixins/ConfirmValidation';
import LocalStorageService from 'client/util/LocalStorageService';
import LoginCard from 'client/components/LoginCard.vue';
import MfaStrings from 'client/containers/Login/MfaStrings';
import ResetPassword from 'client/components/forms/ResetPassword.vue';
import ResetPasswordStore from 'client/stores/ResetPasswordStore';
import RouteGenerator, { AdminRoutes } from 'client/routes/RouteGenerator';
import RouteHelpers from 'client/util/RouteHelpers';
import VerifyTOTP from 'client/containers/AdminVerifyTOTP.vue';
import api from 'client/api';
import loginUtil from 'client/components/mixins/loginUtil';

const { adminPortalLogin } = LoginConsts.documentTitles;
const { passwordResetError, passwordResetSuccess } = LoginConsts.responseMessages;
const { formRef } = LoginConsts.formElements;
const { adminTotpSetup } = RouteGenerator;

export default {
  // This component handles both password reset and new account creation
  name: 'AdminPasswordResetAndSetup',

  LocalStorageService,

  mixins: [loginUtil, ConfirmValidation],

  components: {
    LoginCard,
    ResetPassword,
    VerifyTOTP,
  },

  RouteHelpers,

  computed: {
    isDisabled() {
      return (
        !this.form.confirm
        || !this.form.password
        || this.form.license === false
        || (this.form.confirm !== this.form.password)
      );
    },
  },

  props: {
    email: {
      default: null,
      type: String,
    },
    requestKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: {
        confirm: '',
        license: false,
        password: '',
        email: this.email,
        confirmationCode: this.requestKey,
      },
      shouldShowTOTPInput: false,
      isExpired: false,
      mfaDisplayText: MfaStrings.contexts.totp.passwordReset,
    };
  },

  created() {
    this.validateRequestKey();
    this.formRef = formRef;
    this.$options.RouteHelpers.setDocumentTitle(adminPortalLogin);
  },
  setup() {
    const resetPasswordStore = ResetPasswordStore();
    return { resetPasswordStore };
  },
  methods: {
    request() {
      return this.verifyPasswordRequest()
        .finally(() => {
          this.isLoading = false;
        });
    },

    validateRequestKey() {
      if (!this.requestKey) {
        this.isExpired = true;
        return;
      }

      api[this.clientType]
        .validateKey(this.requestKey)
        .catch(() => {
          this.isExpired = true;
        });
    },

    async verifyPasswordRequest() {
      const resetPassword = {
        confirm: this.form.confirm,
        password: this.form.password,
        license: this.form.license,
        email: this.email,
        confirmationCode: this.form.confirmationCode,
        // Don't have an OTP yet. User will receive new TOTP key they need to confirm
        // in the next step if MFA is enabled on the user.
      };
      this.resetPasswordStore.commitEntireStateChange(resetPassword);

      return this.resetPasswordStore.verifyResetPassword()
        .then(() => {
          this.$options.LocalStorageService.setItem('resetSuccessMessage', passwordResetSuccess);
          this.redirect(AdminRoutes.logout);
        })
        .catch((error) => {
          const totpKey = get(error, 'response.data.totpKey', null);

          if (error.response.status === 401 && !totpKey) {
            this.shouldShowTOTPInput = true;
          } else if (error.response.status === 401 && totpKey) {
            this.vueRouteChange(adminTotpSetup, { totp: totpKey });
          } else {
            this.errorMessage = get(error, 'response.data.message', passwordResetError);
          }
        });
    },
  },
};
</script>

<style module>
@import "../css/login-components.css";

.alertHeader {
  font-size: 1.25em;
  margin-top: 0;
}

.alertText {
  margin-bottom: 0;
}

</style>
