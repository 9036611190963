
// Libraries
import { defineComponent } from 'vue';

// Utils
import routerService from 'client/services/routerService';
import userLoginService from 'client/services/userLoginService';

// Components
import PushView from 'client/containers/user/Views/PushView.vue';

// Constants and Types
import { LoginConsts } from 'client/util/Constants';
import PushMfaAuthApi, { PushAuthenticationStatus } from 'client/api/PushMfaAuthApi';

// Errors
import PushError from 'client/services/response/PushError';
import RequestError from 'client/services/response/RequestError';

const {
  errorParamTypes,
  pushStatusMessages,
} = LoginConsts;

export default defineComponent({
  name: 'PushUserLoginHandler',

  components: {
    PushView,
  },

  props: {
    controllingQueryParams: {
      type: Object,
      required: true,
    },
    hasPendingErrorMessage: Boolean,
  },

  data() {
    return {
      isDisabled: true,
    };
  },

  mounted() {
    this.submit();
  },

  methods: {
    handleError(error: unknown): Promise<void> | void {
      const handleExpectedError = async (): Promise<void> => {
        const { status, pushId } = error as PushError;

        if (status === PushAuthenticationStatus.DENIED
          || status === PushAuthenticationStatus.EXPIRED) {
          try {
            await PushMfaAuthApi.finishPushAuthentication(pushId);
          } catch (e) {
            // Failure is expected and not an error
          }

          if (status === PushAuthenticationStatus.DENIED) {
            this.$emit('clearCredentials');

            // push denial will boot user back to login
            // need to pass error param when navigating from outside /login, like ssoStepUp
            // need to keep query params when doing sso login
            routerService.goToUserLogin({
              query: {
                error: errorParamTypes.pushDenied,
                ...this.controllingQueryParams,
              },
            });
            return;
          }
        }

        const message = pushStatusMessages[status as 'errored' | 'expired' | 'pushDenied' | 'pwdResetDenied' | 'pwdResetSuccess'];
        this.$emit('error', new RequestError(0, message));
      };

      const handleUnexpectedError = (): void => {
        this.$emit('error', error);
      };

      const isExpectedError = error instanceof PushError;

      return isExpectedError ? handleExpectedError() : handleUnexpectedError();
    },

    async submit(): Promise<void> {
      this.isDisabled = true;
      this.$emit('resetErrorMessage');

      try {
        await userLoginService.verifyPush();

        this.$emit('success');
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isDisabled = false;
      }
    },
  },
});
