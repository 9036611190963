
import { defineComponent } from 'vue';
import { useLoginLabels } from 'client/util/Constants/LoginLabels';
import LoginActionButton from 'client/components/LoginActionButton.vue';

// This component is specifically for the Android Mobile Device Trust SSO flow
// The extra click provided by this button satisfies Android's user interaction
// requirements for deep links. See https://jumpcloud.atlassian.net/browse/ZR-1076
export default defineComponent({
  name: 'SSOGoContinue',

  components: {
    LoginActionButton,
  },

  setup() {
    const uiLabel = useLoginLabels();
    return { uiLabel };
  },

  props: {
    action: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    submit() {
      if (!this.isLoading) {
        this.action();
      }
    },
  },
});
