import { OidcConsentPostPayload } from 'client/types/OidcConsent';
import { ValidatorResponse } from 'client/types/ApiValidator';

export default class AdminLoginApiValidator {
  static validateCompleteConsentChallengeOidcPayload(
    payload: OidcConsentPostPayload,
  ): ValidatorResponse {
    try {
      const {
        challenge,
        remember_me: rememberMe,
        scopes,
        ...rest
      } = payload;

      if (!challenge) {
        throw new Error('challenge is required');
      }

      if (rememberMe && typeof rememberMe !== 'boolean') {
        throw new Error('remember_me must be a boolean');
      }

      if (scopes && typeof scopes !== 'object') {
        throw new Error('scopes must be an object');
      }

      if (Object.keys(rest).length) {
        throw new Error('challenge, remember_me, and scopes are the only valid keys');
      }

      return {
        isValid: true,
      };
    } catch (err: unknown) {
      let message;

      if (err instanceof Error) {
        message = err.message;
      }

      return {
        isValid: false,
        message: message || 'Invalid payload',
      };
    }
  }
}
