import AdminLoginApi from 'client/api/AdminLoginApi';
import AdminLoginWrapper from 'client/containers/Login/AdminLoginWrapper.vue';
import AdminOidcConsent from 'client/containers/Oidc/AdminOidcConsent.vue';
import AdminTotpSetup from 'client/containers/AdminTotpSetup/AdminTotpSetup.vue';
import DuoCallbackHandler from 'client/containers/user/UserResetPassword/UserResetPasswordMfaHandlers/DuoCallbackHandler.vue';
import JumpCloudOidcCallback from 'client/containers/Oidc/JumpCloudOidcCallback.vue';
import LoginApp from 'client/containers/LoginApp.vue';
import LoginConsts from 'client/util/Constants/LoginConstants';
import OidcError from 'client/containers/Oidc/Error.vue';
import ProviderNotLinked from 'client/containers/ProviderNotLinked/ProviderNotLinked.vue';
import RouteGenerator, { VueRouterMode } from 'client/routes/RouteGenerator';
import SsoStepUpWrapper from 'client/containers/Login/SsoStepUpWrapper.vue';
import UserLoginApi from 'client/api/UserLoginApi';
import Util from 'client/util/Util';
import VueRouter from 'vue-router';
import loginStore from 'client/stores/loginStore';
import useUserLoginStore from 'client/stores/UserLoginStore';

const {
  documentTitles, loginRoutes, oidc, pushStatusMessages, responseMessages, userConsoleRootRoute,
} = LoginConsts;
const { ssoStepUp } = loginRoutes;
const {
  adminPortalLogin,
  adminPortalTotpSetup,
  userPortalLogin,
} = documentTitles;
const {
  adminOidcConsent, duoCallback, jumpcloudClientOidcCallback, login, loginAdmin,
} = RouteGenerator;

export const LOGIN_CHALLENGE_KEY = 'login_challenge';

const persistOidcChallenges = (routeInfo) => {
  const { parameters = {} } = routeInfo;
  const { currentRoute = {} } = parameters;

  if (currentRoute.query.login_challenge) {
    const challenge = currentRoute.query.login_challenge;

    // Note - persists in memory for further login flows (TOTP / Mfa for admins)
    loginStore.dispatch('LoginOauthAdminFlowModel/setLoginChallenge', challenge);

    // Note - persists in the session for login flows that leave and return i.e. "Login with Google"
    window.sessionStorage.setItem(LOGIN_CHALLENGE_KEY, challenge);
  }
};

const redirectForOidc = (completionFunc) => completionFunc()
  .then(({ data: { redirect_to: oidcRedirect } }) => Util.redirect(oidcRedirect))
  .catch(() => {
    Util.redirect(oidc.error);
  });

const handleOidcQueryChallengeOrRedirect = async (
  {
    login_challenge: loginChallenge,
    consent_challenge: consentChallenge,
    code: oidcFinishCode,
  },
  sessionLoginChallenge,
  loggedInRoute,
) => {
  try {
    const resolvedLoginChallenge = loginChallenge || sessionLoginChallenge;

    if (consentChallenge) {
      await redirectForOidc(() => AdminLoginApi.completeConsentChallengeOIDC({
        challenge: consentChallenge,
      }));
    } else if (resolvedLoginChallenge) {
      await redirectForOidc(() => AdminLoginApi.completeLoginChallengeOIDC(resolvedLoginChallenge));
    } else if (!oidcFinishCode) {
      Util.redirect(loggedInRoute);
    }
  } catch (_) {
    Util.redirect(oidc.error);
  }
};

const handleExistingSessionRedirect = (routeInfo) => {
  const { parameters = {} } = routeInfo;
  const { currentRoute = {} } = parameters;
  let { loggedInRoute } = currentRoute.meta;

  const sessionLoginChallenge = window.sessionStorage.getItem(LOGIN_CHALLENGE_KEY);
  window.sessionStorage.removeItem(LOGIN_CHALLENGE_KEY);

  if (currentRoute.query && currentRoute.query.error && loggedInRoute.indexOf('?error=') === -1) {
    loggedInRoute += `/#/?error=${currentRoute.query.error}`;
  }

  if (currentRoute.query || sessionLoginChallenge) {
    return handleOidcQueryChallengeOrRedirect(
      currentRoute.query,
      sessionLoginChallenge,
      loggedInRoute,
    );
  }

  return Util.redirect(loggedInRoute);
};

/**
 * This middleware was created to validate if the user has an active session,
 * if it does... the user does not need to go the login page,
 * so we avoid the router to render the login, and then we redirect the user to the /consoleUser#
*/
export const SessionValidatorPipeline = (routeInfo) => {
  const { next, parameters = {} } = routeInfo;
  const { currentRoute = {} } = parameters;

  // added an addition check for a template as the LoginApp routing was causing an issue
  if (currentRoute.query
    && (currentRoute.query.context
      || currentRoute.query.template
      || currentRoute.query.login_challenge
      || currentRoute.query.consent_challenge)) {
    const { context, template } = currentRoute.query;

    persistOidcChallenges(routeInfo);

    if (context || template) {
      return next();
    }
  }

  return currentRoute.meta.getUser()
    .then(() => handleExistingSessionRedirect(routeInfo))
    .catch(() => next());
};

export const preprocessRoute = (currentRoute, from, callback) => {
  const { pipeline } = currentRoute.meta || {};

  if (pipeline && pipeline.length) {
    Util.chainPipelineSegments(pipeline.concat([callback]), { currentRoute, from })();
  } else callback();
};

const processTitle = (currentRoute, from, callback) => {
  const { title } = currentRoute.meta || {};

  document.title = title || currentRoute.name || 'JumpCloud';

  callback();
};

// check that fullPath includes verifyemail then store hash in sessionStorage
export const verifyEmailCheck = (routeInfo) => {
  const { next, parameters = {} } = routeInfo;
  const { currentRoute = {} } = parameters;
  if (currentRoute.fullPath.includes('verifyemail')) {
    const store = useUserLoginStore();
    store.verifyEmailHash = currentRoute.hash;
  }
  next();
};

export const RouterOptions = {
  mode: VueRouterMode,
  routes: [
    {
      path: login,
      name: 'Login',
      component: LoginApp,
      props: (route) => ({
        ...route.params,
        error: route.params.error
          || responseMessages[route.query.error]
          || pushStatusMessages[route.query.error],
        success: route.params.success || pushStatusMessages[route.query.success],
      }),
      meta: {
        loggedInRoute: userConsoleRootRoute,
        getUser: () => UserLoginApi.getUser(),
        pipeline: [SessionValidatorPipeline, verifyEmailCheck],
        title: userPortalLogin,
      },
      /**
       * This "beforeEach" callback needs to be added in order to support custom router middlewares
       * or pipelines as the SessionValidatorPipeline.
       */
      beforeEnter: preprocessRoute,
    },
    {
      path: duoCallback,
      name: 'Duo Callback',
      component: DuoCallbackHandler,
      props: (route) => ({
        ...route.query,
      }),
      beforeEnter: (to, _, next) => {
        const { duo_code: duoCode, state } = to.query;

        if (!duoCode || !state) {
          next({ path: login });
        } else {
          next();
        }
      },
    },
    {
      path: loginAdmin,
      name: 'Admin Login',
      component: AdminLoginWrapper,
      props: (route) => ({
        ...route.params,
        error: route.params.error || route.query.error,
      }),
      meta: {
        loggedInRoute: '/#/home',
        getUser: () => AdminLoginApi.getSession(),
        pipeline: [SessionValidatorPipeline],
        title: adminPortalLogin,
      },
      beforeEnter: preprocessRoute,
    },
    {
      path: adminOidcConsent,
      name: 'Admin OIDC Consent',
      component: AdminOidcConsent,
      meta: {
        getUser: () => AdminLoginApi.getSession(),
        title: documentTitles.adminOidcConsent,
      },
      props: (route) => ({
        ...route.params,
        error: route.params.error || route.query.error,
      }),
      beforeEnter: (to, _, next) => {
        const noConsentChallenge = !to.query?.consent_challenge;

        if (noConsentChallenge) {
          next({ path: oidc.error });
        } else {
          next();
        }
      },
    },
    {
      path: `/${RouteGenerator.adminTotpSetup}`,
      name: RouteGenerator.adminTotpSetup,
      component: AdminTotpSetup,
      props: true,
      meta: {
        title: adminPortalTotpSetup,
      },
      beforeEnter: preprocessRoute,
    },
    {
      path: RouteGenerator.loginProviderNotLinked,
      name: 'Provider Not Linked',
      component: ProviderNotLinked,
    },
    {
      path: ssoStepUp,
      name: 'Stepup Choose MFA',
      component: SsoStepUpWrapper,
      props: (route) => {
        const factorsRaw = (route.query && route.query.factors) || '';
        const mfaType = route.query && route.query.mfaType;

        return {
          factorsRaw,
          mfaType,
        };
      },
      beforeEnter: (to, _, next) => {
        const state = to.query && to.query.state;
        loginStore.dispatch('StepUpModel/setStateId', state);

        const path = !state ? login : undefined;

        next(path);
      },
    },
    {
      path: jumpcloudClientOidcCallback,
      name: 'JumpCloud OIDC Callback',
      component: JumpCloudOidcCallback,
      meta: {
        title: documentTitles.userPortalLogin,
      },
    },
    {
      path: oidc.error,
      name: 'OIDC Error',
      component: OidcError,
    },
    {
      path: '*',
      redirect: {
        name: 'Login',
      },
    },
  ],
};

const router = new VueRouter(RouterOptions);

router.beforeEach(processTitle);

export default router;
