
import { defineComponent } from 'vue';
import _ from 'lodash';

export default defineComponent({
  name: 'LoginTextField',
  props: {
    autocomplete: {
      type: Boolean,
      default: true,
    },

    autoFocus: {
      type: Boolean,
      default: false,
    },

    isEditable: {
      type: Boolean,
      default: true,
    },

    label: {
      type: String,
      default: '',
    },

    maxlength: {
      type: Number,
      default: null,
    },

    name: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Object,
      default: () => {},
    },

    submitOnEnter: {
      type: Function,
      default: () => {},
    },

    type: {
      type: String,
      default: 'text',
    },

    value: {
      type: String,
      required: true,
    },

    vvName: {
      type: String,
      required: true,
    },
  },
  emits: ['focus', 'input'],
  methods: {
    handleFocus() {
      this.$emit('focus');
    },
    handleInput(e: Event) {
      this.errors.clear();
      this.$emit('input', (e.target as HTMLInputElement).value);
    },
    handleEnter() {
      this.submitOnEnter();
    },
  },
  computed: {
    validationRules() {
      const rules = {
        required: this.required,
        email: this.type === 'email',
      };
      if (this.rules) {
        _.extend(rules, this.rules);
      }
      return rules;
    },
  },
  directives: {
    focus: {
      inserted(el, _binding, vnode: any) {
        if (vnode.context.autoFocus) {
          el.focus();
        }
      },
    },
  },
});

