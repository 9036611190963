function buffToBase64(data: Uint8Array): string {
  const base64 = btoa(String.fromCharCode(...data));
  return base64.replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export function makePkceCode():string {
  const bytes = new Uint8Array(32);
  window.crypto.getRandomValues(bytes);
  return buffToBase64(bytes);
}

export async function digest(message: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return buffToBase64(new Uint8Array(hash));
}
