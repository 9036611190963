
import { PropType, defineComponent } from 'vue';

import Card from 'client/components/Card.vue';
import Checkbox from 'client/components/forms/Checkbox.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import OidcConfirmations from 'client/containers/Oidc/OidcConfirmations.vue';

import type { OidcClaim } from 'client/types/OidcConsent';

export default defineComponent({
  name: 'OidcConsentView',

  components: {
    Card,
    Checkbox,
    LoginActionButton,
    OidcConfirmations,
  },

  props: {
    displayName: {
      type: String,
      required: true,
    },
    logoUrl: {
      type: String,
      required: true,
    },
    claims: {
      type: Array as PropType<OidcClaim[]>,
      required: true,
    },
    rememberMe: {
      type: Boolean,
      default: false,
    },
    confirmations: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    },
  },
});
