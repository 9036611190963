<template>
  <div :class="$style.formContainer">
    <LoginCard
      key="confirmation"
      class="email-sent-content"
      header="MFA Request Sent"
    >
      <h5 :class="[$style.subheader, $style.mfaHeading]">
        This is for resetting your app or enrolling for the first time.

        <a
          :class="$style.supportArticleLink"
          href="https://jumpcloud.com/support/enable-mfa-for-the-admin-portal"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More ↗️
        </a>
      </h5>
      <ul :class="$style.directionsList">
        <li :class="$style.directions">
          A confirmation email has been sent (allow up to 10 minutes for email to arrive).
        </li>
        <li :class="$style.directions">
          Your email address must be registered with JumpCloud.
        </li>
        <li :class="$style.directions">
          <!-- eslint-disable-next-line max-len -->
          If you do not receive an email or have further issues, reach out to another admin at your org.
        </li>
      </ul>
      <LoginActionButton
        :action="goToLogin"
      >
        Return to Login
      </LoginActionButton>
    </LoginCard>
  </div>
</template>

<script>
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginCard from 'client/components/LoginCard.vue';

export default {
  name: 'RequestTOTPReset',

  components: {
    LoginActionButton,
    LoginCard,
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: 'Admin Login' });
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

@value (jcBlue) from '../css/colors.css';

.mfaHeading {
  text-align: left;
}

.supportArticleLink {
  color: jcBlue;
  text-decoration: none;
}

.supportArticleLink:hover {
  text-decoration: underline;
}

.directionsList {
  margin-top: 20px;
  text-align: left;
}

.directions {
  margin-bottom: 1.5em;
}

.fadeEnterActive {
  transition: opacity 0.35s 0.35s;
}

.fadeLeaveActive {
  transition: opacity 0.35s;
}

.fadeEnter,
.fadeLeaveTo {
  opacity: 0;
}
</style>
