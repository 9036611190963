import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    stateId: '',
  },
  getters: {
    stateId: (state) => state.stateId,
  },
  mutations: {
    modifyStateId: (state, stateId = '') => {
      Vue.set(state, 'stateId', stateId);
    },
  },
  actions: {
    setStateId({ commit }, stateId = '') {
      commit('modifyStateId', stateId);
    },
  },
};
