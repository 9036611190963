<template>
  <div>
    <LoginHeader />
    <div :class="$style.linksContainer">
      <Heading
        :provider="'Google'"
      />
      <div :class="$style.loginItemsContainer">
        <div
          v-for="(item, index) in loginItems"
          :class="$style.loginItems"
          :key="item.id"
        >
          <div :class="$style.textContainer">
            <div :class="$style.circle">
              {{ index + 1 }}
            </div>
            {{ item.text }}
          </div>

          <div :class="$style.buttonContainer">
            <ButtonAnchor
              :class="$style.button"
              :text="item.linkText"
              :href="item.link"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonAnchor from 'client/components/ButtonAnchor.vue';
import Heading from 'client/components/Heading.vue';
import LoginHeader from 'client/components/LoginHeader.vue';
import RouteGenerator from 'client/routes/RouteGenerator';

const { loginAdmin, signup } = RouteGenerator;

export default {
  name: 'ProviderNotLinked',

  components: {
    ButtonAnchor,
    Heading,
    LoginHeader,
  },

  data() {
    return {
      loginItems: [
        {
          id: 1,
          link: loginAdmin,
          linkText: 'Login',
          text: 'Are you trying to login to JumpCloud? Login using your email and password.',
        },
        {
          id: 2,
          link: signup,
          linkText: 'Signup for JumpCloud',
          text: 'Would you like to sign up for an Admin Portal account?',
        },
      ],
    };
  },
};
</script>

<style module>
.linksContainer {
  margin: 5rem auto;
  text-align: center;
  width: fit-content;
}

.textContainer {
  display: inline-flex;
  margin-bottom: 1.5rem;
  text-align: left;
}

.circle {
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 50%;
  color: currentColor;
  display: flex;
  height: 10px;
  justify-content: center;
  margin-right: 1rem;
  padding: 1rem;
  width: 10px;
}

.button {
  width: 190px;
}

.buttonContainer {
  padding-right: 3rem;
}

.loginItems {
  display: inline-block;
  margin: 1rem 0;
  max-width: 500px;
  padding: 0 5rem;
}

.loginItemsContainer {
  max-width: 1135px;
  padding-top: 3rem;
}
</style>
