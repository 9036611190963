import { LoginConsts } from 'client/util/Constants';

const { vvName, errorMessage } = LoginConsts.formElements.confirmPassword;
/**
 * This mixin was created as a fix to vee-validate's defective confirm rule.
 *  Vee-validates confirm rule works but does not disappear, so to avoid any confusion
 *  we created this mixin to manually add the errors to the error bag.
 *  This mixin assumes the following:
 *    - component has the vee-validate error bag
 *    - component store the password & confirm values in data
 */
export default {
  methods: {
    /**
     * Custom validation for the confirm input. Ensures that the form will not submit
     * if the input values are not equal. Also adds a custom error to vee validate
     * error bag.
     * @return {Boolean} [represents if the form passed validation]
     */
    customValidation() {
      const { password, confirm } = this.form;
      const { formComponent } = this.$refs;
      const component = formComponent || this;
      if (password !== confirm) {
        component.errors.add({
          field: vvName,
          msg: errorMessage,
        });
        return false;
      }
      return true;
    },
  },

  watch: {
    /**
     * We need to watch the form to make sure to remove the confirm password
     * error when password & confirm match. Assumes that the password & confirm
     * are in the components data.form.
     */
    form: {
      handler() {
        const { password, confirm } = this.form;
        const valid = (password === confirm);
        const { formComponent } = this.$refs;
        const component = formComponent || this;
        const hasError = component.errors.has(vvName);
        if (hasError && valid) {
          component.errors.remove(vvName);
        }
      },
      deep: true,
    },
  },
};
