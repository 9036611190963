export interface MfaFactorResponse {
  type: 'duo' | 'push' | 'totp' | 'webauthn';
  clientType?: string;
  status: string;
}
interface MfaChallenge {
  duo: DuoChallenge;
}

export default class MfaRequiredError {
  factors: MfaFactorResponse[];

  challenge: MfaChallenge | undefined;

  pwdResetToken: string;

  // eslint-disable-next-line max-len
  constructor(factors: MfaFactorResponse[], challenge: MfaChallenge | undefined, pwdResetToken: string) {
    this.factors = factors;
    this.challenge = challenge;
    this.pwdResetToken = pwdResetToken;
  }
}
