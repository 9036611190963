<template>
  <div
    :class="$style.adminTotpSetupStep"
  >
    <div
      :class="$style.numberContainer"
    >
      <div
        :class="$style.numberContainerNumber"
        data-test-id="adminTotpSetupStepNumber"
      >
        {{ number }}
      </div>
    </div>
    <div
      :class="$style.contentContainer"
    >
      <h3
        :class="$style.contentContainerTitle"
        data-test-id="adminTotpSetupStepTitle"
      >
        {{ title }}
      </h3>
      <div>
        <slot name="adminTotpSetupStepMessage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
};

</script>

<style module>
@value (jcGrey800, jcGrey900) from 'css/brand-colors.css';

.adminTotpSetupStep {
  display: flex;
  margin-bottom: 1rem;
}

.numberContainerNumber {
  align-items: center;
  border: 1px solid jcGrey900;
  border-radius: 50%;
  display: flex;
  height: 2em;
  justify-content: center;
  width: 2em;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin: 0 1em;
}

.contentContainerTitle {
  font-size: 1.125rem;
  margin-top: 0.1874rem;
}

</style>
