
import { defineComponent } from 'vue';

// Components
import WebAuthnView from 'client/containers/user/Views/WebAuthnView.vue';

// Services
import userResetPasswordService from 'client/services/userResetPasswordService';

export default defineComponent({
  name: 'WebAuthnUserResetPasswordHandler',

  components: {
    WebAuthnView,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    requestKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async submitPasswordReset(): Promise<void> {
      this.isLoading = true;
      this.$emit('resetErrorMessage');

      try {
        const passwordResetInfo = {
          confirm: this.form.confirm,
          confirmationCode: this.requestKey,
          password: this.form.password,
          pwdResetToken: this.form.pwdResetToken,
        };

        await userResetPasswordService.verifyWebAuthnSignature(
          passwordResetInfo,
          this.form.challenge.webauthn,
        );

        this.$emit('success');
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
