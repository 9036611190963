<template>
  <div :class="$style.wrapper">
    <img
      v-if="photo"
      :class="$style.imgUser"
      :src="photo"
      alt="user-photo"
      data-test-id="userImage"
    >
    <span
      v-if="email"
      :class="$style.email"
      data-test-id="userEmail"
    >
      {{ email }}
    </span>
    <button
      data-test-id="goButton"
      :class="[$style.button, isLoading ? $style.isLoading : '']"
      @click="handleClick"
      :disabled="isLoading"
    >
      <span :class="$style.buttonContent">
        <span :class="$style.iconWrapper">
          <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg" :class="$style.iconRocket" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.255 9.825a8.274 8.274 0 013.54-7.49 8.274 8.274 0 013.539 7.49l-.372 4.449-.228.882H6.855l-.228-.883-.372-4.448zM4.621 14.39l-.367-4.4a10.224 10.224 0 01-.008-.102C3.6 10.9 2.977 12.467 2.809 14.855l1.812-.465zm-.115-7.668A10.29 10.29 0 019.23.316a1.1 1.1 0 011.13 0 10.288 10.288 0 014.723 6.407c.387.271.856.668 1.33 1.235 1.09 1.304 2.152 3.44 2.377 6.842.091 1.381-1.216 2.325-2.465 2.005l-1.576-.404c-.175.446-.61.757-1.113.757h.176v1c0 1.106-.9 2.002-2.009 2.002H7.786a2.005 2.005 0 01-2.009-2.001v-1.001h.176c-.503 0-.938-.311-1.113-.757l-1.576.404C2.015 17.125.708 16.18.8 14.8c.225-3.402 1.288-5.538 2.378-6.842a6.914 6.914 0 011.329-1.235zm12.274 8.132c-.169-2.388-.792-3.955-1.437-4.966l-.008.102-.368 4.4 1.813.464zm-4.977 2.303H7.786v1h4.017v-1z" fill="#202D38"/></svg>
        </span>
        <div :class="$style.text">
          <span>{{ buttonText }}</span>
        </div>
      </span>
      <div :class="$style.loadingIconContainer">
        <LoadingIcon :class="$style.loader" />
        <span
          v-if="shouldShowLoadingText"
          :class="$style.loadingText"
        >
          Approve on Device
        </span>
      </div>
    </button>
  </div>
</template>

<script>
import { durtApiResponseType } from 'client/services/durtConstants';
import LoadingIcon from 'components/LoadingIcon.vue';

export default {
  name: 'JumpcloudGoButton',

  components: {
    LoadingIcon,
  },

  props: {
    buttonText: {
      type: String,
      default: 'Log in with JumpCloud Go',
    },
    durtInfo: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    email() {
      return this.durtInfo?.result === durtApiResponseType.success ? this.durtInfo.email : '';
    },

    photo() {
      return this.durtInfo?.result === durtApiResponseType.success ? this.durtInfo.photo : '';
    },

    shouldShowLoadingText() {
      // user has valid DURT, but UV is required
      return (
        this.durtInfo?.result === durtApiResponseType.success
        && this.durtInfo.is_locked
      );
    },
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped module>
@value (jcGrey800, jcGrey300, jcPrimary) from 'css/brand-colors.css';

.button {
  background-color: jcPrimary;
  border: 2px solid transparent;
  border-radius: 2px;
  color: jcGrey800;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  height: 42px;
  line-height: 1;
  margin: 1.5rem 0;
  opacity: 1;
  outline: none;
  overflow: hidden;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  width: 100%;
}

.buttonContent {
  align-items: center;
  display: inline-flex;
  position: relative;
  top: 0;
  transition: top 1s;
}

button.isLoading .buttonContent {
  top: -40px;
}

.iconWrapper {
  margin-right: 10px;
  position: relative;
}

.button:disabled {
  background-color: jcGrey300;
  pointer-events: none;
}

.button:hover {
  box-shadow: inset 0 0 30px 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.button:hover .iconWrapper,
.button.isLoading .iconWrapper {
  transform: rotateZ(45deg);
  transition: transform 1s;
}

.button:hover .iconWrapper .flame,
.button.isLoading .iconWrapper .flame {
  visibility: visible;
}

.text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.email {
  font-size: 0.9rem;
}

.imgUser {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(32, 45, 56, 0.5);
  height: 90px;
  margin-bottom: 0.6rem;
  width: 90px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.iconRocket {
  position: relative;
  z-index: 1;
}

.loadingIconContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: relative;
  top: 10px;
  transition: top 1s;
}

.loader {
  width: 1.5rem;
}

.loadingText {
  margin-left: 0.75rem;
}

.button.isLoading .loadingIconContainer {
  opacity: 1;
  top: -23px;
}
</style>
