// eslint-disable-next-line max-len
const getCurrentComponentNameFromList = (componentsUnderControl: {id: string, componentName: string }[]) => (mfaType: string): string => {
  const currentComponent = componentsUnderControl.find(
    ({ id }) => id === mfaType,
  );

  return currentComponent ? currentComponent.componentName : '';
};

export default getCurrentComponentNameFromList;
