<template>
  <div :class="$style.container">
    <LoginAlert
      :message="message"
    />
    <button
      :class="$style.button"
      @click="handleClick"
    >
      <span :class="$style.content">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" :class="$style.icon" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 12.281h11.328c.109.609.187 1.203.187 2C23.515 21.125 18.921 26 11.999 26c-6.641 0-12-5.359-12-12s5.359-12 12-12c3.234 0 5.953 1.188 8.047 3.141L16.78 8.282c-.891-.859-2.453-1.859-4.781-1.859-4.094 0-7.438 3.391-7.438 7.578s3.344 7.578 7.438 7.578c4.75 0 6.531-3.406 6.813-5.172h-6.813v-4.125z"/></svg>
        Sign in with Google
      </span>
    </button>
  </div>
</template>

<script>
import { LocalStorageConsts } from 'client/util/Constants';
import LoginAlert from 'client/components/LoginAlert.vue';
import LoginConstants from 'client/util/Constants/LoginConstants';
import RouteGenerator from 'client/routes/RouteGenerator';

const { googleOrigin } = LocalStorageConsts;
const { googleLogin } = RouteGenerator;

export default {
  name: 'GoogleSignIn',

  components: {
    LoginAlert,
  },

  data() {
    const message = this.error === LoginConstants.errorParamTypes.googleLoginError
      ? this.$options.loginError
      : undefined;
    return { message };
  },

  props: {
    error: {
      type: String,
      default: undefined,
    },
  },

  loginError: 'Your organization does not allow "Sign in with Google". Please sign in with email and password.',

  methods: {
    handleClick() {
      localStorage.setItem(googleOrigin, 'true');
      // route user to google login page
      window.location = googleLogin;
    },
  },
};
</script>

<style module>
@value (jcPrimary, jcGrey900) from 'css/brand-colors.css';

.content {
  align-items: center;
  display: inline-flex;
}

.button {
  background: none;
  border: 1px solid currentColor;
  border-radius: 4px;
  color: jcGrey900;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  padding: 0.4375rem 1rem;
  text-align: center;
  width: 100%;
}

.button:hover {
  color: jcGrey900;
}

.icon {
  fill: currentColor;
  height: 1.5rem;
  margin-right: 0.5em;
  width: 1.5rem;
}

.button:focus,
.icon:focus {
  outline: none;
}
</style>
