
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import Alert from 'client/components/LoginAlert.vue';
import EmailBadge from 'client/components/EmailBadge.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginCard from 'client/components/LoginCard.vue';

import UserLoginStore from 'client/stores/UserLoginStore';

export default defineComponent({
  name: 'UserResetPasswordRequest',

  components: {
    Alert,
    EmailBadge,
    LoginCard,
    LoginActionButton,
  },

  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapStores(UserLoginStore),

    email() {
      return this.userLoginStore.email;
    },
  },

  methods: {
    submit() {
      this.$emit('submit', this.email);
    },

    goToLogin() {
      this.$emit('goToLogin');
    },
  },
});

