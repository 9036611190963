<template>
  <div>
    <form>
      <fieldset :class="$style.loginFieldset">
        <label :class="$style.loginTextFieldLabel">
          {{ label }}
        </label>
        <div :class="$style.totpInputContainer">
          <input
            ref="input1"
            v-model="tokenNumbers.char1"
            v-bind="inputProps"
            v-on="inputHandlers"
            @keydown.arrow-left="handleArrowLeft"
            @keydown.arrow-right="handleArrowRight"
            @keydown.backspace="handleBackspace"
          >
          <input
            ref="input2"
            v-model="tokenNumbers.char2"
            v-bind="inputProps"
            v-on="inputHandlers"
            @keydown.arrow-left="handleArrowLeft"
            @keydown.arrow-right="handleArrowRight"
            @keydown.backspace="handleBackspace"
          >
          <input
            ref="input3"
            v-model="tokenNumbers.char3"
            v-bind="inputProps"
            v-on="inputHandlers"
            @keydown.arrow-left="handleArrowLeft"
            @keydown.arrow-right="handleArrowRight"
            @keydown.backspace="handleBackspace"
          >
          <input
            ref="input4"
            v-model="tokenNumbers.char4"
            v-bind="inputProps"
            v-on="inputHandlers"
            @keydown.arrow-left="handleArrowLeft"
            @keydown.arrow-right="handleArrowRight"
            @keydown.backspace="handleBackspace"
          >
          <input
            ref="input5"
            v-model="tokenNumbers.char5"
            v-bind="inputProps"
            v-on="inputHandlers"
            @keydown.arrow-left="handleArrowLeft"
            @keydown.arrow-right="handleArrowRight"
            @keydown.backspace="handleBackspace"
          >
          <input
            ref="input6"
            v-model="tokenNumbers.char6"
            v-bind="inputProps"
            v-on="inputHandlers"
            @keydown.arrow-left="handleArrowLeft"
            @keydown.arrow-right="handleArrowRight"
            @keydown.backspace="handleBackspace"
          >
        </div>
      </fieldset>
      <LoginActionButton
        v-if="hasAlreadySubmitted"
        :action="onManualSubmit"
        data-test-id="TotpInput__SubmitButton"
        :disabled="isInvalidInput"
        :isLoading="isLoading"
        type="submit"
      >
        {{ submitButtonText }}
      </LoginActionButton>
    </form>
    <input
      autocomplete="off"
      :class="$style.jcpmHiddenInput"
      :disabled="isLoading"
      maxlength="6"
      name="2fa"
      type="text"
      @input="handleJCPWMInput"
    >
    <div
      :class="$style.loadingContainer"
      v-if="shouldShowAutomaticLoadingIndicator"
    >
      <LoadingIcon />
      <p>Verifying Your Code...</p>
    </div>
  </div>
</template>
<script>
import LoadingIcon from 'components/LoadingIcon.vue';
import LoginActionButton from 'components/LoginActionButton.vue';

const tokenInputRefs = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
const tokenNumberKeys = ['char1', 'char2', 'char3', 'char4', 'char5', 'char6'];

export default {
  name: 'TotpInput',

  components: {
    LoadingIcon,
    LoginActionButton,
  },

  model: {
    prop: 'token',
    event: 'change',
  },

  props: {
    hasAlreadySubmitted: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    const tokenNumbers = {};
    tokenNumberKeys.forEach((key) => { tokenNumbers[key] = ''; });
    return {
      tokenNumbers,
    };
  },

  computed: {
    tokenValue() { return tokenNumberKeys.map((key) => this.tokenNumbers[key]).join(''); },

    inputHandlers() {
      return {
        input: this.handleInput,
        paste: this.handlePaste,
      };
    },

    inputProps() {
      return {
        autocomplete: 'off',
        class: `${this.$style.loginInput} keeper-ignore`,
        disabled: this.isLoading,
        inputmode: 'numeric',
        maxlength: '1',
        pattern: '[0-9]*',
        type: 'text',
      };
    },

    // automatic submission is disabled after error
    shouldShowAutomaticLoadingIndicator() {
      return !this.hasAlreadySubmitted && this.isLoading;
    },

    isInvalidInput() {
      return this.token.length !== 6;
    },

    submitButtonText() {
      return 'Submit';
    },
  },

  mounted() {
    // Focus on the first input so users can start typing immediately
    if (!this.token) this.$refs.input1.focus();
    this.emitInterface();
  },

  methods: {
    emitChange() {
      // Emit a model change to update the `token` prop
      this.$emit('change', this.tokenValue);
    },

    onAutomaticSubmittal() {
      if (!this.isLoading && !this.hasAlreadySubmitted) {
        this.$emit('submit');
      }
    },

    onManualSubmit() {
      this.$emit('submit');
    },

    emitInterface() {
      this.$emit('interface', {
        clearInput: () => this.clearInput(),
        focusInput: () => this.focusInput(),
      });
    },

    focusInput() {
      this.$nextTick(() => this.$refs.input1.focus());
    },

    findEventInputIndex(event) {
      return tokenInputRefs
        .findIndex((inputRef) => event.currentTarget === this.$refs[inputRef]);
    },

    focusNextInput(inputIndex) {
      const nextInputRef = tokenInputRefs[inputIndex + 1];
      if (nextInputRef) this.$refs[nextInputRef].focus();
    },

    focusPreviousInput(inputIndex) {
      const previousInputRef = tokenInputRefs[inputIndex - 1];
      if (previousInputRef) this.$refs[previousInputRef].focus();
    },

    handleArrowLeft(event) {
      // Focus previous input on left arrow
      const inputIndex = this.findEventInputIndex(event);
      this.focusPreviousInput(inputIndex);
      event.preventDefault();
    },

    handleArrowRight(event) {
      // Focus next input on right arrow
      const inputIndex = this.findEventInputIndex(event);
      this.focusNextInput(inputIndex);
    },

    handleBackspace(event) {
      // 1Password produces events with key set to '' when autofilling
      // so vue might trigger this incorrectly
      if (!event.key) {
        event.preventDefault();
        return;
      }
      const inputIndex = this.findEventInputIndex(event);
      const currentInputValue = this.tokenNumbers[tokenNumberKeys[inputIndex]];
      if (currentInputValue) {
        // If in an input that already has a character, erase value
        this.tokenNumbers[tokenNumberKeys[inputIndex]] = '';
        this.emitChange();
      }
      if (!currentInputValue && inputIndex > 0) {
        // If in an empty input, erase previous input value and focus on that input field
        this.tokenNumbers[tokenNumberKeys[inputIndex - 1]] = '';
        this.focusPreviousInput(inputIndex);
        this.emitChange();
      }
      event.preventDefault();
    },

    handleInput(event) {
      const { data } = event;
      const inputIndex = this.findEventInputIndex(event);

      // Submit data on an empty event (happens on autofill from a password manager)
      if (data === undefined || data === null) {
        if (this.tokenValue.length === 6) {
          this.emitChange();
          this.onAutomaticSubmittal();
        }
      // Update the input and focus next input on typing valid character or number
      } else if (data.length === 1 && /^[0-9]+$/i.test(data)) {
        this.tokenNumbers[tokenNumberKeys[inputIndex]] = data;
        this.emitChange();
        this.focusNextInput(inputIndex);
        // Submit token if the full 6-digit token has been entered
        if (this.tokenValue.length === 6) this.onAutomaticSubmittal();
      // Clear input when invalid data is entered
      } else {
        this.tokenNumbers[tokenNumberKeys[inputIndex]] = '';
      }
    },

    // Workaround until JCPM can handle inserting otp codes
    // into 6 separate input fields like 1Password does
    handleJCPWMInput(event) {
      const { target } = event;
      const code = target.value;

      if (code.length === 6 && !this.tokenValue) {
        this.populateInputs([...code]);
        this.emitChange();
        this.onAutomaticSubmittal();
      }
    },

    handlePaste(event) {
      event.preventDefault();
      this.clearInput();

      const code = event.clipboardData.getData('text/plain');
      const codeArray = [...code].filter((char) => /^[0-9]+$/i.test(char)).slice(0, 6);

      this.populateInputs(codeArray);
      this.emitChange();

      return this.tokenValue.length === 6
        ? this.onAutomaticSubmittal()
        : this.focusNextInput(codeArray.length - 1);
    },

    populateInputs(codeArray = []) {
      this.tokenNumbers = codeArray.reduce((acc, value, index) => {
        acc[tokenNumberKeys[index]] = value;
        return acc;
      }, {});
    },

    clearInput() {
      tokenNumberKeys.forEach((key) => { this.tokenNumbers[key] = ''; });
      this.emitChange();
      this.$refs.input1.focus();
    },
  },
};
</script>
<style module>
@import '../css/login-components.css';
@import '../css/inputs.css';

/* display: none prohibits otp insertion from working */
.jcpmHiddenInput {
  border: none;
  height: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.loginFieldset {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

.loginTextFieldLabel {
  font-weight: 600;
}

.totpInputContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.loginInput {
  padding: 0;
  text-align: center;
}

.loadingContainer {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.loadingContainer svg {
  max-width: 1.25rem;
}

.loadingContainer p {
  font-size: 0.75rem;
  padding-top: 0;
}
</style>
