
import { AdminRoutes } from 'client/routes/RouteGenerator';
import { defineComponent } from 'vue';
import Alert from 'client/components/LoginAlert.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginCard from 'client/components/LoginCard.vue';
import LoginTextField from 'client/components/LoginTextField.vue';

export default defineComponent({
  name: 'AdminResetPasswordRequest',

  components: {
    Alert,
    LoginActionButton,
    LoginCard,
    LoginTextField,
  },

  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: '',
    };
  },

  computed: {
    resetEmail() {
      return AdminRoutes.resetPassword;
    },
  },

  methods: {
    goToLogin() {
      this.$emit('goToLogin');
    },

    submit() {
      this.$emit('submit', this.email);
    },
  },
});
