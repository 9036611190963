
import { defineComponent } from 'vue';

// Components
import LoginActionButton from 'client/components/LoginActionButton.vue';

// Constants
import { useMfaLabels } from 'client/util/Constants/MfaLabels';

// Utils
import { webAuthnSupported } from 'client/util/FeatureSupport';

export default defineComponent({
  name: 'WebAuthnView',

  components: {
    LoginActionButton,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
  },

  setup() {
    const uiLabel = useMfaLabels();
    return { uiLabel };
  },

  computed: {
    isDisabled() {
      return !this.isWebAuthnSupported || this.isLoading;
    },

    isWebAuthnSupported() {
      return webAuthnSupported();
    },
  },

  mounted() {
    if (this.isWebAuthnSupported) {
      this.submit();
    }
  },
});
