
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'OidcConfirmations',

  props: {
    confirmations: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    },
  },

  methods: {
    parseConfirmation(confirmation: string) {
      const parsedLink = confirmation.replace(
        /\[([^\]]+)\]\(([^)]+)\)/g,
        '<a href="$2" target="_blank">$1</a>',
      );
      return `<p>${parsedLink}</p>`;
    },
  },
});
