<template>
  <div :class="$style.formContainer">
    <LoginCard
      header="Administrator MFA Reset"
      :showCustomLogo="false"
    >
      <Alert
        v-if="errorMessage"
        :message="errorMessage"
      />
      <Alert
        centered
        v-if="isExpired"
      >
        <p :class="$style.alertHeader">
          This MFA reset link has expired.
        </p>
        <p :class="$style.alertText">
          Check your inbox for the most recent link or reset your TOTP
          again. For security purposes, this link expires after 24 hours.
        </p>
      </Alert>

      <form :class="$style.form">
        <LoginTextField
          :autoFocus="autofocus === 'email'"
          @focus="updateAutofocus('email')"
          label="Email"
          name="email"
          placeholder="Administrator Email Address"
          required
          :submitOnEnter="submit"
          :isEditable="false"
          type="email"
          vvName="email"
          v-model="form.email"
        />
        <LoginTextField
          label="Password"
          name="password"
          placeholder="Password"
          :rules="{ max: 64, required: true }"
          type="password"
          v-model="form.password"
          vvName="password"
          :submitOnEnter="submit"
        />
        <LoginActionButton
          :action="request"
          :isLoading="isLoading"
          :disabled="isDisabled"
        >
          Set Up MFA
        </LoginActionButton>
      </form>
    </LoginCard>
    <LogoIcon />
  </div>
</template>

<script>
import { LoginConsts } from 'client/util/Constants';
import { get } from 'lodash';
import ConfirmValidation from 'client/components/mixins/ConfirmValidation';
import LoginCard from 'client/components/LoginCard.vue';
import ResetPasswordStore from 'client/stores/ResetPasswordStore';
import RouteGenerator, { AdminRoutes } from 'client/routes/RouteGenerator';
import api from 'client/api';
import loginUtil from 'client/components/mixins/loginUtil';

const { passwordResetError, totpResetErrorLimit } = LoginConsts.responseMessages;
const { formRef } = LoginConsts.formElements;
const { admin: adminApi } = api;
const { adminTotpSetup } = RouteGenerator;

export default {
  name: 'AdminTotpReset',

  mixins: [loginUtil, ConfirmValidation],

  components: {
    LoginCard,
  },

  computed: {
    isDisabled() {
      return !this.form.password;
    },
  },

  props: {
    email: {
      default: null,
      type: String,
    },
    requestKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: {
        password: '',
        email: this.email,
        confirmationCode: this.requestKey,
      },
      isExpired: false,
      isLoading: false,
    };
  },

  created() {
    this.validateRequestKey();
    this.formRef = formRef;
  },

  setup() {
    const resetPasswordStore = ResetPasswordStore();
    return { resetPasswordStore };
  },

  methods: {
    request() {
      return this.verifyPasswordRequest();
    },
    validateRequestKey() {
      if (!this.requestKey) {
        this.isExpired = true;
        return;
      }

      adminApi.validateTotpKey(this.requestKey).catch(() => {
        this.isExpired = true;
      });
    },

    async verifyPasswordRequest() {
      const resetPassword = {
        password: this.form.password,
        email: this.email,
        confirmationCode: this.form.confirmationCode,
      };
      await this.resetPasswordStore.commitEntireStateChange(resetPassword);

      return this.resetPasswordStore.verifyResetTOTP()
        .then(() => this.redirect(AdminRoutes.logout))
        .catch((error) => {
          const { status } = error.response;
          const totpKey = get(error, 'response.data.totpKey', null);
          // 403 for too many failed attempts
          if (status === 403) {
            this.vueRouteChange('Admin Login', { error: totpResetErrorLimit });
          } else if (status === 428 && totpKey) {
            this.vueRouteChange(adminTotpSetup, { totp: totpKey, isTotpResetFlow: true });
          } else {
            this.errorMessage = get(error, 'response.data.message', passwordResetError);
          }
        });
    },
  },
};
</script>

<style module>
@import "../css/login-components.css";

.alertHeader {
  font-size: 1.25em;
  margin-top: 0;
}

.alertText {
  margin-bottom: 0;
}

.header {
  font-size: 1.5rem;
}

.subheader {
  font-size: 0.75rem;
  font-weight: 600;
}
</style>
