export default class RequestError extends Error {
  displayDuration?: number;

  status: number;

  statusCode?: string;

  constructor(
    status: number,
    message: string,
    statusCode?: string,
    displayDurationString?: string | number,
  ) {
    super(message);
    // default to undefined for potential of falsy string
    this.displayDuration = displayDurationString ? Number(displayDurationString) : undefined;
    this.status = status;
    this.statusCode = statusCode;
  }
}
