<template>
  <Card :class="$style.errorCard">
    <slot name="body" />
    <p>
      <strong>
        An unexpected error has occurred. Please close this window and try again.
      </strong>
    </p>
    <slot />
  </Card>
</template>

<script>
import Card from 'client/components/Card.vue';

export default {
  name: 'OidcError',

  components: {
    Card,
  },
};
</script>

<style module>
@value (jcError100, jcWhite50 ) from 'css/brand-colors.css';

.errorCard {
  background: jcError100;
  border: 35px solid jcWhite50;
  margin: 5rem auto 0;
  max-width: 570px;
  text-align: left;
  width: 100%;
}

</style>
