<template>
  <div :class="[this.shouldShowCustomLogo ? '' : $style.short, $style.formContainer]">
    <LoginCard
      :header="headerTitle"
      :showCustomLogo="shouldShowCustomLogo"
    >
      <p :class="$style.subheader">
        {{ mfaDisplayText.subheaderText }}
      </p>

      <slot />

      <Alert :message="errorMessage" />

      <TotpInput
        :hasAlreadySubmitted="!!errorMessage"
        label="Enter code from your mobile authenticator app:"
        :isLoading="isLoading"
        @interface="getChildInterface"
        @submit="submit"
        v-model="token"
      />

      <TotpResetLink
        v-if="shouldShowTOTPResetLink"
        @onRequestReset="requestResetTOTP"
      />
    </LoginCard>
    <LogoIcon />
  </div>
</template>

<script>
// Frameworks
import { get } from 'lodash';
import { mapGetters } from 'vuex';

// Constants and Types
import { LoginConsts, XhrConsts } from 'client/util/Constants';
import MfaStrings from 'client/containers/Login/MfaStrings';

// Router
import { AdminRoutes } from 'client/routes/RouteGenerator';

// API
import AdminLoginApi from 'client/api/AdminLoginApi';

// Utils
import Util from 'client/util/Util';
import loginUtil from 'client/components/mixins/loginUtil';

// Components
import LocalStorageConstants from 'client/util/Constants/LocalStorageConstants';
import LocalStorageService from 'client/util/LocalStorageService';
import LoginCard from 'client/components/LoginCard.vue';
import ResetPasswordStore from 'client/stores/ResetPasswordStore';
import TotpInput from 'client/components/TotpInput.vue';
import TotpResetLink from 'client/components/TotpResetLink.vue';

const { admin } = LoginConsts.clientType;
const { localStorageOrgKey } = LocalStorageConstants;

const { oidc, responseMessages } = LoginConsts;
const {
  invalidOtpError,
  passwordResetSuccess,
  totpResetError,
  totpServerError,
} = responseMessages;
const { resetTOTP } = AdminRoutes;
const { xOrgIdHeader } = XhrConsts;

// Note: This is a legacy component that now is only for ADMIN TOTP verification
// User TOTP is handled by TotpUserLoginHandler.vue

export default {
  name: 'VerifyTOTP',

  LocalStorageService,

  components: {
    LoginCard,
    TotpInput,
    TotpResetLink,
  },

  childInterface: {
    clearInput: () => {},
  },

  props: {
    // Only necessary b/c of loginUtil mixin - clientType is always "admin"
    clientType: {
      type: String,
      default: admin,
    },
    mfaDisplayText: {
      type: Object,
      default: () => MfaStrings.contexts.totp.login,
    },
    passwordResetInfo: {
      type: Object,
      default: null,
    },
    shouldShowCustomLogo: {
      type: Boolean,
      default: true,
    },
    headerTitle: {
      type: String,
      default: 'Verify Your Identity',
    },
  },

  mixins: [loginUtil],

  data: () => ({
    oidc,
    token: '',
  }),

  computed: {
    shouldShowTOTPResetLink() {
      return this.passwordResetInfo === null;
    },
    ...mapGetters('LoginOauthAdminFlowModel', [
      'loginChallenge',
    ]),
  },

  setup() {
    const resetPasswordStore = ResetPasswordStore();
    return { resetPasswordStore };
  },

  methods: {
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },

    clearInput() {
      this.$options.childInterface.clearInput();
    },

    async completeAdminLoginFlow(resp) {
      if (this.loginChallenge) {
        try {
          const { data: { redirect_to: oidcRedirect } } = await AdminLoginApi
            .completeLoginChallengeOIDC(this.loginChallenge);
          Util.redirect(oidcRedirect);
        } catch (_) {
          this.$router.push(this.oidc.error);
        }
      } else {
        const lastUrl = get(resp, 'data.lastUrl', AdminRoutes.dashboard);

        this.redirect(lastUrl || AdminRoutes.dashboard);
      }
    },

    handleSuccess(resp) {
      const organizationId = resp?.headers[xOrgIdHeader.toLowerCase()];
      if (organizationId) {
        this.$options.LocalStorageService.setItem(localStorageOrgKey, organizationId);
      }
      return this.completeAdminLoginFlow(resp);
    },

    handleError(message = invalidOtpError) {
      this.setErrorMessage(message);
      this.token = '';
      this.clearInput();
    },

    handleAdminPasswordResetError(error) {
      this.errorMessage = get(error, 'response.data.message', 'Verification code was invalid or already used.');
      const status = get(error, 'response.status');
      this.token = '';
      this.clearInput();
      // 403 indicates invalid TOTP code exceeds max attempts
      if (status === 403) {
        this.vueRouteChange('Admin Login', { error: this.errorMessage });
      }
    },

    resetPasswordRequest() {
      this.resetPasswordStore.otp = this.token;

      return this.resetPasswordStore.verifyResetPassword()
        .then(() => {
          this.$options.LocalStorageService.setItem('resetSuccessMessage', passwordResetSuccess);
          this.redirect(AdminRoutes.logout);
        })
        .catch(this.handleAdminPasswordResetError);
    },

    requestResetTOTP() {
      AdminLoginApi.requestTOTPReset()
        .then(() => this.redirect(resetTOTP))
        .catch(() => this.handleError(totpResetError));
    },

    verifyTotp() {
      return AdminLoginApi
        .verifyTotp({ otp: this.token })
        .then(this.handleSuccess)
        .catch(this.handleTOTPError);
    },

    handleTOTPError(error) {
      const status = get(error, 'response.status');
      const message = get(error, 'response.data.message', '');

      // 403 for too many failed attempts
      if (status === 403) {
        this.vueRouteChange('Admin Login', { error: message });
      } else if (status >= 500) {
        this.handleError(totpServerError);
      } else {
        this.handleError(invalidOtpError);
      }
    },
    /**
     * Submits the MFA token for verification. It is called by the submit function
     * from the loginUtil mixin.
     */
    request() {
      let apiRequest = this.verifyTotp;
      if (this.passwordResetInfo && this.passwordResetInfo.confirmationCode) {
        apiRequest = this.resetPasswordRequest;
      }

      return apiRequest().finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

@value (jcGrey800) from 'css/brand-colors.css';

.short {
  padding-top: 0;
}

.formContainer {
  padding-top: 3rem;
}

.formContainer fieldset {
  margin-top: 1.5rem;
}

.formContainer button {
  margin-top: 0.5rem;
}

.subheader {
  font-size: 0.75rem;
}
</style>
