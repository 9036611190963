
import { defineComponent } from 'vue';

// Components
import PushView from 'client/containers/user/Views/PushView.vue';

// Services
import userResetPasswordService from 'client/services/userResetPasswordService';

export default defineComponent({
  name: 'PushUserResetPasswordHandler',

  components: {
    PushView,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    hasPendingErrorMessage: Boolean,
    requestKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isDisabled: true,
    };
  },

  mounted() {
    this.submit();
  },

  methods: {
    async submit(): Promise<void> {
      this.isDisabled = true;
      this.$emit('resetErrorMessage');
      const params = {
        ...this.form,
        push: true,
      };

      try {
        await userResetPasswordService.verifyPushResetPassword(
          this.requestKey,
          params,
        );

        this.$emit('success');
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.isDisabled = false;
      }
    },
  },
});
