<template>
  <UserLoginController
    :mfaType="mfaType"
    :paramError="error"
    :step="step"
    v-bind="$props"
  />
</template>

<script>
import UserLoginController from 'client/containers/user/login/UserLoginController.vue';

export default {
  name: 'UserLoginWrapper',

  components: {
    UserLoginController,
  },

  props: {
    error: {
      type: String,
      default: '',
    },
    // from params when coming from AdminLoginWrapper
    form: {
      type: Object,
      default: () => ({
        email: '',
        password: '',
      }),
    },
    mfaType: {
      type: String,
      default: '',
    },
    step: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: undefined,
    },
    redirectTo: {
      type: String,
      default: '',
    },
  },
};
</script>
