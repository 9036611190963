import { PushAuthenticationStatus } from 'client/api/PushMfaAuthApi';

export const pollSuccessCondition = ({ status }: {status?: string} = {}): boolean => (
  status === PushAuthenticationStatus.ACCEPTED
);

export const pollFailureCondition = ({ status }: {status?: string} = {}): boolean => {
  const isAwaitingResponse = status === PushAuthenticationStatus.PENDING;
  const isApproved = status === PushAuthenticationStatus.ACCEPTED;

  return !status || (!isAwaitingResponse && !isApproved);
};
