
import { defineComponent } from 'vue';
// Components
import AuthView from 'client/containers/user/Views/AuthView.vue';
import DuoUserResetPasswordRedirectHandler from 'client/containers/user/UserResetPassword/UserResetPasswordMfaHandlers/DuoUserResetPasswordRedirectHandler.vue';
import LogoIcon from 'client/components/LogoIcon.vue';
import MfaView from 'client/containers/user/Views/MfaView.vue';
import PushUserResetPasswordHandler from 'client/containers/user/UserResetPassword/UserResetPasswordMfaHandlers/PushUserResetPasswordHandler.vue';
import TotpUserResetPasswordHandler from 'client/containers/user/UserResetPassword/UserResetPasswordMfaHandlers/TotpUserResetPasswordHandler.vue';
import UserResetPasswordInputHandler from 'client/containers/user/UserResetPassword/UserResetPasswordInputHandler.vue';
import WebAuthnUserResetPasswordHandler from 'client/containers/user/UserResetPassword/UserResetPasswordMfaHandlers/WebAuthnUserResetPasswordHandler.vue';

// Constants
import { LoginConsts } from 'client/util/Constants';
import type { MfaFactor } from 'client/types/Auth';

// Service Errors
import MfaRequiredError from 'client/services/response/MfaRequiredError';
import RequestError from 'client/services/response/RequestError';

// Utils
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import ProcessPasswordResetMfaError from 'client/containers/user/MfaComponents/mfaUtils/processPasswordResetMfaError';
import getCurrentComponentNameFromList from 'client/containers/user/MfaComponents/mfaUtils/getCurrentComponentNameFromList';
import getLastUsedFactor from 'client/containers/user/MfaComponents/mfaUtils/getLastUsedFactor';
import mfaFactorBuilder from 'client/containers/user/MfaComponents/mfaUtils/mfaFactorBuilder';
import routerService from 'client/services/routerService';

interface pwdResetForm {
  password: string;
  confirm: string;
  challenge?: any;
  pwdResetToken?: any;
}

const {
  responseMessages: {
    mfaUnavailableForPwdReset,
    passwordResetError,
    passwordResetSuccess,
  },
} = LoginConsts;

const componentsUnderControl = [
  {
    id: '',
    componentName: UserResetPasswordInputHandler.name,
  },
  {
    id: 'duo',
    componentName: DuoUserResetPasswordRedirectHandler.name,
  },
  {
    id: 'push',
    componentName: PushUserResetPasswordHandler.name,
  },
  {
    id: 'totp',
    componentName: TotpUserResetPasswordHandler.name,
  },
  {
    id: 'webauthn',
    componentName: WebAuthnUserResetPasswordHandler.name,
  },
];

const getCurrentComponentName = getCurrentComponentNameFromList(componentsUnderControl);

const formInitialState: pwdResetForm = {
  password: '',
  confirm: '',
};

export default defineComponent({
  name: 'UserResetPasswordController',

  components: {
    AuthView,
    DuoUserResetPasswordRedirectHandler,
    LogoIcon,
    MfaView,
    PushUserResetPasswordHandler,
    TotpUserResetPasswordHandler,
    UserResetPasswordInputHandler,
    WebAuthnUserResetPasswordHandler,
  },

  props: {
    mfaType: {
      type: String,
      default: '',
    },
    requestKey: {
      type: String,
      default: '',
    },
    step: {
      type: String,
      default: '',
    },
  },

  setup() {
    const mfaLabels = useMfaLabels();

    return {
      mfaLabels,
    };
  },

  data() {
    return {
      errorMessage: '',
      // using undefined so we display 0
      errorMessageDisplayDuration: undefined as number | undefined,
      factors: [] as MfaFactor[],
      form: {
        ...formInitialState,
      },
    };
  },

  computed: {
    componentHandlers() {
      return {
        // InputHandler, Duo, Push, Totp, WebAuthn
        resetErrorMessage: this.onResetErrorMessage,
        // InputHandler, Duo, Push, Totp, WebAuthn
        success: this.onSuccess,
      };
    },

    componentName() {
      const id = this.isMfa ? this.mfaType : this.step;

      return getCurrentComponentName(id);
    },

    componentProps() {
      return {
        // InputHandler, Duo, Push, Totp, WebAuthn
        form: this.form,
        // Push
        hasPendingErrorMessage: this.hasPendingErrorMessage,
        // InputHandler, Duo, Push, Totp, WebAuthn
        requestKey: this.requestKey,
      };
    },

    controllingQueryParams() {
      return {
        template: 'resetPasswordUserForm',
        key: this.requestKey,
      };
    },

    hasPendingErrorMessage() {
      const remainingDuration = this.errorMessageDisplayDuration || -1;
      return remainingDuration >= 0;
    },

    headerText() {
      return this.isMfa ? this.mfaLabels.verifyIdentity : this.mfaLabels.passwordResetHeader;
    },

    isMfa() {
      return this.step === 'mfa';
    },
  },

  mounted() {
    this.validateInitialStep();
  },

  methods: {
    handleErrorCountdown() {
      const errorCountdown = setInterval(() => {
        if ((this.errorMessageDisplayDuration as number) < 1) {
          this.onResetErrorMessage();
          clearInterval(errorCountdown);
        }

        (this.errorMessageDisplayDuration as number) -= 1;
      }, 1000);
    },

    handleResetForm() {
      this.form = {
        ...formInitialState,
      };
    },

    onCredentialError(error: unknown) {
      // todo: update error message to respond to action
      if (error instanceof RequestError) {
        return this.setErrorMessage(error.message);
      }
      if (!(error instanceof MfaRequiredError)) {
        return this.setErrorMessage(passwordResetError);
      }

      this.factors = mfaFactorBuilder(
        {
          factors: error.factors,
        },
        {
          controllingQueryParams: this.controllingQueryParams,
        },
      );

      if (this.factors.length === 0) {
        return this.setErrorMessage(mfaUnavailableForPwdReset);
      }

      this.form.challenge = error.challenge;
      this.form.pwdResetToken = error.pwdResetToken;

      const mfaParam = getLastUsedFactor(this.factors);

      return routerService.goToUserLogin({
        query: {
          ...this.controllingQueryParams,
          step: 'mfa',
          ...mfaParam,
        },
      });
    },

    onMfaError(error: unknown) {
      const {
        message,
        action,
        displayDuration,
      } = ProcessPasswordResetMfaError(this.mfaType, error);
      this.errorMessageDisplayDuration = displayDuration;

      if (this.errorMessageDisplayDuration && this.errorMessageDisplayDuration > 0) {
        this.handleErrorCountdown();
      }

      if (action === 'exitToLogin') {
        this.handleResetForm();
        this.redirectToLogin(message);
      } else if (action === 'restart') {
        this.handleResetForm();
        this.redirectBackToResetStart(message);
      } else {
        this.setErrorMessage(message);
      }
    },

    onResetErrorMessage() {
      this.errorMessageDisplayDuration = undefined;
      this.setErrorMessage();
    },

    onSuccess() {
      routerService.goToUserLogin({ params: { success: passwordResetSuccess } });
    },

    redirectBackToResetStart(message?: string) {
      if (message) {
        this.setErrorMessage(message);
      }
      routerService.goToUserPasswordResetForm({
        query: {
          key: this.requestKey,
        },
      });
    },

    redirectToLogin(errorMessage = '') {
      routerService.goToUserLogin({
        params: { error: errorMessage },
      });
    },

    setErrorMessage(message = '') {
      this.errorMessage = message;
    },

    validateInitialStep() {
      const isInitialStepInvalid = this.step && !this.factors.length;

      if (isInitialStepInvalid) {
        routerService.replaceToUserLogin({ query: { ...this.controllingQueryParams } });
      }
    },
  },
});
