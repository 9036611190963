
import { PropType, defineComponent } from 'vue';

// Constants
import MfaConstants from 'client/containers/Login/MfaConstants';

// Utils
import { webAuthnSupported } from 'client/util/FeatureSupport';

import { cancelPoll } from 'client/containers/Login/poll';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import type { MfaFactor } from 'client/types/Auth';

export default defineComponent({
  name: 'MfaChooser',

  props: {
    availableFactors: {
      type: Array as PropType<MfaFactor[]>,
      required: true,
    },
    hasSelectedFactor: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const uiLabel = useMfaLabels();
    return { uiLabel };
  },

  data() {
    return {
      isUserRequestingAlternateMfaMethod: false,
    };
  },

  computed: {
    isToggleAllowed() {
      return this.hasSelectedFactor && !this.isUserRequestingAlternateMfaMethod;
    },

    isWebAuthnSupported() {
      return webAuthnSupported();
    },

    webauthn() {
      return MfaConstants.mfaNames.webauthn;
    },
  },

  methods: {
    request(factorName: string) {
      // cancels any running polls
      cancelPoll();

      this.$emit('changeFactor', factorName);
    },

    handleDisplayOptions() {
      this.isUserRequestingAlternateMfaMethod = true;
    },
  },
});
