<template>
  <UserLoginController
    :clientType="clientType"
    :context="context"
    :mfaType="mfaType"
    :paramError="error"
    :redirectTo="completeRedirectUrl"
    :step="step"
  />
</template>

<script>
import { UserRoutes } from 'client/routes/RouteGenerator';
import UserLoginController from 'client/containers/user/login/UserLoginController.vue';

export default {
  name: 'OauthLoginWrapper',

  props: {
    clientType: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    mfaType: {
      type: String,
      default: '',
    },
    redirectTo: {
      type: String,
      default: '',
    },
    step: {
      type: String,
      default: '',
    },
  },
  components: {
    UserLoginController,
  },

  data() {
    return {
      completeRedirectUrl: UserRoutes.oauthRedirectUrl(this.redirectTo),
    };
  },
};
</script>
