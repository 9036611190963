<template>
  <div
    :class="$style.card"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Card',
};
</script>

<style module>
@value (jcGrey200) from 'css/brand-colors.css';

.card {
  background-color: white;
  border: 1px solid jcGrey200;
  border-radius: 5px;
  overflow: hidden;
  padding: 24px;
  position: relative;
}
</style>
