/* eslint-disable class-methods-use-this */
import { OidcClient, SigninResponse } from 'oidc-client-ts';
import { durtOidcScopes } from 'client/services/durtConstants';
import ConfigService from 'client/util/initializationHandler/ConfigService';

export class OidcClientService {
  private initialize() {
    return new OidcClient({
      authority: ConfigService.jcGoAuthority,
      client_id: ConfigService.jcGoClient,
      redirect_uri: `${window.location.origin}/login/oidc_callback`,
      response_type: 'code',
      scope: durtOidcScopes.join(' '),
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  signin(userState?: any): Promise<void> {
    const client = this.initialize();

    return client.createSigninRequest({ state: userState }).then((req) => {
      window.location.replace(req.url);
    });
  }

  // eslint-disable-next-line arrow-body-style
  processSigninResponse(): Promise<SigninResponse> {
    const client = this.initialize();

    return client.processSigninResponse(window.location.href);
  }
}

export default new OidcClientService();
