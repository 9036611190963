
/* eslint-disable camelcase */
import { defineComponent } from 'vue';
import AdminLoginApi from 'client/api/AdminLoginApi';
import LoginConstants from 'client/util/Constants/LoginConstants';
import OidcConsentView from 'client/containers/Oidc/OidcConsentView.vue';

import type {
  OidcClaim,
  OidcConsentPostPayload,
} from 'client/types/OidcConsent';

const { oidc } = LoginConstants;

export default defineComponent({
  name: 'AdminOidcConsent',

  components: {
    OidcConsentView,
  },

  data() {
    const model: OidcConsentPostPayload = {
      challenge: '',
      remember_me: true,
      scopes: {},
    };

    return {
      displayName: '',
      logoUrl: '',
      model,
      claims: [] as OidcClaim[],
      confirmations: [] as string[],
    };
  },

  async created() {
    const { query } = this.$route;

    const challenge = query.consent_challenge as string;

    try {
      const response = await AdminLoginApi.fetchOidcConsentData(challenge);

      const {
        data: {
          confirmations,
          display_name,
          logo_url,
          scopes,
        },
      } = response;

      this.confirmations = confirmations;
      this.displayName = display_name;
      this.logoUrl = logo_url;

      this.model.challenge = challenge;
      this.model.scopes = scopes.reduce((acc, scope) => {
        if (acc) {
          scope.claims.forEach((claim) => {
            this.claims.push(claim);

            acc[scope.name] = (acc[scope.name] || []).concat(claim.name);
          });
        }

        return acc;
      }, {} as OidcConsentPostPayload['scopes']);
    } catch (e) {
      this.$router.replace(oidc.error);
    }
  },

  methods: {
    async onConsent() {
      try {
        const {
          data: {
            redirect_to: redirectTo,
          },
        } = await AdminLoginApi.completeConsentChallengeOIDC(this.model);

        window.location.replace(redirectTo);
      } catch {
        this.$router.replace(oidc.error);
      }
    },

    onCancel() {
      this.$router.back();
    },
  },
});
