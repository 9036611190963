<template>
  <header :class="$style.header">
    <a
      :class="$style.headerLink"
      href="https://jumpcloud.com"
    >
      <LogoIcon
        :class="$style.logoIcon"
        :fixed="false"
      />
    </a>
  </header>
</template>

<script>
import LogoIcon from 'client/components/LogoIcon.vue';

export default {
  name: 'LoginHeader',

  components: {
    LogoIcon,
  },
};
</script>

<style module>
@value (jcGrey800) from 'css/brand-colors.css';

.header {
  padding: 1.5rem 1rem;
  width: 100%;
}

.logoIcon {
  color: jcGrey800;
  fill: currentColor;
  font-size: 1.5rem;
  height: 1em;
  width: auto;
}
</style>
