// eslint-disable-next-line import/prefer-default-export
export function useLoginLabels() {
  return {
    adminLogin: 'Admin Login',
    allow: 'Allow',
    changeEmail: 'Change',
    continue: 'Continue',
    email: 'Email',
    emailPlaceholder: 'User Email Address',
    login: 'Login',
    loginAsAdmin: {
      trying: 'Are you trying to ',
      login: 'log in as an Administrator?',
    },
    loginToApp: 'Log in to your application using JumpCloud',
    rememberMe: 'Remember me',
    ssoLogin: 'SSO Login',
    userLogin: 'User Login',
    password: 'Password',
    userPasswordPlaceholder: 'User Password',
    resetUserPassword: 'Reset User Password',
    welcomeBack: 'Welcome Back!',
  };
}
