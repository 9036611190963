/* eslint class-methods-use-this: 0 */
import { XhrConsts } from 'client/util/Constants';
import axios, { Axios } from 'axios';

const { xCSRFToken, xOrgIdHeader } = XhrConsts;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * The base Api class provides common methods that are used by all Api
 * subclasses for both RESTful and non-RESTful endpoints. It provides an
 * initialize method that is used to ensure that the CSRF token and OrgId are
 * present on all requests as well methods to handle errors and stringify an
 * object of query string parameters for use in a GET request.
 */
export default class Api {
  readonly axios: Axios;

  readonly csrfUrl: string;

  readonly csrfHeader: string;

  readonly orgIdHeader: string;

  constructor(csrfUrl = '/csrf', csrfHeader = xCSRFToken, orgIdHeader = xOrgIdHeader) {
    // TODO: We will eventually want to have all Api's using `this.axios`
    // rather than the global `axios` to avoid sharing headers.
    // For now, this local axios instance is only being used in LoginApi.
    this.axios = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.csrfUrl = csrfUrl;
    this.csrfHeader = csrfHeader;
    this.orgIdHeader = orgIdHeader;
  }

  initialize(): Promise<void> {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      // eslint-disable-next-line no-console
      console.warn('deprecated API.initialize()');
    }
    return Promise.resolve();
  }
}
