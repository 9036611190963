
import { defineComponent } from 'vue';

import JumpcloudWordmark from 'client/components/JumpcloudWordmark.vue';
import LoadingIcon from 'client/components/LoadingIcon.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginCard from 'client/components/LoginCard.vue';

import LocalStorageService from 'client/util/LocalStorageService';
import routerService from 'client/services/routerService';
import userResetPasswordService from 'client/services/userResetPasswordService';

import ProcessPasswordResetMfaError from 'client/containers/user/MfaComponents/mfaUtils/processPasswordResetMfaError';

import { LocalStorageConsts, LoginConsts } from 'client/util/Constants';

const { userPwdResetInfo } = LocalStorageConsts;
const {
  responseMessages: {
    passwordResetError,
    passwordResetSuccess,
  },
} = LoginConsts;

export default defineComponent({
  name: 'DuoCallbackHandler',

  components: {
    JumpcloudWordmark,
    LoadingIcon,
    LoginActionButton,
    LoginCard,
  },

  props: {
    // props from params
    // eslint-disable-next-line vue/prop-name-casing
    duo_code: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
  },

  computed: {
    buttonText() {
      return 'Redirecting to Login';
    },

    helpText() {
      return 'We are completing your password reset request.';
    },
  },

  mounted() {
    this.submit();
  },

  methods: {
    async submit(): Promise<void> {
      try {
        const pwdResetInfo = LocalStorageService.getItem(userPwdResetInfo) || '{}';
        const {
          confirm,
          password,
          pwdResetToken,
          requestKey,
        } = JSON.parse(pwdResetInfo);

        await userResetPasswordService.resetPassword(requestKey, {
          duo_code: this.duo_code,
          confirm,
          password,
          pwdResetToken,
          state: this.state,
        });

        routerService.goToUserLogin({ params: { success: passwordResetSuccess } });
      } catch (error) {
        const { message = passwordResetError } = ProcessPasswordResetMfaError('duo', error);
        routerService.goToUserLogin({ params: { error: message } });
      } finally {
        LocalStorageService.removeItem(userPwdResetInfo);
      }
    },
  },
});
