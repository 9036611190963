import MfaConstants from 'client/containers/Login/MfaConstants';
import mfaTypes from 'client/containers/user/MfaComponents/mfaUtils/mfaTypes';
import type { MfaFactor } from 'client/types/Auth';

const { mfaNames } = MfaConstants;

// eslint-disable-next-line max-len
export default (mfa: {factors: any[]} = { factors: [] }, options = {}): MfaFactor[] => {
  const sortOrder: {[index: string]: number} = {
    [mfaNames.push]: 0,
    [mfaNames.totp]: 1,
    [mfaNames.webauthn]: 2,
    [mfaNames.duo]: 3,
    [mfaNames.durt]: 4,
    [mfaNames.durt_uv]: 5,
  };

  return mfa.factors
    .map((factor) => mfaTypes(
      {
        factor,
      },
      options,
    ))
    .filter((f) => f.status === MfaConstants.mfaStatus.available)
    .sort((a, b) => sortOrder[a.name] - sortOrder[b.name]);
};
