<template>
  <transition
    :enterClass="$style.fadeUpEnter"
    :enterActiveClass="$style.fadeUpEnterActive"
    :leaveActiveClass="$style.fadeUpLeaveActive"
    :leaveToClass="$style.fadeUpLeaveTo"
  >
    <div
      :class="[$style[type], $style.loginAlert, alignClass]"
      v-if="message || $slots.default"
    >
      <div
        :class="$style.iconContainer"
        v-if="!centered"
      >
        <i :class="[iconClass, $style.alertIcon]" />
      </div>
      <div v-if="!$slots.default">
        {{ message }}
      </div>
      <slot />
    </div>
  </transition>
</template>

<script>
import { LoginConsts } from 'client/util/Constants';

const { error, success } = LoginConsts.alertTypes;

export default {
  name: 'Alert',
  props: {
    // Determines whether the alert uses a centered text style
    centered: {
      type: Boolean,
      default: false,
    },

    // The name of the error to display; if empty, alert is hidden
    message: {
      type: String,
      default: '',
    },

    // Determines what styles to use for the alert
    type: {
      type: String,
      default: error,
    },
  },
  computed: {
    alignClass() {
      if (this.type === error) {
        return this.$style.loginAlertCenter;
      }
      return this.centered ? this.$style.loginAlertCenter : this.$style.loginAlertLeft;
    },
    iconClass() {
      return this.type === success ? 'jc-actions-check' : 'jc-actions-alert';
    },
  },
};
</script>

<style module>
@value fadeTransition from 'css/transitions.css';
@value (jcGrey900, jcError, jcError100, jcSuccess, jcSuccess100) from 'css/brand-colors.css';
@value lightShadow from 'css/shadows.css';

.loginAlert {
  border-radius: 3px;
  box-shadow: lightShadow;
  color: jcGrey900;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 1rem;
  position: relative;
}

.error {
  background: jcError100;
  border-left: 5px solid jcError;
}

.success {
  background: jcSuccess100;
  border-left: 5px solid jcSuccess;
}

.loginAlertLeft {
  border-left: 0;
  padding: 1rem;
  text-align: left;
}

.loginAlertCenter {
  border-left: 0;
  padding: 17.5px 30px;
  text-align: center;
}

.iconContainer {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.alertIcon {
  font-size: 21px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fadeUpEnterActive,
.fadeUpLeaveActive {
  transition: fadeTransition;
}

.fadeUpEnter,
.fadeUpLeaveTo {
  opacity: 0;
  transform: translate(20px, 0);
}
</style>
