<template>
  <div :class="$style.formContainer">
    <LogoIcon
      :fixed="false"
    />
    <h1 :class="$style.header">
      Update your password in 3 easy steps
    </h1>
    <ol
      :class="$style.directions"
    >
      <li
        v-for="step in steps"
        :class="$style.directionsItem"
        :key="step.step"
      >
        {{ step.text }}
      </li>
    </ol>
    <img
      :class="$style.image"
      :src="image"
    >
    <small :class="$style.disclaimer">
      * If TOTP MFA is enabled you will be prompted after confirm password to enter your TOTP code.
    </small>
    <a
      :class="$style.link"
      :href="resetUserPassword"
      v-if="$route.query.key"
    >
      I don't see how, continue to portal
    </a>
  </div>
</template>

<script>
import { AdminRoutes } from 'client/routes/RouteGenerator';
import LogoIcon from 'client/components/LogoIcon.vue';

const { resetUserPassword } = AdminRoutes;

export default {
  name: 'PasswordResetSteps',

  components: {
    LogoIcon,
  },

  props: {
    image: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },

  computed: {
    resetUserPassword() {
      return this.$route.query.key ? `${resetUserPassword}&key=${this.$route.query.key}` : resetUserPassword;
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

.formContainer {
  max-width: 90ch;
}

.directions {
  counter-reset: password-reset-counter;
  list-style-type: none;
  margin: 4rem auto;
  max-width: 300px;
  padding-left: 0;
  text-align: left;
}

.directionsItem {
  align-items: center;
  counter-increment: password-reset-counter;
  display: flex;
  margin-bottom: 2rem;
}

.directionsItem::before {
  align-self: flex-start;
  border: 1px solid currentColor;
  border-radius: 100%;
  content: counter(password-reset-counter);
  display: block;
  flex: 0 0 auto;
  height: 1.5rem;
  line-height: 1.5;
  margin-right: 1rem;
  text-align: center;
  width: 1.5rem;
}

.image {
  margin: 0 auto 2rem;
  max-width: 35rem;
  width: 100%;
}

.disclaimer {
  display: block;
  font-size: 0.875rem;
  margin: 0 0 4rem;
}

.link {
  color: currentColor;
}

@media screen and (min-width: 800px) {
  .directions {
    align-items: flex-start;
    display: flex;
    justify-content: space-around;
    max-width: 100%;
  }

  .directionsItem {
    margin-bottom: 0;
  }

  .directionsItem:not(:last-child) {
    margin-right: 2rem;
  }
}
</style>
