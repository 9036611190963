import {
  androidDeepLinks, androidHttpLinks, durtApiResponseType, command as durtCommands,
} from 'client/services/durtConstants';
import UserLoginStore from 'client/stores/UserLoginStore';
import Util from 'client/util/Util';
import axios, { AxiosError, AxiosResponse } from 'axios';

// Tried installing @types/chrome, but ts-loader needed to be upgraded,
// but then that required upgrading webpack.
declare global {
  interface Window {
    chrome: any;
    jumpcloud: {
      firefox: any;
    };
  }
  const browser: any;
}

function sendMessage<T>(browser: any, extensionId: string, payload: any): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      browser.runtime.sendMessage(
        extensionId,
        payload,
        (response:any) => {
          if (browser.runtime.lastError) {
            reject(browser.runtime.lastError);
          }
          resolve(response);
        },
      );
    } catch (error: any) {
      reject(error);
    }
  });
}

function sendFirefoxMessage<T>(browser: any, extensionId: string, payload: any): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      browser.runtime.sendMessage(
        extensionId,
        payload,
        () => {
          if (browser.runtime.response) resolve(browser.runtime.response);
          else reject(Error('durt extension error: no response'));
        },
      );
    } catch (error: any) {
      reject(error);
    }
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sendMessageToChromeExtension<T>(extensionId: string, payload: any): Promise<T> {
  return sendMessage(window.chrome, extensionId, payload);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sendMessageToFirefoxExtension<T>(extensionId: string, payload: any): Promise<T> {
  return sendFirefoxMessage(window.jumpcloud.firefox, extensionId, payload);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sendMessageToSafariExtension<T>(extensionId: string, payload: any): Promise<T> {
  return sendMessage(browser, extensionId, payload);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function wakeUpAndroidServer(command: string, uuid: string): Promise<null> {
  return new Promise((resolve, reject) => {
    if (!(navigator as any).userActivation.hasBeenActive) {
      reject(Error(durtApiResponseType.userNotActive));
      return;
    }

    const env = Util.getEnvironment();
    const linkByEnv = androidDeepLinks[env];

    const deepLink = `${linkByEnv}://app?command=${command}&uuid=${uuid}`;
    const existingLink = document.querySelector<HTMLAnchorElement>('.android-deep-link');

    if (!existingLink) {
      const link = document.createElement('a');

      link.setAttribute('style', 'display:none;');
      link.href = deepLink;
      link.id = 'android-deep-link';

      document.body.appendChild(link);
      link.click();
    } else {
      existingLink.href = deepLink; // reset href with new param values
      existingLink.click();
    }

    resolve(null);
  });
}

export function wakeUpAndroidServerHttp(command: string, uuid: string): void {
  if (!(navigator as any).userActivation.hasBeenActive) {
    throw new Error(durtApiResponseType.userNotActive);
  }

  let link = document.querySelector<HTMLAnchorElement>('.android-deep-link');

  if (!link) {
    link = document.createElement('a');

    link.setAttribute('style', 'display:none;');
    link.id = 'android-deep-link';

    document.body.appendChild(link);
  }

  const env = Util.getEnvironment();
  const linkByEnv = androidHttpLinks[env];
  const store = UserLoginStore();
  const redirect = Util.buildUrl(store.pageLoadUrl, { autoGo: 'false' });

  link.href = Util.buildUrl(linkByEnv, { command, uuid, redirect });
  link.click();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function postToAndroidServer<T>(payload: any): Promise<T> {
  let counter = 18;
  const domain = 'http://localhost:1028';

  return new Promise((resolve, reject) => {
    const query = () => {
      axios.post(domain, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res: AxiosResponse) => {
          if (res.statusText === 'OK' && res.status === 200) {
            resolve(res.data);
          }
          throw new Error(durtApiResponseType.unexpectedError);
        })
        .catch((e: AxiosError) => {
          if (e.code === AxiosError.ERR_NETWORK) { // Network error indicates server is not up yet
            if (counter >= 0) {
              counter -= 1;
              setTimeout(query, 250);
            } else {
              reject(e);
            }
          } else {
            reject(e);
          }
        });
    };

    // ensure deep link call happens first
    setTimeout(query, 250);
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, max-len
export async function sendMessageToAndroidApp<T>(appId: string, payload: any): Promise<T> {
  if (payload.cmd !== durtCommands.access) {
    throw new Error(durtApiResponseType.userNotActive);
  }

  await wakeUpAndroidServer(payload.cmd, payload.pkce);

  return postToAndroidServer(payload);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, max-len
export async function sendMessageToAndroidHttp<T>(appId: string, payload: any): Promise<T> {
  if (payload.cmd !== durtCommands.access) {
    throw new Error(durtApiResponseType.userNotActive);
  }

  wakeUpAndroidServerHttp(payload.cmd, payload.pkce);

  return postToAndroidServer(payload);
}
