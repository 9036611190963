
import { defineComponent } from 'vue';

import LoadingIcon from 'components/LoadingIcon.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';

import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import LocalStorageConstants from 'client/util/Constants/LocalStorageConstants';
import MfaConstants from 'client/containers/Login/MfaConstants';

import LocalStorageService from 'client/util/LocalStorageService';

const { mfaNames } = MfaConstants;

export default defineComponent({
  name: 'DuoRedirectView',

  components: {
    LoadingIcon,
    LoginActionButton,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const uiLabel = useMfaLabels();

    return { uiLabel };
  },

  computed: {
    buttonText(): string {
      const { enabled, disabled } = this.uiLabel.duo.button;

      return this.isLoading ? disabled : enabled;
    },

    hasDuoAsLastFactor(): boolean {
      return LocalStorageService.getItem(LocalStorageConstants.previousMfaMethod) === mfaNames.duo;
    },

    shouldAutoRedirect() {
      // Should only auto redirect when coming from MFA Chooser
      // • having no factor in local storage indicates this OR
      // • from param will be included when coming from a different factor via MFA Chooser
      //   (`?step=mfa&mfaType=totp --> ?step=mfa&mfaType=duo`) - See MfaView.vue
      return !this.hasDuoAsLastFactor || !!this.$route.params.from;
    },
  },

  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
  },

  mounted() {
    if (this.shouldAutoRedirect) {
      this.$emit('submit');
    }
  },
});
