import { datadogRum } from '@datadog/browser-rum-slim';
import Util from 'client/util/Util';
import type { EventActionType, EventData } from 'client/types/Durt';

const createBaseEvent = () => ({
  timestamp: Date.now(),
  env: Util.getEnvironment(),
});

export default {
  emitAction(type: EventActionType, data?: EventData): void {
    const event = {
      ...createBaseEvent(),
      data,
    };

    return datadogRum.addAction(type, event);
  },
};
