<template>
  <div :class="$style.adminTotpSetup">
    <AdminGetAuthApp
      v-if="!shouldShowVerifyAccount"
      @onGoForward="goForward"
    />
    <AdminVerifyAccount
      v-if="shouldShowVerifyAccount"
      :error="errorMessage"
      :isLoading="isLoading"
      @onGoBack="goBack"
      @onSubmit="submit"
      :totp="totp"
    />
  </div>
</template>

<script>
import { AdminRoutes } from 'client/routes/RouteGenerator';
import { LoginConsts } from 'client/util/Constants';
import { get } from 'lodash';
import AdminGetAuthApp from 'client/containers/AdminTotpSetup/AdminGetAuthApp.vue';
import AdminTotpSetupConsts from 'client/containers/AdminTotpSetup/AdminTotpSetupConsts';
import AdminVerifyAccount from 'client/containers/AdminTotpSetup/AdminVerifyAccount.vue';
import LocalStorageService from 'client/util/LocalStorageService';
import ResetPasswordStore from 'client/stores/ResetPasswordStore';
import loginUtil from 'client/components/mixins/loginUtil';

const { responseMessages } = LoginConsts;
const {
  passwordResetSuccess,
  totpResetSuccess,
} = responseMessages;

const {
  verifyFailureMessage,
} = AdminTotpSetupConsts;
const { clientType } = LoginConsts;

export default {
  inject: ['$validator'],

  mixins: [loginUtil],

  components: {
    AdminGetAuthApp,
    AdminVerifyAccount,
  },

  LocalStorageService,

  props: {
    clientType: {
      type: String,
      default: clientType.admin,
    },
    totp: {
      type: String,
      default: '',
    },
    isTotpResetFlow: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    shouldShowVerifyAccount: false,
  }),

  setup() {
    const resetPasswordStore = ResetPasswordStore();
    return { resetPasswordStore };
  },

  methods: {
    goBack() {
      this.shouldShowVerifyAccount = false;

      if (this.errorMessage) {
        this.errorMessage = '';
      }
    },

    goForward() {
      this.shouldShowVerifyAccount = true;
    },

    async submit(otp) {
      this.setRequestState();

      this.resetPasswordStore.otp = otp;

      try {
        if (this.isTotpResetFlow) {
          await this.resetPasswordStore.verifyResetTOTP();
        } else {
          await this.resetPasswordStore.verifyResetPassword();
        }

        // Set item in order to show success alert on Admin Login form
        this.$options.LocalStorageService.setItem(
          'resetSuccessMessage',
          this.isTotpResetFlow ? totpResetSuccess : passwordResetSuccess,
        );

        // Force Admin to log back in with new credentials
        this.redirect(AdminRoutes.logout);
      } catch (error) {
        this.errorMessage = get(error, 'response.data.message', verifyFailureMessage);
        this.isLoading = false;
      }
    },
  },
};

</script>

<style module>
@value (jcWhite50) from 'css/brand-colors.css';
@value (max-width-large) from 'css/breakpoints.css';

.adminTotpSetup {
  --spacing: 2.2em;

  background-color: jcWhite50;
  box-shadow: 0 0 2.875rem rgba(0, 0, 0, 6%);
  margin: 6.425rem auto;
  overflow: hidden;
  padding: var(--spacing);
  width: 56rem;
}

@media (max-width: max-width-large) {
  .adminTotpSetup {
    width: calc(100% - var(--spacing));
  }
}

</style>
