<template>
  <div>
    <JumpcloudGoPageLoader :userEmail="userEmail" />
  </div>
</template>

<script>
import { LoginConsts } from 'client/util/Constants';
import JumpcloudGoPageLoader from 'client/components/JumpcloudGoPageLoader.vue';
import Util from 'client/util/Util';
import durtService from 'client/services/durtService';
import oidcClientService from 'client/services/oidcClientService';
import routerService from 'client/services/routerService';

const { responseMessages } = LoginConsts;

export default {
  name: 'JumpCloudOidcCallback',

  components: {
    JumpcloudGoPageLoader,
  },

  data() {
    return {
      userEmail: '',
    };
  },

  created() {
    const errorCode = this.$route.query.error;

    if (errorCode) {
      const message = responseMessages[errorCode] || responseMessages.unexpected_error;
      routerService.replaceToUserLogin({ params: { error: message } });
    } else {
      this.durtRegister();
    }
  },

  methods: {
    async durtRegister() {
      let userState = '';
      try {
        // in case processSigninResponse fails we will loose the user state
        // should handle the state our selves or we can rely on this
        const oidcResponse = await oidcClientService.processSigninResponse();
        this.userEmail = oidcResponse.profile.email;
        userState = oidcResponse.userState;
        if (!oidcResponse?.id_token) {
          throw new Error('id_token not found in oidc response');
        }
        await durtService.register(oidcResponse.id_token);

        // Calling getAccess & signin after successful register
        // ensures user session is DURT-derived.
        const accessData = await durtService.getAccess();
        // Wait 2s to allow JCGO animation enough time to render
        await Promise.all([durtService.signin(accessData), Util.delay(1000)]);

        window.location.replace(`/login${userState ? '?' : ''}${userState}`);
      } catch (e) {
        try {
          const { text, logout = false } = JSON.parse(e?.response?.data?.message);

          if (logout) {
            // invalidate durt for IdP signin attempts
            await durtService.logout();

            const location = {
              params: {
                error: text,
              },
              query: {},
            };

            if (userState) {
              const [name, value] = userState.split('=');
              location.query[name] = value;
            }

            routerService.replaceToUserLogin(location);
          }
        } catch {
          // if the user is logged in (which in the normal case he is,
          // because we are the relying party and the authority)
          // the login error won't appear in the notification with code below
          window.location.replace(`/login?error=goError&${userState}`);
          // should we show him a warning notification in this page (before redirecting him)
          // and in case he is logged in we can show a continue button
        }
      }
    },
  },
};
</script>
