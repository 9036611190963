import axios from 'axios';

// use a local axios instance to prevent interceptor pollution
const localAxios = axios.create();

/**
 * Contains all the functionality for retrieving a csrf or xsrf token. The csrf token is used for
 * all adminportal requests, and the xsrf token is for all userportal request.
 */
export default {
  /**
   * Pass to the axios interceptor in order to ensure that the csrf is present. If not then fetch
   * the csrf token and add it to the current axios request config.
   * @param {String} csrfUrl [url for GET request to retrieve CSRF token]
   * @param {String} csrfHeader [request header name for the csrf token]
   * @param {Object} currentRequestConfig [request config from the axios interceptor]
   * @param {Object} axiosInstance [which axios instance to set the headers of. Defaults to the
   *                                global axios instance.]
   * @return {Object||Error} [The axios interceptor config or an Error on failed CSRF token request]
   */
  async confirmCsrf(csrfUrl, csrfHeader, currentRequestConfig, axiosInstance) {
    const requestInterceptorConfig = currentRequestConfig;
    if (requestInterceptorConfig.headers[csrfHeader]) {
      return requestInterceptorConfig;
    }
    const csrfTokenFetchResult = await this.fetchCsrfToken(csrfUrl, csrfHeader, axiosInstance);
    if (csrfTokenFetchResult instanceof Error) {
      return Error('Could not retrieve CSRF Token.');
    }
    requestInterceptorConfig.headers[csrfHeader] = csrfTokenFetchResult;
    return requestInterceptorConfig;
  },

  /**
   * Fetches the CSRF token. Requests without the CSRF in the header will be rejected. This will set
   * the default CSRF header setting in the axios config passed to it.
   * @TODO: At the moment after the CSRF token has been retrieved it does not set the global axios
   * common default header. When this is moved to the base API class we should ensure that the
   * default common header is set instead of just the regular default header. This will set the
   * header for all created instances of axios instead of just the individual instance.
   * @param {String} csrfUrl [url for GET request to retrieve CSRF token]
   * @param {String} csrfHeader [request header name for the csrf token]
   * @param {Object} axiosInstance [which axios instance to set the headers of. Defaults to the
   *                                global axios instance.]
   * @return {String||Error } [Return the fetched CSRF token, on failure return an error]
   */
  async fetchCsrfToken(csrfUrl, csrfHeader, axiosInstance) {
    let csrfToken;
    const apiAxiosInstance = axiosInstance;
    /**
     * Create a new axios instance in order to be safe against other interceptors.
     * The axios interceptor gets stuck in an infinite loop when axios when using the same instance.
     */
    try {
      const { data } = await localAxios.get(csrfUrl);
      csrfToken = typeof data === 'string' ? data : data.xsrf;
      if (!csrfToken) {
        return Error('Could not find CSRF token!');
      }
      apiAxiosInstance.defaults.headers[csrfHeader] = csrfToken;
      return csrfToken;
    } catch (error) {
      return error;
    }
  },

  /**
   * Clears the default csrf header off an axios instance. The CSRF header is cached
   * as a default headers. If it expires, then it must be manually cleared. At some point,
   * we should consider auto clearing it based on a timer instead of manual, but this is
   * still probably needed as a safety anyway.
   * @param {Object} axiosInstance Axios instance to remove the csrf token from.
   * @param {String} csrfHeader Header name. Admin uses csrf and User uses xsrf versions.
   */
  clearCsrfToken(axiosInstance, csrfHeader) {
    // eslint-disable-next-line no-param-reassign
    delete axiosInstance.defaults.headers[csrfHeader];
  },

  getCsrfToken(axiosInstance, csrfHeader) {
    return axiosInstance.defaults.headers[csrfHeader];
  },
};
