<template>
  <div :class="$style.qrCodeContainer">
    <transition-group
      :enterClass="$style.fadeTo"
      :enterActiveClass="$style.qrFadeTransition"
      :leaveActiveClass="$style.qrFadeTransition"
      :leaveToClass="$style.fadeTo"
    >
      <div
        key="loadingContent"
        v-if="qrImageLoading"
        :class="$style.loadingContent"
      >
        <LoadingIcon />
      </div>
      <img
        key="qrImage"
        :class="$style.qrCode"
        v-if="!qrImageLoading"
        :src="qrCodeSrc"
      >
    </transition-group>
  </div>
</template>

<script>
import * as QRCodeGenerator from 'qrcode';
import { AdminRoutes, UserRoutes } from 'client/routes/RouteGenerator';
import LoadingIcon from 'components/LoadingIcon.vue';

export default {
  name: 'QrCode',

  components: {
    LoadingIcon,
  },

  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
    totp: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    qrCodeSrc: '',
    qrImageLoading: true,
  }),

  mounted() {
    this.initializeQrCodeSrc();
  },

  methods: {
    /**
     * Check the clientType, for users we need to generate a qr code src. Then load the image.
     * QR code options see: https://github.com/soldair/node-qrcode#qr-code-options
     */
    async initializeQrCodeSrc() {
      let qrCodeSrc;

      if (this.isAdmin) {
        qrCodeSrc = AdminRoutes.qrCode;
      } else {
        qrCodeSrc = await QRCodeGenerator.toDataURL(UserRoutes.qrCodeValue(this.email, this.totp), {
          errorCorrectionLevel: 'high',
          scale: 10,
        }).catch(() => {
          // catch and do nothing
        });
      }
      this.loadQrImage(qrCodeSrc);
    },

    /**
     * Loads the image using js then sets the loading state to false to display the QR code.
     * @return {Object} [The resulting image]
     */
    loadQrImage(qrCodeSrc) {
      this.qrCodeSrc = qrCodeSrc;
      const image = new Image();
      image.onload = () => {
        this.qrImageLoading = false;
      };
      image.src = qrCodeSrc;
      return image;
    },
  },
};

</script>

<style module>
@value (fadeTransition) from 'css/transitions.css';

.fadeTo {
  opacity: 0;
}

.loadingContent {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.qrCodeContainer {
  height: 130px;
  margin: 40px auto 20px auto;
  position: relative;
  width: 130px;
}

.qrCode {
  width: 100%;
}

.qrFadeTransition {
  transition: fadeTransition;
}

</style>
