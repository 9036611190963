<template>
  <section :class="$style.container">
    <ButtonLoginSwitch
      v-if="!redirectTo && !loginChallenge && !isAdminMFA"
      :clickHandler="goToUserLogin"
    >
      User Portal Login
    </ButtonLoginSwitch>
    <VerifyTOTP
      v-if="isAdminMFA"
    />
    <div
      v-else
      :class="$style.formContainer"
    >
      <AdminLoginController
        data-test-id="AdminLoginController"
        :error="error"
      />
      <LogoIcon />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapStores } from 'pinia';

import AdminLoginController from 'client/containers/Login/AdminLoginController.vue';
import ButtonLoginSwitch from 'client/components/ButtonLoginSwitch.vue';
import Constants from 'client/util/Constants';
import Cookies from 'js-cookie';
import LoginConsts from 'client/util/Constants/LoginConstants';
import LogoIcon from 'client/components/LogoIcon.vue';
import UserLoginStore from 'client/stores/UserLoginStore';
import VerifyTOTP from 'client/containers/AdminVerifyTOTP.vue';
import loginUtil from 'client/components/mixins/loginUtil';

const { redirectTo } = Constants;
const { clientType, clientTypeCookie } = LoginConsts;

export default {
  name: 'AdminLoginWrapper',

  mixins: [
    loginUtil,
  ],

  props: {
    clientType: {
      type: String,
      default: clientType.admin,
    },
    error: {
      type: String,
      default: undefined,
    },
    form: {
      type: Object,
      default: () => ({
        email: '',
        password: '',
      }),
    },
    redirectTo: {
      type: String,
      default: '',
    },
    requestKey: {
      type: String,
      default: '',
    },
  },

  components: {
    AdminLoginController,
    LogoIcon,
    ButtonLoginSwitch,
    VerifyTOTP,
  },

  methods: {
    goToUserLogin() {
      // removing redirectTo from session to prevent issues when swtiching clientTypes
      sessionStorage.removeItem(redirectTo);

      this.vueRouteChange('Login', { form: { email: this.userLoginStore.email, password: this.userLoginStore.password } });
    },
  },

  computed: {
    ...mapStores(UserLoginStore),
    ...mapGetters('LoginOauthAdminFlowModel', [
      'loginChallenge',
    ]),

    isAdminMFA() {
      return this.$route.query.template === 'loginMFA';
    },
  },

  mounted() {
    Cookies.set(clientTypeCookie, 'admin');
  },
};
</script>

<style module>
@import '../../css/login-components.css';
</style>
