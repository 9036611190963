import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    loginChallenge: undefined, // used for oauth
  },
  getters: {
    loginChallenge: (state) => state.loginChallenge,
  },
  mutations: {
    setValue: (state, { key, value }) => {
      Vue.set(state, key, value);
    },
  },
  actions: {
    setLoginChallenge({ commit }, challenge) {
      commit('setValue', { key: 'loginChallenge', value: challenge });
    },
  },
};
