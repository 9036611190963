<template>
  <div :class="$style.formContainer">
    <transitionGroup
      :enterClass="$style.fadeEnter"
      :enterActiveClass="$style.fadeEnterActive"
      :leaveActiveClass="$style.fadeLeaveActive"
      :leaveToClass="$style.fadeLeaveTo"
    >
      <UserResetPasswordRequest
        v-if="!isAdmin()"
        key="user"
        :errorMessage="errorMessage"
        :isLoading="isLoading"
        :success="success"
        @goToLogin="goToUserLogin"
        @submit="request"
      />
      <AdminResetPasswordRequest
        v-else
        key="admin"
        :errorMessage="errorMessage"
        :isLoading="isLoading"
        :success="success"
        @goToLogin="goToAdminLogin"
        @submit="request"
      />
    </transitionGroup>
    <LogoIcon />
  </div>
</template>

<script>
import AdminResetPasswordRequest from 'client/containers/admin/PasswordReset/AdminResetPasswordRequest.vue';
import UserResetPasswordRequest from 'client/containers/user/UserResetPassword/UserResetPasswordRequest.vue';
import api from 'client/api';
import loginUtil from 'client/components/mixins/loginUtil';
import routerService from 'client/services/routerService';

export default {
  name: 'RequestPasswordReset',

  components: {
    AdminResetPasswordRequest,
    UserResetPasswordRequest,
  },

  mixins: [loginUtil],

  props: {
    clientType: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      default: undefined,
    },
    redirectTo: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    success: false,
  }),

  methods: {
    goToAdminLogin() {
      routerService.goToAdminLogin();
    },

    goToUserLogin() {
      const query = this.context && this.redirectTo
        ? { context: this.context, redirectTo: this.redirectTo }
        : {};
      routerService.goToUserLogin({ query });
    },

    request(email) {
      api[this.clientType].requestPasswordReset({ email })
        .then(() => {
          this.isLoading = false;
          this.success = true;
        }).catch(() => {
          this.isLoading = false;
          this.errorMessage = 'There was an error handling your request.';
        });
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

.fadeEnterActive {
  transition: opacity 0.35s 0.35s;
}

.fadeLeaveActive {
  transition: opacity 0.35s;
}

.fadeEnter,
.fadeLeaveTo {
  opacity: 0;
}
</style>
