
import { defineComponent } from 'vue';
import EmailEntry from 'client/containers/user/login/EmailEntry.vue';
import JumpCloudGoButton from 'client/components/JumpcloudGoButton.vue';
import LoginAlert from 'client/components/LoginAlert.vue';

export default defineComponent({
  name: 'Register',

  components: {
    EmailEntry,
    JumpCloudGoButton,
    LoginAlert,
  },

  props: {
    durtErrorMessage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,
      shouldShowEmail: false,
    };
  },

  computed: {
    buttonText() {
      return 'Not Now';
    },

    title() {
      return [
        'JumpCloud Go',
        'Device Registration',
      ];
    },

    description() {
      return 'Speed up and secure your future logins by registering this device for JumpCloud Go.';
    },

    disclaimers() {
      return [
        'Supports biometric authenticators for passwordless login',
        'Requires a company managed device',
      ];
    },

    errorMessage() {
      return this.durtErrorMessage || this.$route.params.error;
    },
  },

  methods: {
    handleContinue(payload: any) {
      this.$emit('submitEmail', payload);
    },

    handleRegister() {
      this.$emit('resetErrorMessage');
      this.isLoading = true;
      this.$emit('goRegister');
    },

    toggleEmail() {
      this.shouldShowEmail = !this.shouldShowEmail;
    },
  },
});

