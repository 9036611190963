
// Libraries
import { defineComponent } from 'vue';
import { get } from 'lodash';
import Cookies from 'js-cookie';

// Components
import Access from 'client/containers/user/login/jumpcloudGo/Access.vue';
import AuthView from 'client/containers/user/Views/AuthView.vue';
import ButtonLoginSwitch from 'client/components/ButtonLoginSwitch.vue';
import DuoRedirectHandler from 'client/containers/user/login/mfa/DuoRedirectHandler.vue';
import EmailEntry from 'client/containers/user/login/EmailEntry.vue';
import JumpcloudGoPageLoader from 'client/components/JumpcloudGoPageLoader.vue';
import MfaView from 'client/containers/user/Views/MfaView.vue';
import PasswordEntry from 'client/containers/user/login/PasswordEntry.vue';
import PushUserLoginHandler from 'client/containers/user/login/mfa/PushUserLoginHandler.vue';
import Register from 'client/containers/user/login/jumpcloudGo/Register.vue';
import SSOGoContinue from 'client/containers/user/login/SSOGoContinue.vue';
import TotpUserLoginHandler from 'client/containers/user/login/mfa/TotpUserLoginHandler.vue';
import WebAuthnUserLoginHander from 'client/containers/user/login/mfa/WebAuthnUserLoginHandler.vue';

// Constants and Types
import { useLoginLabels } from 'client/util/Constants/LoginLabels';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import Constants, { LocalStorageConsts, LoginConsts } from 'client/util/Constants';
import MfaConstants from 'client/containers/Login/MfaConstants';
import MfaRequiredError, { MfaFactorResponse } from 'client/services/response/MfaRequiredError';
import RequestError from 'client/services/response/RequestError';
import type { MfaFactor } from 'client/types/Auth';

// Utils
import { durtApiResponseType, extensionTypes, infoResponse } from 'client/services/durtConstants';
import LocalStorageService from 'client/util/LocalStorageService';
import Util from 'client/util/Util';
import durtService from 'client/services/durtService';
import getCurrentComponentNameFromList from 'client/containers/user/MfaComponents/mfaUtils/getCurrentComponentNameFromList';
import getLastUsedFactor from 'client/containers/user/MfaComponents/mfaUtils/getLastUsedFactor';
import mfaFactorBuilder from 'client/containers/user/MfaComponents/mfaUtils/mfaFactorBuilder';
import oidcClientService from 'client/services/oidcClientService';
import routerService from 'client/services/routerService';
import userLoginService from 'client/services/userLoginService';

// Store
import { mapStores } from 'pinia';
import UserLoginStore from 'client/stores/UserLoginStore';

const { redirectTo } = Constants;
const { mfaNames } = MfaConstants;
const { previousMfaMethod, userLoginEmail } = LocalStorageConsts;
const {
  clientType,
  clientTypeCookie,
  responseMessages,
  userConsoleRootRoute,
} = LoginConsts;
const {
  goError,
  loginError,
  mfaRequired,
  mfaUnavailable,
  passwordRequired,
} = responseMessages;

interface ControllingQueryParams {
  context?: string,
  redirectTo?: string,
}

const componentsUnderControl = [
  {
    id: '',
    componentName: EmailEntry.name as string,
  },
  {
    id: 'password',
    componentName: PasswordEntry.name as string,
  },
  {
    id: 'ssoGoContinue',
    componentName: SSOGoContinue.name as string,
  },
  {
    id: 'goRegister',
    componentName: Register.name as string,
  },
  {
    id: 'goAccess',
    componentName: Access.name as string,
  },
  {
    id: mfaNames.duo,
    componentName: DuoRedirectHandler.name as string,
  },
  {
    id: mfaNames.totp,
    componentName: TotpUserLoginHandler.name as string,
  },
  {
    id: mfaNames.webauthn,
    componentName: WebAuthnUserLoginHander.name as string,
  },
  {
    id: mfaNames.push,
    componentName: PushUserLoginHandler.name as string,
  },
];

const getCurrentComponentName = getCurrentComponentNameFromList(componentsUnderControl);

const initialState = {
  email: '',
  password: '',
  shouldRememberEmail: false,
};

type ComponentPropsType = {
  context: string,
  controllingQueryParams: ControllingQueryParams,
  hasPendingErrorMessage: boolean,
  redirectTo: string,
  successMessage: string,
  durtInfo: infoResponse,
  durtErrorMessage: string,
};

export default defineComponent({
  name: 'UserLoginController',

  components: {
    Access,
    AuthView,
    DuoRedirectHandler,
    ButtonLoginSwitch,
    EmailEntry,
    JumpcloudGoPageLoader,
    MfaView,
    PasswordEntry,
    PushUserLoginHandler,
    Register,
    SSOGoContinue,
    TotpUserLoginHandler,
    WebAuthnUserLoginHander,
  },

  props: {
    context: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({
        email: '',
        password: '',
      }),
    },
    paramError: {
      type: String,
      default: '',
    },
    mfaType: {
      type: String,
      default: '',
    },
    redirectTo: {
      type: String,
      default: '',
    },
    step: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: '',
    },
  },

  setup() {
    const loginLabels = useLoginLabels();
    const mfaLabels = useMfaLabels();

    return {
      loginLabels,
      mfaLabels,
    };
  },

  data() {
    return {
      durtErrorMessage: '',
      durtExtType: '',
      durtInfo: {} as infoResponse,
      errorMessage: '',
      // using undefined so we display 0
      errorMessageDisplayDuration: undefined as number | undefined,
      factors: [] as MfaFactor[],
      isGoLoading: false,
      isLoginError: false,
      isReady: false,
      showGoContinueButton: false,
      userFormData: { ...initialState },
    };
  },

  computed: {
    ...mapStores(UserLoginStore),

    componentHandlers() {
      return {
        // Used in: Push
        clearCredentials: this.clearCredentials,
        // Used in: Email, Password, Push, WebAuthN
        resetErrorMessage: this.onResetErrorMessage,
        // Used in: Email, Password, Totp, Push, Duo
        success: this.onSuccess,
        // Used in: Email
        submitEmail: this.handleEmailSubmit,
        // Used in: Register
        goRegister: this.handleJumpCloudGoRegister,
        // Use in: Access
        goAccess: this.durtSignin,
      };
    },

    componentName(): string {
      const id = this.isMfa ? this.mfaType : this.step;

      return getCurrentComponentName(id);
    },

    componentProps(): ComponentPropsType {
      return {
        // Used in: Password
        context: this.context,
        // Used in: Push (TODO: see if can use handler)
        controllingQueryParams: this.controllingQueryParams,
        // Push
        hasPendingErrorMessage: this.hasPendingErrorMessage,
        // Used in: Email
        redirectTo: this.redirectTo,
        // Used in: Email
        successMessage: this.success,
        // Used in Access
        durtInfo: this.durtInfo,
        // Used in Register and Access
        durtErrorMessage: this.durtErrorMessage,
      };
    },

    controllingQueryParams(): ControllingQueryParams {
      // this ensures we don't lose query params as we navigate
      const redirectParam = this.sanitizedRedirectTo
        ? { redirectTo: this.sanitizedRedirectTo }
        : {};
      const contextParam = this.context ? { context: this.context } : {};
      const loginHintParam = this.$route.query.login_hint
        ? { login_hint: this.$route.query.login_hint }
        : {};
      const autoGoParam = this.$route.query.autoGo
        ? { autoGo: this.$route.query.autoGo }
        : {};

      return {
        ...redirectParam,
        ...contextParam,
        ...loginHintParam,
        ...autoGoParam,
      };
    },

    errorMessageToDisplay(): string {
      // show param error on email page, local error anywhere else
      if (this.isMfa) {
        return this.errorMessage;
      }

      if (this.isGoRegistration || this.isGoAccess) {
        // let jcGO components handle UI
        return '';
      }
      return this.paramError;
    },

    hasPendingErrorMessage() {
      const remainingDuration = this.errorMessageDisplayDuration || -1;
      return remainingDuration >= 0;
    },

    headerText(): string {
      const getCredentialHeader = () => (
        this.context === 'sso' ? this.loginLabels.loginToApp : this.loginLabels.userLogin
      );

      if (this.isMfa) {
        return this.mfaLabels.verifyIdentity;
      }

      if (this.isGoRegistration || this.isGoAccess) {
        // let jcGO components handle UI
        return '';
      }

      return getCredentialHeader();
    },

    isAdminRedirectAllowed(): boolean {
      // redirectTo comes from oauth and sso login
      // these login types should block navigation to admin
      return !this.redirectTo;
    },

    isGoAccess() {
      return this.step === 'goAccess';
    },

    isGoRegistration() {
      return this.step === 'goRegister';
    },

    isSsoGoContinue() {
      return this.step === 'ssoGoContinue';
    },

    isMfa(): boolean {
      return this.step === 'mfa';
    },

    isPasswordEntry(): boolean {
      return this.step === 'password';
    },

    sanitizedRedirectTo() {
      const isOauthOrSso = ['sso', 'oauth'].includes(this.context);
      const isUrlValid = this.redirectTo.startsWith('https://');
      // if oauth or sso, can use the redirectTo url, which has been sanitized
      // do not use redirectTo unless it is a sanitized URL
      return (isOauthOrSso && isUrlValid) ? decodeURIComponent(this.redirectTo) : '';
    },

    shouldHideEmailBadge(): boolean {
      return !this.step || this.isGoRegistration || this.isGoAccess || this.isSsoGoContinue;
    },

    shouldShowAdminRedirectInError(): boolean {
      // invalid credentials attach current time to credential errors
      const isInvalidCredentialError = !!this.$route.query.time;

      return isInvalidCredentialError && this.isAdminRedirectAllowed && !this.isMfa;
    },
  },

  created() {
    // AUTH-7660: disable forward button, useful when coming from different routes (stepup)
    window.history.pushState({}, '');
    this.initDurt();
  },

  mounted() {
    Cookies.set(clientTypeCookie, clientType.user);
    this.validateInitialStep();
  },

  methods: {
    clearCredentials() {
      this.userLoginStore.$reset();
    },

    goToAdminLogin() {
      // removing redirectTo from session to prevent issues when switching clientTypes
      sessionStorage.removeItem(redirectTo);
      this.userFormData.email = this.userLoginStore.email;
      this.userFormData.password = this.userLoginStore.password;
      routerService.goToAdminLogin({ params: { form: this.userFormData as any } });
    },

    handleMfaRedirect(factorsRaw: MfaFactorResponse[]) {
      this.factors = mfaFactorBuilder(
        { factors: factorsRaw },
        {
          controllingQueryParams: this.controllingQueryParams,
        },
      );

      // Edge case: user with legacy MFA required setting has no
      // available factors and totp enrollment period has expired
      if (this.factors.length === 0) {
        this.handleComponentChange('', mfaUnavailable);
        return;
      }

      const mfaParam = getLastUsedFactor(this.factors);

      routerService.goToUserLogin({
        query: {
          ...this.controllingQueryParams,
          step: 'mfa',
          ...mfaParam,
        },
      });
    },

    handleComponentChange(step: string, paramError = '') {
      // append the step for everything but email page
      const stepParam = step ? { step } : {};

      routerService.goToUserLogin({
        params: { error: paramError },
        query: { ...this.controllingQueryParams, ...stepParam },
      });
    },

    handleErrorCountdown() {
      const errorCountdown = setInterval(() => {
        if ((this.errorMessageDisplayDuration as number) < 1) {
          this.onResetErrorMessage();
          clearInterval(errorCountdown);
        }

        (this.errorMessageDisplayDuration as number) -= 1;
      }, 1000);
    },

    handleNavigate() {
      if (this.userLoginStore.verifyEmailHash) {
        Util.redirect(`${userConsoleRootRoute}${this.userLoginStore.verifyEmailHash}`);
        return;
      }

      Util.redirect(this.sanitizedRedirectTo || userConsoleRootRoute);
    },

    handleRememberUserEmail() {
      const { email, shouldRememberEmail } = this.userLoginStore.$state;

      if (shouldRememberEmail) {
        LocalStorageService.setItem(userLoginEmail, email);
      }
    },

    onCredentialError(error: unknown) {
      let message = loginError;
      let factors: MfaFactorResponse[] = [];
      if (error instanceof RequestError) {
        message = error.message;
      } else if (error instanceof MfaRequiredError) {
        message = mfaRequired;
        factors = error.factors;
      }

      this.isLoginError = message === loginError;

      switch (message) {
        case mfaRequired:
          this.handleMfaRedirect(factors);
          break;
        case passwordRequired:
          this.handleComponentChange('password');
          break;
        default:
          // credential error displayed on current page
          routerService.replaceToUserLogin({
            params: { error: message },
            query: {
              ...this.controllingQueryParams,
              step: this.step,
              time: new Date().getTime().toString(),
            },
          });
      }
    },

    onMfaError(error: any) {
      // Indicates invalid otp attempts > max attempts
      if (error?.status === 403) {
        this.handleComponentChange('password', error.message);
      } else {
        const message = error instanceof RequestError ? error.message : loginError;
        const displayDuration = error instanceof RequestError ? error.displayDuration : undefined;
        this.errorMessageDisplayDuration = displayDuration;

        if (this.errorMessageDisplayDuration && this.errorMessageDisplayDuration > 0) {
          this.handleErrorCountdown();
        }
        this.setErrorMessage(message);
      }
    },

    onResetErrorMessage() {
      this.errorMessageDisplayDuration = undefined;
      this.errorMessage = '';
      this.durtErrorMessage = '';
    },

    onSuccess() {
      if (this.isMfa && this.mfaType) {
        LocalStorageService.setItem(previousMfaMethod, this.mfaType);
      }

      this.handleRememberUserEmail();
      this.handleNavigate();
    },

    populateEmailField() {
      const savedEmail = LocalStorageService.getItem(userLoginEmail) || '';
      // email priority (highest first): userLoginStore, login_hint, localStorage
      const userEmail = this.userLoginStore.email
        || (this.$route.query.login_hint as string)
        || savedEmail;
      const shouldRememberEmail = !!(this.userLoginStore.shouldRememberEmail || savedEmail);

      this.userLoginStore.email = userEmail;
      this.userLoginStore.shouldRememberEmail = shouldRememberEmail;
    },

    validateInitialStep() {
      // during oauth with login_hint, user will be directed straight to password
      const isOauthFlowWithLoginHint = this.context === 'oauth' && !!this.$route.query.login_hint;

      return isOauthFlowWithLoginHint ? this.validateOauthWithHint() : this.validateStandard();
    },

    validateOauthWithHint() {
      this.userLoginStore.email = this.$route.query.login_hint as string;
      this.handleComponentChange('password');
    },

    validateStandard() {
      // could navigate straight to password step if saved if bookmarks without email saved
      const isInitialStepInvalid = (
        (this.isPasswordEntry || this.isMfa)
        && !this.userLoginStore.email
      );

      if (isInitialStepInvalid) {
        routerService.replaceToUserLogin({ query: { ...this.controllingQueryParams } });
      }

      this.populateEmailField();
    },

    setErrorMessage(message: string) {
      this.errorMessage = message;
    },

    async initDurt() {
      const isLoginServiceOauth = this.context === 'oauth';

      // Only getInfo if we are in a good place to use it.
      if (!isLoginServiceOauth && await durtService.hasExtension()) {
        try {
          this.durtExtType = await durtService.getExtensionType();
          this.durtInfo = await durtService.getInfo();
        } catch (err: any) {
          if (err.message === durtApiResponseType.userNotActive) {
            // Set result unique to Android MDT flow
            this.durtInfo.result = err.message;
          }
          // Ignoring error for now, and just pretend they can't DURT
        }

        if (this.durtInfo.result === durtApiResponseType.userNotRegisteredError) {
          // Param error can come from failed OIDC flow during registration
          this.handleComponentChange('goRegister', this.paramError);
        }

        if (this.durtInfo.result === durtApiResponseType.success) {
          this.handleComponentChange('goAccess');
        }

        if (this.durtInfo.result === durtApiResponseType.userNotActive
          && this.durtExtType === extensionTypes.androidHttp
        ) {
          this.handleComponentChange('goAccess');
        }
      }
      this.isReady = true;
    },

    async handleEmailSubmit(payload: { email: string, redirectTo?: string }) {
      // USER_NOT_ACTIVE response is unique to Android MDT flow
      if (this.durtInfo.result === durtApiResponseType.userNotActive
        && this.durtExtType === extensionTypes.android
      ) {
        try {
          const accessData = await durtService.getAccess();
          await durtService.signin(accessData);

          if (this.context === 'sso') {
            this.handleComponentChange('ssoGoContinue');
            return;
          }

          Util.redirect(this.sanitizedRedirectTo || userConsoleRootRoute);
        } catch {
          // continue to submit email and show password field
        }
      }

      try {
        await userLoginService.submitEmail(payload);
        this.onResetErrorMessage();
        this.handleComponentChange('password');
      } catch {
        this.onCredentialError(new RequestError(0, passwordRequired));
      }
    },

    async handleJumpCloudGoRegister() {
      try {
        await oidcClientService.signin(window.location.search.slice(1));
      } catch (err: any) {
        this.durtErrorMessage = goError;
      }
    },

    async durtSignin() {
      try {
        this.isGoLoading = true;
        const accessData = await durtService.getAccess();

        // Wait 2s to allow JCGO animation enough time to render
        await Promise.all([durtService.signin(accessData), Util.delay(2000)]);

        // If we are doing SSO with Android, then we need to show a continue button
        // to get user interaction in case the SSO redirect is using an App-Link
        if (this.context === 'sso' && this.durtExtType === extensionTypes.androidHttp) {
          // accessData.session is a JWT token
          const dust = JSON.parse(atob(accessData.session.split('.')[1]));
          this.durtInfo.email = dust?.user?.email;
          this.showGoContinueButton = true;
          return;
        }

        Util.redirect(this.sanitizedRedirectTo || userConsoleRootRoute);
      } catch (err: any) {
        const durtApiResponseValues = Object.values(durtApiResponseType);

        const parseError = (e: any): string => {
          if (durtApiResponseValues.includes(err.message)) {
            if (err.message === durtApiResponseType.userNotActive) {
              return durtApiResponseType.userNotActive;
            }
            return goError;
          }

          const message = get(e, 'response.data.message', loginError);
          try {
            const { text } = JSON.parse(message);
            return text;
          } catch {
            return message;
          }
        };

        this.durtErrorMessage = parseError(err);

        this.isGoLoading = false;
      }
    },
  },
});
