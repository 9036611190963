import { LoginConsts } from 'client/util/Constants';
import { get } from 'lodash';
import { pollFailureCondition, pollSuccessCondition } from 'client/util/verifyPushHelpers';
import MfaRequiredError from 'client/services/response/MfaRequiredError';
import RequestError from 'client/services/response/RequestError';
import WebAuthnService from 'client/services/webAuthnService';
import api from 'client/api';
import poll from 'client/containers/Login/poll';

const userApi = api.user;
const {
  pushStatusMessages,
  responseMessages,
} = LoginConsts;
const {
  mfaRequired,
  mfaUnavailableForPwdReset,
  passwordResetError,
} = responseMessages;

export default {
  async getDeviceCertCode(): Promise<any> {
    try {
      const res = await userApi.requestDeviceCode();
      return res.data.code;
    } catch {
      return null;
    }
  },

  /**
   * Remove properties with null values from info object to not
   * purposely send them in the payload
   * @param  {Object} info [flexible form object with possible null values]
   * @return {Object} [object used as 'payload' in userApi.resetPassword]
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  reformatInfo(info: any): any {
    return Object.entries(info).reduce((obj: any, [key, value]) => {
      const formattedObj = obj;
      if (value !== null) {
        formattedObj[key] = value;
      }
      return formattedObj;
    }, {}) as any;
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  resetPassword(code: string, info: any): Promise<any> {
    const formattedInfo = this.reformatInfo(info);

    return userApi.resetPassword(code, formattedInfo)
      .catch(this.handlePasswordResetError);
  },

  handlePasswordResetError(error: unknown): void {
    if (error instanceof Error) {
      const errorMessage = get(error, 'response.data.message', passwordResetError);
      const statusCode = get(error, 'response.status');

      // TODO: Determine if we can reuse `mfaRequired` error message for password
      // reset attempts with no available factors.
      // See https://github.com/TheJumpCloud/SI/blob/master/routes/webui/systemusers.js#L361-L369
      if (errorMessage === mfaRequired || errorMessage === mfaUnavailableForPwdReset) {
        const factors = get(error, 'response.data.factors', []);
        const challenge = get(error, 'response.data.challenge', undefined);
        const pwdResetToken = get(error, 'response.data.pwdResetToken', '');
        throw new MfaRequiredError(factors, challenge, pwdResetToken);
      }
      throw new RequestError(statusCode, errorMessage);
    } else {
      throw error;
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  verifyPushResetPassword(code: string, info: any): Promise<any> {
    const pollForResponse = () => new Promise((resolve, reject) => {
      const pollConfig = { delay: 1000 };

      poll(
        () => userApi.resetPassword(code, info)
          .then((response: { data: any}) => response.data)
          .catch((error: any) => {
            const errorMessageResponse = get(error, 'response.data.message');
            const status = get(error, 'response.status');
            const statusCode = get(error, 'response.data.status', '');
            const parsedMessage = get(pushStatusMessages, statusCode, '');
            const displayDuration = get(error, 'response.headers.retry-after');

            const errorMessage = parsedMessage
              || errorMessageResponse
              || passwordResetError;

            return {
              status, statusCode, displayDuration, errorMessage,
            };
          }),
        {
          successCondition: pollSuccessCondition,
          successHandler: resolve,
          failureCondition: pollFailureCondition,
          failureHandler: reject,
        },
        pollConfig,
      );
    });

    return pollForResponse()
      .catch((error: any) => {
        const errorMessage = get(error, 'errorMessage', passwordResetError);
        const status = get(error, 'status');
        const statusCode = get(error, 'statusCode');
        const displayDuration = get(error, 'displayDuration');

        throw new RequestError(status, errorMessage, statusCode, displayDuration);
      });
  },

  async verifyWebAuthnSignature(
    passwordResetInfo: PasswordResetInfo,
    challenge: Challenge,
  ): Promise<any> {
    try {
      const payload = await WebAuthnService.preparePasswordResetRequest(challenge);

      return await userApi.resetPassword(
        passwordResetInfo.confirmationCode,
        {
          ...passwordResetInfo,
          ...payload,
        },
      );
    } catch (error: unknown) {
      if (error instanceof RequestError) {
        // errors from WebAuthnService
        throw error;
      } else {
        // errors from userApi.resetPassword
        return this.handlePasswordResetError(error);
      }
    }
  },
};
