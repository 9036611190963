/* eslint class-methods-use-this: 0 */
import * as Constants from 'client/util/Constants';
import { UserRoutes } from 'client/routes/RouteGenerator';
import Api from 'client/api/Api';
import CsrfApi from 'client/api/CsrfApi';
import Util from 'client/util/Util';

import type { AxiosResponse } from 'axios';

const { xsrf, csrf } = Constants.default;
const { XhrConsts } = Constants;

export default class LoginApi extends Api {
  constructor(csrfType = csrf) {
    // The UserApi occasionally needs to use a csrfToken instead of an xsrf token
    if (csrfType === xsrf) {
      super(UserRoutes.xsrfUrl, XhrConsts.xXsrfToken);
    } else {
      super();
    }

    this.axios.interceptors.request.use(async (config) => {
      // TODO: Cache by csrfType instead of on the instance.
      const result = await CsrfApi.confirmCsrf(this.csrfUrl, this.csrfHeader, config, this.axios);
      return result instanceof Error ? Promise.reject(result) : Promise.resolve(result);
    }, (error: Error) => Promise.reject(error));

    // TODO: Get rid of this extra csrf, after Users stop using SI.
    //       For now, init SI CSRF for some User APIs
    if (csrfType === xsrf) {
      const deviceHost = UserRoutes.deviceCert();
      this.axios.interceptors.request.use(async (config) => {
        // UserConsole and deviceHost don't need the SI CSRF
        if (config.url?.indexOf('/userconsole/') === 0 || config.url?.indexOf(deviceHost) !== -1) {
          return Promise.resolve(config);
        }
        const result = await CsrfApi.confirmCsrf(csrf, XhrConsts.xCSRFToken, config, this.axios);
        return result instanceof Error ? Promise.reject(result) : Promise.resolve(result);
      }, (error) => Promise.reject(error));
    }

    this.axios.interceptors.response.use((response) => this.interceptRedirect(response));
  }

  // @todo: After axios upgrade the return type can be removed or replaced with the correct type
  // Return type set to `unknown` to get around type checking of interceptor
  // Response interceptor return value for axios < 1.x.x is expected to be void | Promise<void>
  // This method is written with axios >= 1.x.x syntax
  interceptRedirect(response: AxiosResponse<unknown, unknown>, redirect = Util.redirect): unknown {
    const url = response && response.headers[XhrConsts.xRedirectHeader];
    if (url) {
      redirect(url);
      // return a new empty promise to effectively stop the
      // existing promise chain and allow the redirect to happen
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise(() => {});
    }
    return response;
  }

  clearCsrfToken(): void {
    CsrfApi.clearCsrfToken(this.axios, this.csrfHeader);
  }
}
