import FeatureFlagsModule from 'client/stores/FeatureFlagsModule';
import LoginOauthAdminFlowModel from 'client/stores//LoginOauthAdminFlowModel';
import StepUpModel from 'client/stores/StepUpModel';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV.indexOf('dev') >= 0,
  modules: {
    FeatureFlagsModule,
    LoginOauthAdminFlowModel,
    StepUpModel,
  },
});
