<template>
  <Banner
    v-if="bannerType"
    :bannerText="bannerText"
    :bannerType="bannerType"
    learnMoreLink="https://status.jumpcloud.com/"
    learnMoreText="View status page"
  />
</template>

<script>
import { get } from 'lodash';
import ConfigService from 'client/util/initializationHandler/ConfigService';
import JCStatusApi from 'client/api/JCStatusApi';

import { LoginConsts } from 'client/util/Constants';
import Banner from 'client/components/Banner.vue';

const { clientType, loginRoutes } = LoginConsts;

export default {
  name: 'StatusBanner',

  components: {
    Banner,
  },

  JCStatusApi,

  data() {
    return {
      statuses: {
        admin: {},
        user: {},
      },
      activeBanner: {},
      bannerText: 'JumpCloud is experiencing issues that may impact login.',
    };
  },

  computed: {
    bannerType() {
      return get(this.activeBanner, 'indicator', '');
    },
    shouldCheckStatus() {
      return !!ConfigService.jcStatusUrl;
    },
  },

  created() {
    if (this.shouldCheckStatus) {
      this.getStatuses();
    }
  },

  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  methods: {
    async getStatuses() {
      try {
        const statuses = await this.$options.JCStatusApi.getStatusSummary();
        this.statuses = statuses;

        this.setActiveBanner();
        this.setObserver();
      } catch (e) {
        // don't update statuses on failure
      }
    },
    setObserver() {
      const target = document.querySelector('.login-container');
      this.observer = new MutationObserver(this.setActiveBanner);

      this.observer.observe(
        target,
        { attributes: true, subtree: true },
      );
    },
    setActiveBanner() {
      let userType = window.location.pathname === loginRoutes.admin
        ? clientType.admin
        : clientType.user;

      const searchParams = new URLSearchParams(window.location.search);
      const templateParam = searchParams.get('template');

      const adminTemplates = ['resetAdminPassword', 'resetPasswordAdminForm'];
      const userTemplates = [
        'chooseOS',
        'resetMacPassword',
        'resetPasswordUserForm',
        'resetUserPassword',
        'resetWindowsPassword',
      ];

      // This isn't ideal, but for some reason admin pwd reset routes
      // drop '/admin' from the url so we need an extra check here to
      // ensure the userType is correct
      if (adminTemplates.includes(templateParam)) {
        userType = clientType.admin;
      }

      if ([...adminTemplates, ...userTemplates].includes(templateParam)) {
        this.bannerText = 'JumpCloud is experiencing issues that may impact password resets.';
      }

      this.activeBanner = this.statuses[userType];
    },
  },
};
</script>
