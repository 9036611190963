<template>
  <Card :class="[$style.card, factorClassNames[className]]">
    <LoginLogo v-if="showCustomLogo" />
    <h1
      v-if="header"
      :class="$style.header"
    >
      {{ header }}
    </h1>

    <slot />
  </Card>
</template>

<script>
import Card from 'client/components/Card.vue';
import LoginLogo from 'client/components/LoginLogo.vue';

export default {
  name: 'LoginCard',

  components: {
    Card,
    LoginLogo,
  },

  props: {
    header: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    showCustomLogo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

@value (max-width-small) from 'css/breakpoints.css';

.card {
  border: 0;
  box-shadow: 0 0 2.875rem rgba(0, 0, 0, 6%);
  position: relative;
}

@media only screen and (max-width: max-width-small) {
  .card.card {
    box-shadow: unset;
  }
}
</style>

<style module="factorClassNames">
.push,
.totp,
.webauthn {
  box-shadow: none;
  padding: 0;
}
</style>
