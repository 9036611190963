<template>
  <div>
    <h1
      :class="$style.heading"
    >
      Set Up Verification Code (TOTP) MFA
    </h1>
    <div :class="$style.body">
      <div :class="$style.mainContent">
        <AdminTotpSetupStep
          :number="1"
          title="Download an Authenticator App"
        >
          <template slot="adminTotpSetupStepMessage">
            <p>
              To get set up, download <strong>JumpCloud Protect</strong> or one of our
              <a
                :class="[$style.textLink]"
                :href="qualifiedAppsUrl"
                rel="noopener noreferrer"
                target="_blank"
              >
                qualified authentication applications</a>
              on your mobile device.
            </p>
          </template>
        </AdminTotpSetupStep>
      </div>
      <aside :class="$style.linksCardContainer">
        <div :class="$style.linksCard">
          <a
            :class="$style.link"
            :href="androidPlayStoreUrl"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :class="$style.image"
              :src="androidPlayStoreImageSrc"
            >
          </a>
          <a
            :class="$style.link"
            :href="appleAppStoreUrl"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :class="$style.image"
              :src="appleAppStoreImageSrc"
            >
          </a>
        </div>
      </aside>
    </div>
    <div :class="$style.footer">
      <LoginActionButton
        :class="$style.actionButton"
        :action="goForward"
      >
        I Have An App
      </LoginActionButton>
    </div>
  </div>
</template>

<script>
import AdminTotpSetupConsts from 'client/containers/AdminTotpSetup/AdminTotpSetupConsts';
import AdminTotpSetupStep from 'components/AdminTotpSetupStep.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';

const {
  androidPlayStoreImageSrc,
  androidPlayStoreUrl,
  appleAppStoreImageSrc,
  appleAppStoreUrl,
  qualifiedAppsUrl,
} = AdminTotpSetupConsts;

export default {
  components: {
    AdminTotpSetupStep,
    LoginActionButton,
  },

  data: () => ({
    androidPlayStoreImageSrc,
    androidPlayStoreUrl,
    appleAppStoreImageSrc,
    appleAppStoreUrl,
    qualifiedAppsUrl,
  }),

  methods: {
    goForward() {
      this.$emit('onGoForward');
    },
  },
};

</script>

<style module>
@value (
  jcInformational,
  jcPrimary,
  jcPrimary50
) from 'css/brand-colors.css';
@value (max-width-large) from 'css/breakpoints.css';

.heading {
  font-size: 1.4rem;
}

.body {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: var(--spacing) auto;
}

.mainContent {
  flex: 1;
}

.textLink {
  color: jcInformational;
  text-decoration: none;
  transition: background-color 0.2s;
}

.textLink:hover {
  text-decoration: underline;
}

.linksCardContainer {
  flex: 1;
  height: 16rem;
}

.linksCard {
  align-items: center;
  background-color: jcPrimary50;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.link {
  height: 4.5em;
  margin: 0.5em 0;
  max-width: 15em;
}

.image {
  height: 100%;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.actionButton {
  margin: 0;
  width: 12rem;
}

@media (max-width: max-width-large) {
  .body {
    flex-direction: column;
  }

  .linksCard {
    padding: 1rem 0;
  }

  .actionButton {
    flex: 1;
  }
}

</style>
