<template>
  <div :class="[$style.banner, $style[bannerType]]">
    <div :class="$style.bannerInnerContent">
      <warningIcon
        v-if="bannerText"
        :class="$style.icon"
      />
      <p :class="$style.bannerText">
        {{ bannerText }}
        <a
          v-if="learnMoreLink"
          :class="$style.learnMoreLink"
          :href="learnMoreLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ learnMoreText }}
          <externalLinkIcon :class="$style.externalLinkIcon" />
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import externalLinkIcon from 'public/img/icons/icon-external-link.svg?inline';
import warningIcon from 'public/img/icons/icon-error-no-fill.svg?inline';

const types = ['warning', 'error'];

export default {
  name: 'Banner',

  components: {
    externalLinkIcon,
    warningIcon,
  },

  props: {
    bannerText: {
      type: String,
      default: '',
    },
    bannerType: {
      type: String,
      default: 'warning',
      validator: (type) => types.includes(type),
    },
    learnMoreLink: {
      type: String,
      default: '',
    },
    learnMoreText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style module>
@value (max-width-medium) from 'css/breakpoints.css';
@value (
  jcError50,
  jcError900,
  jcGrey800,
  jcInformational900,
  jcWarning50,
  jcWarning900
) from 'css/brand-colors.css';

.banner {
  --jcBody: 14px;

  align-items: center;
  color: jcGrey800;
  display: flex;
  justify-content: center;
  padding: 1em 0;
}

.bannerInnerContent {
  align-items: center;
  display: flex;
  font-size: var(--jcBody);
  max-width: 90%;
}

.bannerText {
  font-size: 1em;
  margin: 0;
}

.warning {
  background-color: jcWarning50;
}

.error {
  background-color: jcError50;
}

.externalLinkIcon {
  margin-left: 0.25em;
}

.externalLinkIcon path {
  fill: jcGrey800;
}

.icon {
  margin-right: 0.9em;
}

.warning .icon path {
  fill: jcWarning900;
}

.error .icon path {
  fill: jcError900;
}

.learnMoreLink {
  border-bottom: 1px solid;
  color: jcGrey800;
  font-weight: 600;
  text-decoration: none;
}

.learnMoreLink:hover {
  border-bottom: 1px solid;
  color: jcInformational900;
}

.learnMoreLink:hover .externalLinkIcon path {
  fill: jcInformational900;
}

@media (max-width: max-width-medium) {
  .bannerText {
    width: 90%;
  }

  .icon {
    margin-right: 0;
    width: 10%;
  }
}
</style>
