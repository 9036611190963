<template>
  <div :class="$style.formContainer">
    <transitionGroup
      :enterClass="$style.fadeEnter"
      :enterActiveClass="$style.fadeEnterActive"
      :leaveActiveClass="$style.fadeLeaveActive"
      :leaveToClass="$style.fadeLeaveTo"
    >
      <LoginCard
        v-if="!success"
        key="form"
        header="Register Your Account"
      >
        <LoginAlert :message="errorMessage" />

        <form>
          <LoginTextField
            :autocomplete="false"
            label="First Name"
            name="firstName"
            placeholder="First Name"
            :isEditable="!readOnly"
            :rules="{ required: true }"
            :submitOnEnter="submit"
            v-model="form.firstName"
            vvName="first name"
          />

          <LoginTextField
            :autocomplete="false"
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            :isEditable="!readOnly"
            :rules="{ required: true }"
            :submitOnEnter="submit"
            v-model="form.lastName"
            vvName="last name"
          />

          <LoginTextField
            :autoFocus="true"
            :autocomplete="false"
            label="Password"
            name="password"
            placeholder="Password"
            type="password"
            :rules="{ max: 64, required: true }"
            :submitOnEnter="submit"
            v-model="form.password"
            vvName="password"
          />

          <LoginTextField
            :autocomplete="false"
            label="Confirm Password"
            name="confirm"
            placeholder="Confirm Password"
            :submitOnEnter="submit"
            :rules="{ max: 64, required: true }"
            type="password"
            v-model="form.confirm"
            vvName="confirm password"
          />

          <LoginActionButton
            :action="submit"
            :isLoading="isLoading"
          >
            Register
          </LoginActionButton>
        </form>
      </LoginCard>

      <div
        key="confirmation"
        v-if="success"
      >
        <h2 :class="$style.header">
          Thank You
        </h2>
        <h5 :class="$style.subheader">
          <div>Account activation successful.</div>
          <div>You may now log in to the JumpCloud User Portal.</div>
        </h5>
        <LoginActionButton
          :action="continueAction"
        >
          continue to login
        </LoginActionButton>
      </div>
    </transitionGroup>

    <LogoIcon />
  </div>
</template>

<script>
import { LoginConsts } from 'client/util/Constants';
import ConfirmValidation from 'client/components/mixins/ConfirmValidation';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginAlert from 'client/components/LoginAlert.vue';
import LoginCard from 'client/components/LoginCard.vue';
import RouteGenerator, { UserRoutes } from 'client/routes/RouteGenerator';
import api from 'client/api';
import loginUtil from 'client/components/mixins/loginUtil';

const { login } = RouteGenerator;
const { userRegistrationError } = LoginConsts.responseMessages;

export default {
  name: 'UserRegistration',

  mixins: [loginUtil, ConfirmValidation],

  components: {
    LoginAlert,
    LoginActionButton,
    LoginCard,
  },

  props: {
    // Sent in the url parameters, required for activate user request
    requestKey: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    success: false,

    form: {
      firstName: '',
      lastName: '',
      password: '',
      confirm: '',
      preserve: 'on',
    },
  }),

  computed: {
    readOnly() {
      return this.$route.query.readOnly === 'true';
    },
  },

  created() {
    this.form.firstName = this.$route.query.firstName;
    this.form.lastName = this.$route.query.lastName;
  },

  methods: {
    request() {
      api.user.activate(this.form, this.requestKey)
        .then((res) => {
          const { email, totpKey } = res.data;
          if (email && totpKey) {
            this.redirect(UserRoutes.setupMFA(totpKey, email));
          } else if (res.status === 200) {
            this.success = true;
          }
        }).catch((error) => {
          let message;
          const { response } = error;
          if (response) {
            ({ message } = response.data);
          }
          this.errorMessage = message || userRegistrationError;
        }).finally(() => {
          this.isLoading = false;
        });
    },

    continueAction() {
      this.redirect(login);
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

@value (delayedOpacity, fadeTransition) from 'css/transitions.css';

/* transition */
.fadeEnterActive {
  transition: delayedOpacity;
}

.fadeLeaveActive {
  transition: fadeTransition;
}

.fadeEnter,
.fadeLeaveTo {
  opacity: 0;
}
</style>
