import axios from 'axios';

import ConfigService from 'client/util/initializationHandler/ConfigService';
import fromApi from 'client/services/statusPageFormatting';

class JCStatusApi {
  // eslint-disable-next-line class-methods-use-this
  async getStatusSummary() {
    const url = `${ConfigService.jcStatusUrl}/api/v2/summary.json`;
    const response = await axios.get(url);

    return fromApi.getStatuses(response.data);
  }
}

export default new JCStatusApi();
