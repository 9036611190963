<template>
  <div>
    <h1 :class="$style.heading">
      We noticed you don't have a {{ provider }} account connected.
    </h1>
    <p :class="$style.subHeading">
      The information provided does not appear to be associated with a current organization.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Heading',

  props: {
    provider: {
      type: String,
      required: true,
    },
  },
};
</script>

<style module scoped>
.heading {
  font-family: Source Serif Pro, serif;
  font-size: 2.5rem;
  letter-spacing: 1px;
}

.subHeading {
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.7px;
}
</style>
