<!-- Delete once component library can be imported -->
<template>
  <div
    :class="[$style.checkboxWrapper, {
      [$style.checkboxDisabled]: isDisabled
    }]"
  >
    <input
      :id="id"
      type="checkbox"
      :disabled="isDisabled"
      v-model="inputValue"
    >
    <label
      :class="$style.checkboxLabel"
      :for="id"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import Util from 'client/util/Util';

export default {
  name: 'Checkbox',

  props: {
    id: {
      type: String,
      default: Util.getUniqueId(),
    },

    label: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style module>
.checkboxWrapper {
  align-items: center;
  display: flex;
}

.checkboxLabel {
  margin-left: 0.5rem;
}

.checkboxDisabled {
  color: var(--jcGrey400);
}
</style>
