export default class PushError extends Error {
  status: string;

  pushId: string;

  constructor(status: string, pushId: string) {
    super();
    this.status = status;
    this.pushId = pushId;
  }
}
