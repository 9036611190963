import { UserRoutes, getOrigin } from 'client/routes/RouteGenerator';
import Constants from 'client/util/Constants';
import LoginApi from 'client/api/LoginApi';
import LoginConsts from 'client/util/Constants/LoginConstants';
import Util from 'client/util/Util';
import axios from 'axios';
import loginStore from 'client/stores/loginStore';

const { userConsoleRootRoute } = LoginConsts;
const { user } = LoginConsts.clientType;
class UserLoginApi extends LoginApi {
  constructor(_userRoutes = UserRoutes) {
    super(Constants.xsrf);
    this.authDuoUrl = `${getOrigin()}/userconsole/auth/duo`;
    this.UserRoutes = _userRoutes;
    // TEMP: Use Duo auth server running locally
    // this.authDuoUrl = 'http://localhost:8005/auth/duo';
  }

  /**
   * All POSTs to verify a factor must include the state ID if it is available.
   * If the state ID is available and is not included then users who are in a
   * "Stepup Auth Event" will not be able to successfully authenticate.
   *
   * @param {string} url
   * @param {object} payload
   * @returns Axios POST promise
   * @memberof UserLoginApi
   */
  mfaVerificationPost(url, payload = {}, store = loginStore) {
    const stateId = store.getters['StepUpModel/stateId'];
    const request = payload;

    if (stateId) {
      // state is specific to sso step up and should be cleared after step up failure
      // if this is in the payload for regular login, it isn't being cleared correctly
      request.state = stateId;
    }

    return this.axios.post(url, request);
  }

  /**
   * Activate a user
   * @param  {Object} payload [firstName, lastName, password, confirm, preserve]
   * @return {Object}         [returns the promise from the axios request]
   */
  activate(payload, key) {
    return this.axios.post(`/activate/${key}`, payload);
  }

  /**
   * Primary authentication for a user.
   * @param  {Object} payload [email, password]
   * @return {AxiosPromise} [returns the promise from the axios request]
   */
  login(payload) {
    const path = LoginConsts.authURLs[user];
    return this.axios.post(path, payload);
  }

  /**
   * Email verification for a user.
   * @param  {Object} payload [email]
   * @return {AxiosPromise} [returns the promise from the axios request]
   */
  submitEmail(payload) {
    const path = LoginConsts.authURLs.submitEmail;
    return this.axios.post(path, payload);
  }

  /**
   * Authentication for a DURT session.
   * @param  {Object} payload [sessionToken, sessionTokenCode]
   * @return {AxiosPromise} [returns the promise from the axios request]
   */
  loginDURT(payload) {
    const path = LoginConsts.authURLs.durt;
    return this.mfaVerificationPost(path, payload);
  }

  /**
   * Logout of a DURT session.
   * @return {AxiosPromise} [returns the promise from the axios request]
   */
  logoutDURT() {
    const path = LoginConsts.authURLs.durt;
    return this.axios.delete(path);
  }

  /**
   * MFA authentication for a user.
   *
   * @TODO This endpoint should be renamed "/auth/totp". As of December 2019, there are multiple
   * methods for MFA.
   * @param  {Object} payload [otp: token to be verified]
   * @return {AxiosPromise} [returns the promise from the axios request]
   */
  verifyTotp(payload) {
    return this.mfaVerificationPost(`${userConsoleRootRoute}/auth/totp`, payload);
  }

  /**
  * Requests for a password change. Upon success the user will
  * receive an email.
  * @param  {Object} payload [contains email that will receive the email]
  * @return {AxiosPromise} [returns the promise from the axios request]
  */
  requestPasswordReset(payload) {
    return this.axios.post('/changepassword', payload);
  }

  requestDeviceCode() {
    return this.axios.post(
      `${this.UserRoutes.deviceCert()}auth/code`,
      undefined,
      { withCredentials: true },
    );
  }

  getDuoAuth() {
    return this.axios.get(this.authDuoUrl);
  }

  /**
   * Request a user's data.
   * @return {Object} [returns the promise from the axios request]
   */
  // eslint-disable-next-line class-methods-use-this
  getUser() {
    // not using this.axios because we don't want the xsrf
    return axios.get(`${userConsoleRootRoute}/api/self`);
  }

  /**
  * Resets a user's password. Upon success the user will receive an email.
  * @param  {String} key [the confirmation key for resetting the password]
  * @param  {Object} payload
  * @param  {String} payload.password [The user's password]
  * @param  {String} payload.confirm [The user's password again]
  * @return {AxiosPromise} [returns the promise from the axios request]
  */
  resetPassword(key, payload) {
    return this.axios.post(`/changepassword/${key}`, payload);
  }

  validateDeviceCert() {
    // This POST does not send any data, hence undefined. null below would send
    // a body with a content-type and there's no reason for us to do this with
    // this API.
    // not using this.axios because we don't want the xsrf
    return axios.post(
      `${this.UserRoutes.deviceCert()}auth`,
      undefined,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Get the challenge used for WebAuthn Authentication
   */
  getWebAuthnChallenge() {
    return this.axios.get(`${userConsoleRootRoute}/auth/webauthn`)
      .then((resp) => resp.data);
  }

  /**
   * Posts the WebAuthn signed credential for verification.
   */
  postWebAuthnSignature({ publicKeyCredential, token }) {
    return this.mfaVerificationPost(`${userConsoleRootRoute}/auth/webauthn`, { publicKeyCredential, token })
      .then((resp) => resp.data);
  }

  /**
   * Posts the Duo signature and token to verify
   * @param  {Object} payload [contains the signature request and token to be verified]
   */
  postDuoSignature(payload) {
    return this.mfaVerificationPost(`${this.authDuoUrl}`, payload);
  }

  postDuoChallengeUrl(payload) {
    return this.mfaVerificationPost(`${this.authDuoUrl}/oauth/challenge`, payload);
  }

  oauthPostChangeRequest() {
    const redirectToParam = Util.getQueryStringParameter('redirectTo');
    const redirectToUrl = new URL(redirectToParam);
    const requestParams = Util.getQueryStringParameter('request_params', redirectToUrl.search);
    const url = `/userconsole/oauth2/change-identity/?request_params=${requestParams}`;

    // request interceptor will automagically send user to login with URL returned in header
    return this.axios.post(url, {});
  }
}

export default new UserLoginApi();
export const UserLoginApiHelper = UserLoginApi;
