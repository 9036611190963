import { defineStore } from 'pinia';
import AdminLoginApi from 'client/api/AdminLoginApi';

export interface ResetPasswordState {
  password: string,
  confirm: string,
  license: boolean,
  email: string,
  confirmationCode: string,
  otp: string
}

export default defineStore('resetPassword', {
  state: (): ResetPasswordState => ({
    password: '',
    confirm: '',
    license: false,
    email: '',
    confirmationCode: '',
    otp: '',
  }),
  actions: {
    async commitEntireStateChange(newState: ResetPasswordState) {
      this.$state = newState;
    },

    /**
     * Attempt to verify user-provided data to reset user's password
     *
     * @param {context.state} ResetPassword
     * @returns {Promise.resolve} The user has changed their password
     * {Promise.reject} General network Error
     * {Promise.reject} Status Code 401 and data: totpKey means TOTP verification required
     * before password and TOTP is reset
     */
    verifyResetPassword() {
      return AdminLoginApi.resetPassword(this.$state);
    },

    /**
     * Attempt to verify user-provided data to reset user's password
     *
     * @param {context.state} ResetPassword
     * @returns {Promise.resolve} The user has changed their password
     * {Promise.reject} General network Error
     * {Promise.reject} Status Code 401 and data: totpKey means TOTP verification required
     * before password and TOTP is reset
     */
    verifyResetTOTP() {
      return AdminLoginApi.verifyMfaResetTOTP(this.$state);
    },
  },
});
