
// Libraries
import { defineComponent } from 'vue';

// Components
import WebAuthnView from 'client/containers/user/Views/WebAuthnView.vue';

// Services
import userLoginService from 'client/services/userLoginService';

export default defineComponent({
  name: 'WebAuthnUserLoginHander',

  components: {
    WebAuthnView,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async handleAuth(): Promise<void> {
      this.isLoading = true;
      this.$emit('resetErrorMessage');

      try {
        await userLoginService.verifyWebAuthnSignature();

        this.$emit('success');
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
