<template>
  <div>
    <img
      alt="rocket"
      src="~/public/img/icons/icon-rocket.svg"
    >
    <span>Securing Encrypted Session</span>
  </div>
</template>

<script>
export default {
  name: 'JCGoSecureStep',
};
</script>

<style scoped module>
img {
  margin-right: 0.25rem;
  transform: rotateZ(45deg);
}
</style>
