import api from 'client/api';

const adminApi = api.admin;

interface AdminForm {
  email: string;
  shouldRememberEmail: boolean;
}

export default {
  /** Email methods */
  generatePayload(credentials: AdminForm): {email: string} {
    const { email } = credentials;

    return {
      email,
    };
  },

  submitEmail(payload: { email: string, redirectTo?: string }): Promise<any> {
    return adminApi.submitEmail(payload);
  },
};
