// Libraries
import { find } from 'lodash';

// Constants
import { LoginConsts } from 'client/util/Constants';
import { PushAuthenticationStatus } from 'client/api/PushMfaAuthApi';
import MfaConstants from 'client/containers/Login/MfaConstants';

// Service Error
import RequestError from 'client/services/response/RequestError';

const { pushStatusMessages, responseMessages } = LoginConsts;
const {
  invalidCsrf,
  mfaExpired,
  passwordResetError,
  passwordResetErrorLimit,
} = responseMessages;

const { mfaNames: { duo, push, webauthn } } = MfaConstants;

interface RedirectRule {
  key: string,
  check: () => boolean,
  action?: 'restart' | 'exitToLogin',
  displayDuration?: number,
  message: string,
}

// eslint-disable-next-line max-len
const getRedirectRule = (mfaType: string, error: RequestError | unknown):Partial<RedirectRule> => {
  if (error instanceof RequestError) {
    const redirectRules: RedirectRule[] = [
      {
        key: 'invalidCsrf', // session timeout
        check: () => error.status === 403 && error.message === invalidCsrf,
        action: 'restart',
        message: mfaExpired,
      },
      {
        key: 'maxAttempts', // max mfa attempts
        check: () => error.status === 403 && error.message !== invalidCsrf,
        action: 'exitToLogin',
        message: passwordResetErrorLimit,
      },
      {
        key: 'challengeTimeout', // duo/webauthn 5 minute challenge timeout
        check: () => (
          (mfaType === duo || mfaType === webauthn)
          && error.status === 401
        ),
        action: 'restart',
        message: mfaExpired,
      },
      {
        key: 'badRequest', // pwd doesn't meet org reqs
        check: () => error.status === 400,
        action: 'restart',
        message: error.message,
      },
      {
        key: 'pushDenied', // push login attempt failure
        check: () => (
          mfaType === push
          && error.statusCode === PushAuthenticationStatus.DENIED
        ),
        action: 'exitToLogin',
        message: pushStatusMessages.pwdResetDenied,
      },
      {
        key: 'default',
        check: () => true,
        message: error.message,
        displayDuration: error?.displayDuration,
      },
    ];

    return find(redirectRules, (rule) => rule.check()) as RedirectRule;
  }
  return { message: passwordResetError };
};

export default getRedirectRule;
