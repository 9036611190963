<template>
  <img
    v-if="showLogo"
    alt="User Portal"
    :src="logoUrl"
    :class="$style.logo"
    @error="imageLoadError"
  >
</template>

<script>
import { LocalStorageConsts } from 'client/util/Constants';
import LocalStorageService from 'client/util/LocalStorageService';

const { orgLogoUrl } = LocalStorageConsts;

export default {
  name: 'LoginLogo',
  data() {
    return {
      logoUrl: '',
    };
  },

  computed: {
    showLogo() {
      return !!this.logoUrl;
    },
  },

  mounted() {
    this.getLogo();
  },

  LocalStorageService,

  methods: {
    imageLoadError() {
      this.logoUrl = '';
    },

    getLogo() {
      let imageUrl;

      if (this.$route) {
        const { logoUrl: logoUrlRaw } = this.$route.query;
        // vue router should decode this, but will make sure
        const logoUrl = decodeURIComponent(logoUrlRaw || '');

        // logo can be saved in /api/v2/logos in local, staging and potentially production
        const currentDomain = window.location.origin;
        const isInCurrentDomain = logoUrl.startsWith(currentDomain);

        try {
          // check for jumpcloud subdomains (assets) in production
          const urlLogo = new URL(logoUrl);

          const isValidLogoUrl = urlLogo.protocol === 'https:' && (
            isInCurrentDomain || urlLogo.hostname.endsWith('.jumpcloud.com')
          );
          if (isValidLogoUrl) {
            imageUrl = logoUrl;
          }
        } catch (e) {
          // catch and do nothing
        }
      }

      if (imageUrl) {
        this.$options.LocalStorageService.setItem(orgLogoUrl, imageUrl);
        this.logoUrl = imageUrl;
      } else {
        this.logoUrl = this.$options.LocalStorageService.getValue(orgLogoUrl);
      }
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';
</style>
