<template>
  <button
    :type="type"
    :class="[$style.button, buttonTypeClass]"
    @click.prevent="handleClick"
    :disabled="disabled"
  >
    <span
      :class="{
        [$style.fadeTransition]: true,
        [$style.fadeTo]: isLoading
      }"
    >
      <slot>default text</slot>
    </span>
    <transition
      :enterClass="$style.fadeTo"
      :enterActiveClass="$style.fadeTransition"
      :leaveActiveClass="$style.fadeTransition"
      :leaveToClass="$style.fadeTo"
    >
      <div
        v-if="isLoading"
        :class="$style.loadingIconContainer"
      >
        <LoadingIcon />
      </div>
    </transition>
  </button>
</template>

<script>
import { LoginConsts } from 'client/util/Constants';
import LoadingIcon from 'components/LoadingIcon.vue';

const { buttonTypes } = LoginConsts;

export default {
  name: 'LoginActionButton',

  components: {
    LoadingIcon,
  },

  props: {
    action: {
      type: Function,
      required: true,
    },

    disabled: Boolean,

    buttonType: {
      type: String,
      default: buttonTypes.success,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isSecondary: Boolean,

    type: {
      type: String,
      default: 'button',
    },
  },

  computed: {
    buttonTypeClass() {
      if (this.buttonType === buttonTypes.error) {
        return this.$style.errorButton;
      }

      if (this.buttonType === buttonTypes.chooseOS) {
        return this.$style.chooseOsButton;
      }
      if (this.isSecondary) {
        return this.$style.successButtonSecondary;
      }
      return this.$style.successButtonPrimary;
    },
  },

  methods: {
    handleClick() {
      if (!this.isLoading) {
        this.action();
      }
    },
  },
};

</script>

<style module>
@value (jcGrey800, jcError, jcPrimary, jcGrey300) from 'css/brand-colors.css';
@value (panelSpeedSm, panelTimingFunction) from 'css/transitions.css';
@value mediumShadow from 'css/shadows.css';

.button {
  border-radius: 2px;
  color: jcGrey800;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  margin: 1.5rem 0;
  opacity: 1;
  outline: none;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100%;
}

.successButtonPrimary {
  background: jcPrimary;
  border: 2px solid transparent;
}

.button[disabled] {
  background-color: jcGrey300;
  border: 2px solid transparent;
}

.successButtonSecondary {
  background: none;
  border: 1px solid currentColor;
}

.chooseOsButton {
  background: jcPrimary;
  border: 2px solid transparent;
  display: block;
  margin: 2rem auto;
  text-align: left;
  width: 12rem;
}

.errorButton {
  background: jcError;
  border: 2px solid transparent;
}

.successButtonSecondary:disabled {
  border: 1px solid transparent;
  color: rgba(146, 153, 158, 1);
}

.button:hover:not([disabled]) {
  box-shadow: inset 0 0 30px 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.loadingIconContainer {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.5em;
}

.fadeTransition {
  transition: opacity 0.2s ease-in-out;
}

.fadeTo {
  opacity: 0;
}

</style>
