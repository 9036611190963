
// Libraries
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';

// Components
import DuoRedirectView from 'client/containers/user/Views/DuoRedirectView.vue';

// Store
import UserLoginStore from 'client/stores/UserLoginStore';

// Services
import LocalStorageService from 'client/util/LocalStorageService';
import userLoginService from 'client/services/userLoginService';

// Utils
import Util from 'client/util/Util';

// Constants
import { LocalStorageConsts } from 'client/util/Constants';
import MfaConstants from 'client/containers/Login/MfaConstants';

const { previousMfaMethod, userLoginEmail } = LocalStorageConsts;
const { mfaNames } = MfaConstants;

export default defineComponent({
  name: 'DuoRedirectHandler',

  components: {
    DuoRedirectView,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  props: {
    controllingQueryParams: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapStores(UserLoginStore),
  },

  methods: {
    async handleDuoRedirect(): Promise<void> {
      try {
        this.$emit('resetErrorMessage');
        this.isLoading = true;

        const { email, shouldRememberEmail } = this.userLoginStore.$state;
        const { context, redirectTo } = this.controllingQueryParams;

        const isSso = context === 'sso';
        const isOauth = context === 'oauth';
        const shouldUseRedirect = isSso || isOauth;

        const payload = shouldUseRedirect && redirectTo ? { redirectTo } : undefined;
        const url = await userLoginService.getDuoRedirectUrl(payload);
        // Should NOT emit success event here b/c success/fail will happen
        // on Duo's page via the Universal Prompt

        // Need to set last factor && user email here b/c success callback is delegated to Duo now
        LocalStorageService.setItem(previousMfaMethod, mfaNames.duo);
        if (shouldRememberEmail) {
          LocalStorageService.setItem(userLoginEmail, email);
        }
        // Timeout is arbitrary, but helps reduce jumpy UI experience
        setTimeout(() => Util.redirect(url), 1000);
      } catch (error) {
        this.$emit('error', error);
        this.isLoading = false;
      }
    },
  },
});
