
import { LocalStorageConsts } from 'client/util/Constants';
import { defineComponent } from 'vue';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import DuoRedirectView from 'client/containers/user/Views/DuoRedirectView.vue';
import LocalStorageService from 'client/util/LocalStorageService';
import Util from 'client/util/Util';

const { userPwdResetInfo } = LocalStorageConsts;

export default defineComponent({
  name: 'DuoUserResetPasswordRedirectHandler',

  components: {
    DuoRedirectView,
  },

  setup() {
    const uiLabel = useMfaLabels();
    return { uiLabel };
  },

  data() {
    return {
      isLoading: false,
    };
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    requestKey: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleDuoRedirect(): void {
      try {
        this.$emit('resetErrorMessage');
        this.isLoading = true;

        Util.redirect(this.form.challenge.duo.location);
        // Add pwd reset data here so it's available when Duo
        // redirects back to /login/duo/oauth/callback
        // See DuoCallbackHandler.vue
        LocalStorageService.setItem(userPwdResetInfo, JSON.stringify({
          confirm: this.form.confirm,
          password: this.form.password,
          pwdResetToken: this.form.pwdResetToken,
          requestKey: this.requestKey,
        }));
      } catch {
        this.$emit('error', this.uiLabel.duo.error.redirect);
        this.isLoading = false;
      }
    },
  },
});
