import { LocalStorageConsts } from 'client/util/Constants';
import { webAuthnSupported } from 'client/util/FeatureSupport';
import LocalStorageService from 'client/util/LocalStorageService';
import MfaConstants from 'client/containers/Login/MfaConstants';
import type { MfaFactor } from 'client/types/Auth';

const { webauthn } = MfaConstants.mfaNames;
const { previousMfaMethod } = LocalStorageConsts;

export default function getLastUsedFactor(
  factors: MfaFactor[],
): { mfaType?: string } {
  let lastFactor = factors.find(
    (factor) => factor.name === LocalStorageService.getItem(previousMfaMethod),
  );

  // WebAuthn edge cases
  // If webauthn is last factor and is no longer supported there are three paths:
  // 1) if it's the only factor redirect anyway
  // 2) if one other factor is available then redirect to it
  // 3) if multiple other factors are available then stay on /choosemfa
  if (
    lastFactor
      && lastFactor.name === webauthn
      && !webAuthnSupported()
  ) {
    const remainingFactors = factors.filter(
      (factor) => factor.name !== webauthn,
    );

    lastFactor = remainingFactors.length === 1
      ? remainingFactors[0]
      : undefined;
  }

  if (lastFactor === undefined) {
    LocalStorageService.removeItem(previousMfaMethod);
  }

  const factor = factors.length === 1
    ? factors[0]
    : lastFactor;

  return factor?.name ? { mfaType: factor.name } : {};
}
