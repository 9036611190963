
import { defineComponent } from 'vue';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';

// Components
import LoadingIcon from 'components/LoadingIcon.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';

export default defineComponent({
  name: 'PushView',

  components: {
    LoadingIcon,
    LoginActionButton,
  },

  props: {
    hasPendingErrorMessage: Boolean,
    isDisabled: {
      type: Boolean,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
  },

  setup() {
    const uiLabel = useMfaLabels();
    return { uiLabel };
  },

  computed: {
    buttonText(): string {
      const { enabled, disabled } = this.uiLabel.push.button;

      return this.isDisabled ? disabled : enabled;
    },
  },
});
