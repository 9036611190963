<template>
  <ResetPassword
    clientType="user"
    :form="form"
    :isLoading="isLoading"
    :submit="submit"
  />
</template>

<script>
// Components
import ResetPassword from 'client/components/forms/ResetPassword.vue';

// Services
import userResetPasswordService from 'client/services/userResetPasswordService';

export default {
  name: 'UserResetPasswordInputHandler',

  components: {
    ResetPassword,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    requestKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async submit() {
      this.isLoading = true;
      this.$emit('resetErrorMessage');
      // eslint-disable-next-line vue/no-mutating-props
      this.form.deviceCode = await userResetPasswordService.getDeviceCertCode();

      try {
        await userResetPasswordService.resetPassword(this.requestKey, this.form);
        this.$emit('success');
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style module>
@import '../../../css/login-components.css';
</style>
