/**
 * This gets the values components with credential inputs using vanilla js because browsers with
 * autofill fail to update the vue local state.
 */
export default {
  methods: {
    getCredentialsPayload() {
      const inputs = this.$el.getElementsByTagName('input');
      const email = inputs[0];
      const password = inputs[1];
      const event = document.createEvent('HTMLEvents');
      event.initEvent('input', false, true);
      // dispatch the input event to trigger an update on local vue state for error handling
      email.dispatchEvent(event);
      password.dispatchEvent(event);
      return { email: email.value, password: password.value };
    },
  },
};
