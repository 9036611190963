<template>
  <div
    :class="$style.resetPassword"
  >
    <span
      :class="[
        $style.fadeTransition,
        $style.resetTotpContainer,
        { [$style.fadeTo]: isLoadingReset }
      ]"
    >
      <button
        :class="{
          [$style.resetPasswordLink]: true,
        }"
        aria-label="Reset MFA or Enroll (opens a new page)"
        @click="handleResetTOTP"
      >
        Click Here
      </button>To Reset MFA Or Enroll
    </span>
    <transition
      :enterClass="$style.fadeTo"
      :enterActiveClass="$style.fadeTransition"
      :leaveActiveClass="$style.fadeTransition"
      :leaveToClass="$style.fadeTo"
    >
      <div
        v-if="isLoadingReset"
        :class="$style.loadingIconContainer"
      >
        <LoadingIcon />
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingIcon from 'components/LoadingIcon.vue';

export default {
  data: () => ({
    isLoadingReset: false,
  }),

  components: {
    LoadingIcon,
  },

  methods: {
    handleResetTOTP(e) {
      this.isLoadingReset = true;
      this.$emit('onRequestReset', e);
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

.resetPassword {
  position: relative;
}

.resetTotpContainer {
  font-size: 0.875rem;
}

.loadingIconContainer {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.5em;
}

.fadeTransition {
  transition: opacity 0.2s ease-in-out;
}

.fadeTo {
  opacity: 0;
}
</style>
