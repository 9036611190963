<template>
  <button
    :class="[$style.topLevelNavigation, $style.swapClientTypeButton]"
    data-automation="loginSwapClient"
    @click="clickHandler"
  >
    <slot />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" :class="$style.swapClientTypeIcon" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M10.96 9.715H0V7.116h11.091L6.812 2.838 8.65 1l6.431 6.432.919.918-.919.919-6.431 6.432-1.838-1.838z"/></svg>
  </button>
</template>
<script>
export default {
  name: 'ButtonLoginSwitch',

  props: {
    clickHandler: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

@value (jcGrey800, jcInformational900, jcPrimary) from 'css/brand-colors.css';
@value (max-width-small) from 'css/breakpoints.css';

.topLevelNavigation {
  background-color: transparent;
  color: jcGrey800;
  cursor: pointer;
  display: block;
  font-weight: 400;
  text-decoration: none;
}

.topLevelNavigation:hover {
  color: jcPrimary;
}

.swapClientTypeButton {
  border: 0;
  grid-area: button;
  outline: none;
  padding: 0;
}

.swapClientTypeIcon {
  fill: currentColor;
  height: 1em;
  margin: 0 0.5rem;
  vertical-align: middle;
  width: 1em;
}

.swapClientTypeIcon:focus {
  outline: none;
}

@media only screen and (max-width: max-width-small) {
  .topLevelNavigation {
    color: jcInformational900;
    font-size: 12px;
    margin-left: auto;
    padding-left: 1rem;
    padding-top: 1rem;
  }
}
</style>
