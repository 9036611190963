
import { defineComponent } from 'vue';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import TotpInput from 'client/components/TotpInput.vue';
import userResetPasswordService from 'client/services/userResetPasswordService';

export default defineComponent({
  name: 'TotpUserResetPasswordHandler',

  components: {
    TotpInput,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    requestKey: {
      type: String,
      required: true,
    },
  },

  setup() {
    const uiLabel = useMfaLabels();
    return { uiLabel };
  },

  data() {
    return {
      token: '',
      hasAlreadySubmitted: false,
      isLoading: false,
      childInterface: {
        clearInput: () => {},
      },
    };
  },

  methods: {
    clearInput() {
      this.childInterface.clearInput();
    },

    getChildInterface(childInterface: { clearInput: () => void }) {
      this.childInterface = childInterface;
    },

    async submit() {
      this.isLoading = true;
      try {
        await userResetPasswordService.resetPassword(
          this.requestKey,
          {
            ...this.form,
            otp: this.token,
          },
        );
        this.$emit('success');
      } catch (error) {
        // check that displays a manual submit button
        this.hasAlreadySubmitted = true;
        this.$emit('error', error);
        this.clearInput();
      } finally {
        this.isLoading = false;
      }
    },
  },
});
