
// Libraries
import { PropType, defineComponent } from 'vue';

// Components
import MfaChooser from 'client/containers/user/MfaComponents/MfaChooser.vue';

// Constants
import { useMfaLabels } from 'client/util/Constants/MfaLabels';

// Types
import type { MfaFactor } from 'client/types/Auth';

export default defineComponent({
  name: 'MfaView',

  components: {
    MfaChooser,
  },

  props: {
    factors: {
      type: Array as PropType<MfaFactor[]>,
      required: true,
    },
    mfaType: {
      type: String,
      required: true,
    },
    shouldHideEmailBadge: Boolean,
  },

  setup() {
    const uiLabel = useMfaLabels();
    return { uiLabel };
  },

  computed: {
    availableFactors() {
      return this.factors.filter((factor) => factor.name !== this.mfaType);
    },

    hasAlternateFactorsAvailable() {
      return this.factors.length > 1;
    },

    hasSelectedFactor() {
      return !!this.mfaType;
    },

    shouldShowDivider() {
      return this.hasAlternateFactorsAvailable && this.hasSelectedFactor;
    },

    message() {
      return this.uiLabel.chooser.help;
    },
  },

  methods: {
    handleChangeFactor(factorName: string) {
      const factor = this.factors.find((f) => factorName === f.name);

      if (!factor) {
        return;
      }

      const route = {
        ...factor.route,
        params: {
          ...factor.route.params,
          from: this.$route.query.mfaType,
        },
      };

      this.$router.replace(route);

      // Clear any error that may have occurred in a different factor
      this.$emit('resetErrorMessage');
    },
  },
});
