<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <h1
      :class="$style.heading"
    >
      Set Up Verification Code (TOTP) MFA
    </h1>
    <div :class="$style.body">
      <div :class="$style.mainContent">
        <AdminTotpSetupStep
          :number="2"
          title="Add Your Account"
        >
          <template slot="adminTotpSetupStepMessage">
            <p>
              Scan the QR code to retrieve the validation codes you will need to complete
              your configuration and verify your future logins to JumpCloud resources.
            </p>
          </template>
        </AdminTotpSetupStep>
        <AdminTotpSetupStep
          :number="3"
          title="Verify Your Account"
        >
          <template slot="adminTotpSetupStepMessage">
            <p>
              Enter the 6 digit verification code provided for "JumpCloud Admin"
              from your authenticator application.
            </p>
          </template>
        </AdminTotpSetupStep>
        <form :class="$style.totpCodeForm">
          <LoginAlert
            :message="error"
          />
          <LoginTextField
            :autocomplete="false"
            :class="$style.totpCode"
            @input="handleInput"
            :maxlength="6"
            placeholder="Enter Code"
            :submitOnEnter="handleSubmit"
            :value="totpCode"
            vvName="verification code"
          />
        </form>
      </div>
      <aside :class="$style.qrCodeCardContainer">
        <div :class="$style.qrCodeCard">
          <div :class="$style.qrCodeInstruction">
            Scan to add account
          </div>
          <QrCode
            clientType="admin"
            :isAdmin="true"
            :totp="totp"
          />
          <div :class="$style.totpKey">
            {{ totp }}
          </div>
        </div>
      </aside>
    </div>
    <div :class="$style.footer">
      <button
        :class="$style.backButton"
        @click="goBack"
        type="button"
      >
        Back
      </button>
      <LoginActionButton
        :class="$style.actionButton"
        :action="handleSubmit"
        :disabled="isDisabled"
        :isLoading="isLoading"
      >
        Submit
      </LoginActionButton>
    </div>
  </div>
</template>

<script>
import AdminTotpSetupStep from 'components/AdminTotpSetupStep.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginAlert from 'client/components/LoginAlert.vue';
import LoginTextField from 'client/components/LoginTextField.vue';
import QrCode from 'client/components/QrCode.vue';

export default {
  inject: ['$validator'],

  components: {
    AdminTotpSetupStep,
    LoginAlert,
    LoginActionButton,
    LoginTextField,
    QrCode,
  },

  props: {
    error: {
      type: String,
      default: undefined,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    totp: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isDisabled: true,
    totpCode: '',
  }),

  methods: {
    goBack() {
      this.$emit('onGoBack');
    },

    handleInput(value) {
      this.isDisabled = value.length !== 6;
      this.totpCode = value;
    },

    handleSubmit() {
      this.$emit('onSubmit', this.totpCode);
    },
  },
};

</script>

<style module>
@value (
  jcGrey900,
  jcInformational,
  jcPrimary50,
  jcWhite50
) from 'css/brand-colors.css';
@value (max-width-large) from 'css/breakpoints.css';

.heading {
  font-size: 1.4rem;
}

.body {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: var(--spacing) auto;
}

.mainContent {
  flex: 1;
}

.totpCodeForm {
  display: flex;
  flex-direction: column;
  margin: -0.6125rem 2.5rem 0;
}

.totpCode {
  margin: 0;
}

.qrCodeCardContainer {
  flex: 1;
}

.qrCodeCard {
  align-items: center;
  background-color: jcPrimary50;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 0;
}

.qrCodeInstruction {
  font-weight: 700;
}

.totpKey {
  color: jcInformational;
  margin-top: 1rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.backButton,
.actionButton {
  width: 12rem;
}

.backButton {
  background: jcWhite50;
  border: 1px solid jcGrey900;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 1.75rem;
}

.actionButton {
  margin: 0;
}

@media (max-width: max-width-large) {
  .body {
    flex-direction: column;
  }

  .totpCodeForm {
    margin-bottom: 2rem;
  }

  .backButton,
  .actionButton {
    flex: 1;
  }
}

</style>
