<!-- eslint-disable vue/no-mutating-props -->
<template>
  <form :class="$style.form">
    <LoginTextField
      label="Password"
      name="password"
      placeholder="Password"
      type="password"
      v-model="form.password"
      vvName="password"
      :rules="passwordFieldValidation"
      :submitOnEnter="submit"
    />
    <LoginTextField
      label="Confirm Password"
      name="confirm password"
      placeholder="Confirm Password"
      type="password"
      v-model="form.confirm"
      vvName="confirm password"
      :rules="confirmPasswordFieldValidation"
      :submitOnEnter="submit"
    />
    <div
      :class="$style.termsOfUse"
      v-if="showTermsOfService"
    >
      <div :class="$style.terms">
        <input
          :class="$style.licenseInput"
          data-vv-name="Terms of Service"
          name="Terms of Service"
          required
          type="checkbox"
          v-validate="'required'"
          v-model="form.license"
        >
        <span>
          I agree to the
          <a
            href="https://jumpcloud.com/legal"
            rel="noopener noreferrer"
            target="_blank"
            :class="$style.resetPasswordLink"
          >Terms of Service</a>
        </span>
        <div
          :class="$style.errorDisplay"
          v-if="errors.has('Terms of Service')"
        >
          <i :class="$style.errorDisplayPointer" />
          <i :class="['jc-actions-alert', $style.removeIcon]" />
        </div>
      </div>
    </div>
    <LoginActionButton
      :action="submit"
      :isLoading="isLoading"
      :disabled="disabled"
    >
      Set Password
    </LoginActionButton>
  </form>
</template>

<script>
import { extend, isEqual } from 'lodash';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginTextField from 'client/components/LoginTextField.vue';

/**
 * This form has the ability to v-model a form object with the following keys:
 * password, confirm, license
 * It can modify the license field based on whether or not the clientType is an admin or not.
 */
export default {
  name: 'ResetPassword',

  inject: ['$validator'],

  props: {
    clientType: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Function,
      required: true,
    },
  },
  components: {
    LoginActionButton,
    LoginTextField,
  },

  computed: {
    showTermsOfService() {
      return this.clientType === 'admin';
    },
    passwordFieldValidation() {
      const adminValidations = {};
      extend(adminValidations, this.adminFieldValidations);

      return this.clientType === 'admin' ? adminValidations : this.userFieldValidations;
    },

    confirmPasswordFieldValidation() {
      const adminValidations = { matchPassword: true };
      extend(adminValidations, this.adminFieldValidations);

      return this.clientType === 'admin' ? adminValidations : this.userFieldValidations;
    },

    adminFieldValidations() {
      return {
        required: true,
        min: 12,
        max: 64,
        noEmailPassword: true,
        noUserName: true,
        noWhitespace: true,
      };
    },

    userFieldValidations() {
      return {
        required: true,
        max: 64,
        matchPassword: true,
      };
    },
  },

  beforeMount() {
    this.$validator.extend('matchPassword', {
      getMessage: 'The password confirmation does not match.',
      validate: (value) => isEqual(value, this.form.password),
    });
    this.$validator.extend('noEmailPassword', {
      getMessage: 'Password may not contain your email address',
      validate: (value) => !value.toLowerCase().includes(this.form.email.toLowerCase()),
    });

    this.$validator.extend('noUserName', {
      getMessage: 'Password may not contain your username',
      validate: (value) => !value.toLowerCase().includes(this.form.email.split('@')[0].toLowerCase()),
    });

    this.$validator.extend('noWhitespace', {
      getMessage: 'Password cannot start or end with whitespace',
      validate: (value) => isEqual(value, value.trim()),
    });
  },
};
</script>

<style module>
@import '../../css/login-components.css';

.form {
  width: 100%;
}

.termsOfUse {
  padding: 20px 0;
  text-align: center;
}

.terms {
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.licenseInput {
  margin-right: 10px;
  position: relative;
}

.errorDisplay {
  font-size: 18px;
  padding: 10px 10px 8px;
}

.errorDisplayPointer {
  left: 0;
}
</style>
