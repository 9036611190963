export default {
  activityLogViewsAnnouncementDismissed: 'jcActivityLogViewsAnnouncementDismissed',
  adDistributionManagement: 'jcAdDistributionManagementDismissed',
  addLocalUserAccountModalAcknowledged: 'jcAddSystemUsernameModalAcknowledged',
  agentLogSavedData: 'jcAgentLogSavedData',
  applicationInstructionsDismissed: 'jcApplicationInstructionsDismissed',
  applicationsTabsTransitionAcknowledged: 'jcApplicationsTabsTransitionAcknowledged',
  authnpoliciesInstructionsSeen: 'jcAuthnpoliciesInstructionsSeen',
  bannerDismissed: 'jcBannerDismissed',
  bannerDismissedCommandsSurvey: 'jcBannerDismissedCommandsSurvey',
  bannerDismissedeventsApi: 'jcBannerDismissedeventsApi',
  bannerDismissedConditionalPoliciesForApplications: 'jcBannerDismissedConditionalPoliciesForApplications',
  bannerDismissedCustomizationSettings: 'jcBannerDismissedCustomizationSettingsv3',
  bannerDismissedFreeAccounts: 'jcbannerDismissedFreeAccounts',
  bindSystemToUserModalAcknowledged: 'jcBindSystemToUserModalAcknowledged',
  columnsDevicePageAnnouncementAcknowledged: 'jcColumnsDevicePageAnnouncementAcknowledged',
  customEmailAnnouncementSeen: 'jcCustomEmailAnnouncementSeen',
  deviceInstructionsDismissed: 'jcDeviceInstructionsDismissed',
  exportAnnouncementDeviceDismissed: 'jcExportAnnouncementDeviceDismissed',
  exportAnnouncementUserDismissed: 'jcExportAnnouncementUserDismissed',
  gettingStartedPopoverSeen: 'jcGettingStartedPopoverSeen',
  googleOrigin: 'jcLogin',
  groupsInstructionsDismissed: 'jcGroupsCollapsableDismissed',
  gsuiteInstructionsDismissed: 'jcGsuiteInstructionsDismissed',
  gsuiteDistributionManagement: 'jcGsuiteDistributionManagementDismissed',
  insightsInstructionsDismissed: 'jcInsightsInstructionsDismissed',
  localStorageOrgKey: 'login-ui-OrganizationId',
  loginModalSeen: 'jcLoginModalSeen',
  mdmBigSurReadiness: 'jcMdmBigSurReadiness',
  navPanelCollapsed: 'jcNavPanelCollapsed',
  navPanelSectionsCollapsed: 'jcNavPanelSectionsCollapsed',
  optimizelyVisitorId: 'visitorId',
  orgLogoUrl: 'jcLogoUrl',
  o365InstructionsDismissed: 'jcO365InstructionsDismissed',
  paymentPromptDismissed: 'jcPaymentPromptDismissed',
  previousMfaMethod: 'jcPreviousMfaMethod',
  radiusInstructionsDismissed: 'jcRadiusInstructionsDismissed',
  recommendedApplicationsCarouselDismissed: 'jcRecommendedApplicationsCarouselDismissed',
  recommendedCommandsCarouselDismissed: 'jcRecommendedCommandsCarouselDismissed',
  recommendedPoliciesCarouselDismissed: 'jcRecommendedPoliciesCarouselDismissed',
  recommendedPoliciesDismissed: 'jcRecommendedPoliciesDismissed',
  remoteWorkTipsPanelAnnouncementSeen: 'jcRemoteWorkTipsPanelAnnouncementSeen',
  reportsAnnouncementSeen: 'jcReportsAnnouncementSeen',
  reportsInstructionsDismissed: 'jcReportsInstructionsDismissed',
  resourcesPanelAnnouncementSeen: 'jcResourcesPanelAnnouncementSeen',
  scimGenericProvisioningBannerDissmissed: 'jcSCIMGenericProvisioningBannerDissmissed',
  scimGroupsSupportDismissed: 'jcIdentityManangementGroupsInfoBanner',
  systemInstructionsDismissed: 'jcSystemInstructionsDismissed',
  systemMainPanelColumns: 'jcSystemMainPanelColumns',
  systemUserMainPanelColumns: 'jcSystemUserMainPanelColumns',
  universityModalSeen: 'jcUniversityModalSeen',
  userGroupDistributionEmail: 'jcUserGroupDistributionEmailDismissed',
  userLoginEmail: 'jcUserLoginEmail',
  userPwdResetInfo: 'jcUserPwdResetInfo',
  userStateAsideAnnouncement: 'jcUserStateAsideAnnouncementDismissed',
  userStateMainAnnouncement: 'jcUserStateMainAnnouncementDismissed',
  uuid: 'jcUUID',
  vppAnnouncementPopoverDismissed: 'jcVppAnnouncementPopoverDismissed',
  welcomeModalSeen: 'jcWelcomeModalSeen',
  windowsAppModalSeen: 'jcWindowsAppModalSeen',
};
