
// Libraries
import { PropType, defineComponent } from 'vue';

// Components
import AuthView from 'client/containers/user/Views/AuthView.vue';
import ButtonLoginSwitch from 'client/components/ButtonLoginSwitch.vue';
import DuoRedirectHandler from 'client/containers/user/login/mfa/DuoRedirectHandler.vue';
import JumpcloudGoHandler from 'client/containers/user/SsoStepUp/JumpcloudGoHandler.vue';
import MfaView from 'client/containers/user/Views/MfaView.vue';
import PushUserLoginHandler from 'client/containers/user/login/mfa/PushUserLoginHandler.vue';
import TotpUserLoginHandler from 'client/containers/user/login/mfa/TotpUserLoginHandler.vue';
import WebAuthnUserLoginHander from 'client/containers/user/login/mfa/WebAuthnUserLoginHandler.vue';

// Constants and Types
import { LoginConsts } from 'client/util/Constants';
import { useLoginLabels } from 'client/util/Constants/LoginLabels';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import MfaConstants from 'client/containers/Login/MfaConstants';
import type { MfaFactor } from 'client/types/Auth';

// Utils
import RequestError from 'client/services/response/RequestError';
import getCurrentComponentNameFromList from 'client/containers/user/MfaComponents/mfaUtils/getCurrentComponentNameFromList';
import getLastUsedFactor from 'client/containers/user/MfaComponents/mfaUtils/getLastUsedFactor';
import routerService from 'client/services/routerService';

// Store
import { mapActions } from 'vuex';

const { responseMessages } = LoginConsts;
const { mfaNames } = MfaConstants;
const {
  loginError,
} = responseMessages;

const componentsUnderControl = [
  {
    id: mfaNames.duo,
    componentName: DuoRedirectHandler.name,
  },
  {
    id: mfaNames.durt,
    componentName: JumpcloudGoHandler.name,
  },
  {
    id: mfaNames.durt_uv,
    componentName: JumpcloudGoHandler.name,
  },
  {
    id: mfaNames.totp,
    componentName: TotpUserLoginHandler.name,
  },
  {
    id: mfaNames.webauthn,
    componentName: WebAuthnUserLoginHander.name,
  },
  {
    id: mfaNames.push,
    componentName: PushUserLoginHandler.name,
  },
];

const getCurrentComponentName = getCurrentComponentNameFromList(componentsUnderControl);

export default defineComponent({
  name: 'SsoStepUpController',

  components: {
    AuthView,
    DuoRedirectHandler,
    ButtonLoginSwitch,
    JumpcloudGoHandler,
    MfaView,
    PushUserLoginHandler,
    TotpUserLoginHandler,
    WebAuthnUserLoginHander,
  },

  data() {
    return {
      // using undefined so we display 0
      errorMessageDisplayDuration: undefined as number | undefined,
    };
  },

  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    factors: {
      type: Array as PropType<MfaFactor[]>,
      required: true,
    },
    mfaType: {
      type: String,
      default: '',
    },
  },

  setup() {
    const loginLabels = useLoginLabels();
    const mfaLabels = useMfaLabels();

    return {
      loginLabels,
      mfaLabels,
    };
  },

  computed: {
    componentHandlers() {
      return {
        // Used in: Push
        clearCredentials: this.clearCredentials,
        // Used in: Totp, Push, Duo, WebAuthN
        error: this.onMfaError,
        // Used in: Push, WebAuthN
        resetErrorMessage: this.resetErrorMessage,
        // Used in: Totp, Go, Push, Duo, WebAuthN
        success: this.onSuccess,
      };
    },

    componentName() {
      return getCurrentComponentName(this.mfaType);
    },

    componentProps() {
      return {
        // Push
        controllingQueryParams: {},
        // Push
        hasPendingErrorMessage: this.hasPendingErrorMessage,
        // Go
        mfaType: this.mfaType,
      };
    },

    hasPendingErrorMessage() {
      const remainingDuration = this.errorMessageDisplayDuration || -1;
      return remainingDuration >= 0;
    },

    headerText() {
      return this.isJcGoMfa ? '' : this.mfaLabels.verifyIdentity;
    },

    isJcGoMfa() {
      const goMfaTypes = [mfaNames.durt, mfaNames.durt_uv];
      return goMfaTypes.includes(this.mfaType);
    },
  },

  created() {
    this.validateInitialStep();
  },

  methods: {
    ...mapActions('StepUpModel', [
      'setStateId',
    ]),

    clearCredentials() {
      // clearing the state id allows the user to authenticate through push after denial
      this.setStateId('');
    },

    handleErrorCountdown() {
      const errorCountdown = setInterval(() => {
        if ((this.errorMessageDisplayDuration as number) < 1) {
          this.resetErrorMessage();
          clearInterval(errorCountdown);
        }

        (this.errorMessageDisplayDuration as number) -= 1;
      }, 1000);
    },

    onMfaError(error: any) {
      const errorMessage = error instanceof RequestError ? error.message : loginError;
      this.errorMessageDisplayDuration = error instanceof RequestError
        ? error.displayDuration : undefined;

      if (this.errorMessageDisplayDuration && this.errorMessageDisplayDuration > 0) {
        this.handleErrorCountdown();
      }

      // Indicates invalid otp attempts > max attempts
      if (error?.status === 403) {
        this.clearCredentials();
        routerService.goToUserLogin({
          params: { error: errorMessage },
        });
      } else {
        this.setErrorMessage(errorMessage);
      }
    },

    resetErrorMessage() {
      this.errorMessageDisplayDuration = undefined;
      this.$emit('resetErrorMessage');
    },

    onSuccess() {
      // no-op: Redirect triggered by axios response interceptor
    },

    setErrorMessage(message: string) {
      this.$emit('setErrorMessage', message);
    },

    validateInitialStep() {
      const { mfaType } = getLastUsedFactor(this.factors);
      const lastFactor = this.factors.find(
        (factor) => factor.name === mfaType,
      );

      if (lastFactor) {
        this.$router.replace(lastFactor.route);
      }
    },
  },
});
