<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :class="$style.loadingIcon" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M27.662 6.749a3.242 3.242 0 11-6.336 1.38 3.242 3.242 0 016.336-1.38zm-7.974-2.806a2.976 2.976 0 11-5.816 1.268 2.976 2.976 0 015.816-1.268zm-7.81 2.435a2.71 2.71 0 11-5.296 1.156 2.71 2.71 0 015.296-1.156zm-4.661 6.484a2.444 2.444 0 11-4.778 1.042 2.444 2.444 0 014.779-1.042zm-.115 7.566a2.179 2.179 0 11-4.258.928 2.179 2.179 0 014.258-.928zm3.06 4.927a1.913 1.913 0 11-3.739.815 1.913 1.913 0 013.739-.815zm5.634 3.046a1.648 1.648 0 11-3.22.702 1.648 1.648 0 013.22-.702zm6.654-.596a1.382 1.382 0 11-2.7.59 1.382 1.382 0 012.7-.59zm5.515-4.633a1.116 1.116 0 11-2.182.474 1.116 1.116 0 012.182-.474zm1.632-7.24a.85.85 0 11-1.662.362.85.85 0 011.662-.362z"/></svg>
</template>

<script>
export default {
  name: 'LoadingIcon',
};

</script>

<style module>
.loadingIcon {
  animation: spin 2s infinite linear;
  width: 100%;
}

.loadingIcon:focus { outline: none; }

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
