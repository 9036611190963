/* eslint sort-imports-es6-autofix/sort-imports-es6: 0 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Include global css that applies to all login pages
import 'css/fonts.css';
import 'css/login.css';

import { createPinia, PiniaVuePlugin } from 'pinia';
import Router from 'client/routes/Router';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import VueRouter from 'vue-router';

import { onReady } from 'client/util/initializationHandler';
import StatusBanner from 'client/containers/Login/StatusBanner.vue';
import LoadErrorComponent from 'components/LoadError.vue';
import { datadogRum } from '@datadog/browser-rum-slim';
import loginStore from './stores/loginStore';

// Integrate global Vue packages
Vue.use(VeeValidate, { classes: true, strict: false });
Vue.use(VueRouter);
Vue.use(PiniaVuePlugin);
Vue.config.errorHandler = (error, vm, info) => {
  const type = info;
  const context = { type };

  datadogRum.addError(error, context);
  // eslint-disable-next-line no-console
  console.error(error);
};

const pinia = createPinia();
const StatusBannerClass = Vue.extend(StatusBanner);

onReady(() => {
  loginStore.dispatch('FeatureFlagsModule/fetchFeatureFlags').finally(() => {
    const bannerInstance = new StatusBannerClass();
    bannerInstance.$mount('.banner');

    new Vue({
      template: '<router-view/>',
      router: Router,
      store: loginStore,
      pinia,
    }).$mount('.login-content');
  }).catch(() => {
  // Vue error page
    new Vue(LoadErrorComponent).$mount('.login-content');
  });
});
