import { render, staticRenderFns } from "./ButtonAnchor.vue?vue&type=template&id=9324c088&scoped=true&"
import script from "./ButtonAnchor.vue?vue&type=script&lang=js&"
export * from "./ButtonAnchor.vue?vue&type=script&lang=js&"
import style0 from "./ButtonAnchor.vue?vue&type=style&index=0&id=9324c088&prod&module=true&scoped=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9324c088",
  null
  
)

export default component.exports