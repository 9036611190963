<template>
  <PasswordResetSteps
    :steps="steps"
    :image="macResetImg"
  />
</template>

<script>
import PasswordResetSteps from 'client/components/PasswordResetSteps.vue';
import macResetImg from 'public/img/app-animation.gif';

export default {
  name: 'MacPasswordReset',

  components: {
    PasswordResetSteps,
  },

  data() {
    return {
      macResetImg,
      steps: [
        {
          step: 1,
          text: 'Click the menu bar app',
        },
        {
          step: 2,
          text: 'Fill in password fields*',
        },
        {
          step: 3,
          text: 'Click "Save"',
        },
      ],
    };
  },
};
</script>
