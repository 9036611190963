import { StatusPageConsts } from 'client/util/Constants';
import { filter, find, some } from 'lodash';

const {
  componentIds,
  componentStatuses,
} = StatusPageConsts;

const {
  ADMIN_PORTAL,
  GENERAL_ACCESS_API,
  SSO,
  USER_PORTAL,
} = componentIds;

const {
  OPERATIONAL,
  DEGRADED,
  PARTIAL_OUTAGE,
  MAJOR_OUTAGE,
  MAINTENANCE,
} = componentStatuses;

const statusConditions = [
  {
    key: 'major',
    check: (components) => some(components, ['status', MAJOR_OUTAGE]),
    status: MAJOR_OUTAGE,
    indicator: 'error',
  },
  {
    key: 'partial',
    check: (components) => some(components, ['status', PARTIAL_OUTAGE]),
    status: PARTIAL_OUTAGE,
    indicator: 'warning',
  },
  {
    key: 'degraded',
    check: (components) => some(components, ['status', DEGRADED]),
    status: DEGRADED,
    indicator: 'warning',
  },
  {
    key: 'maintenance',
    check: (components) => some(components, ['status', MAINTENANCE]),
    status: MAINTENANCE,
    indicator: 'warning',
  },
  {
    key: 'operational',
    check: () => true,
    status: OPERATIONAL,
    indicator: '',
  },
];

const getStatus = (components = []) => {
  const { status, indicator } = find(statusConditions, (condition) => condition.check(components));

  return { status, indicator };
};

const fromApi = {
  getStatuses: (data) => {
    const adminIds = [GENERAL_ACCESS_API, ADMIN_PORTAL];
    const userIds = [GENERAL_ACCESS_API, SSO, USER_PORTAL];

    const adminPortalComponents = filter(
      data.components,
      (component) => adminIds.includes(component.id),
    );
    const userPortalComponents = filter(
      data.components,
      (component) => userIds.includes(component.id),
    );

    return {
      admin: getStatus(adminPortalComponents),
      user: getStatus(userPortalComponents),
    };
  },
};

export default fromApi;
