import { Location } from 'vue-router';
import { UserRoutes } from 'client/routes/RouteGenerator';
import router, { LOGIN_CHALLENGE_KEY } from 'client/routes/Router';

const { resetPassword, resetPasswordUserForm } = UserRoutes;

export default {
  goToAdminLogin(location: Location = {}): void {
    // Note: grabs any OIDC login challenge that is
    // stored on the session when navigating back to login
    const query = {
      ...location.query,
    };

    const loginChallenge = window.sessionStorage.getItem(LOGIN_CHALLENGE_KEY);
    if (loginChallenge) {
      query.login_challenge = loginChallenge;
    }

    router.push({ name: 'Admin Login', ...location, query });
  },
  goToUserLogin(location: Location = {}): void {
    router.push({ name: 'Login', ...location });
  },
  replaceToUserLogin(location: Location = {}): void {
    router.replace({ name: 'Login', ...location });
  },
  goToUserPasswordResetRequest(location: Location = {}): void {
    router.push({ path: resetPassword, ...location });
  },
  goToUserPasswordResetForm(location: Location = {}): void {
    router.push({ path: resetPasswordUserForm, ...location });
  },
};
