export default {
  mfaStatus: {
    available: 'available',
    unavailable: 'unavailable',
    not_configured: 'not_configured',
  },

  mfaNames: {
    duo: 'duo',
    durt: 'durt',
    durt_uv: 'durt_uv',
    push: 'push',
    totp: 'totp',
    webauthn: 'webauthn',
  },
};
