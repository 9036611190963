<template>
  <a
    :class="$style.button"
    :href="href"
  >
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'ButtonAnchor',

  props: {
    href: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style module scoped>
@value (jcPrimary, jcPrimary700, jcGrey900) from 'css/brand-colors.css';

.button {
  background-color: jcPrimary;
  border: 0.125rem solid transparent;
  border-radius: 0.3125rem;
  color: jcGrey900;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  min-width: 6rem;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.button:hover,
.button:active {
  background-color: jcPrimary700;
}
</style>
