import { webAuthnError, webAuthnUnsupportedError } from 'client/services/response/WebAuthnErrors';
import { webAuthnSupported } from 'client/util/FeatureSupport';
import UserLoginApi from 'client/api/UserLoginApi';
import webAuthnFormatting from 'client/services/webAuthnFormatting';

export default {
  async getCredentials(publicKey: PublicKeyCredentialRequestOptions): Promise<Credential> {
    const credential = await navigator.credentials.get({ publicKey });

    if (credential === null) {
      throw webAuthnError;
    }

    return credential;
  },

  // eslint-disable-next-line max-len
  async getChallenge(challenge?: Challenge): Promise<{ publicKey: EncodedPublicKeyCredential, token: string }> {
    try {
      const { publicKey, token } = challenge || await UserLoginApi.getWebAuthnChallenge();

      return {
        publicKey: webAuthnFormatting.encodePublicKey(publicKey),
        token,
      };
    } catch (error) {
      throw webAuthnError;
    }
  },

  // New methods to separate login from password reset
  async prepareLoginRequest():
    Promise<{
      publicKeyCredential: DecodedPublicKeyCredential,
      token: string,
    }> {
    if (!webAuthnSupported()) throw webAuthnUnsupportedError;

    try {
      // Get challenge from the api
      const { publicKey, token } = await this.getChallenge();

      // Get the signed Credential from the key
      const credential = await this.getCredentials(publicKey);
      const publicKeyCredential = webAuthnFormatting.decodePublicKeyCredential(credential);

      return {
        publicKeyCredential,
        token,
      };
    } catch {
      throw webAuthnError;
    }
  },

  async preparePasswordResetRequest(challenge: Challenge):
    Promise<{
      publicKeyCredential: DecodedPublicKeyCredential,
      token: string,
    }> {
    if (!webAuthnSupported()) throw webAuthnUnsupportedError;

    try {
      const { publicKey, token } = challenge;
      const credential = await this.getCredentials(webAuthnFormatting.encodePublicKey(publicKey));

      return {
        publicKeyCredential: webAuthnFormatting.decodePublicKeyCredential(credential),
        token,
      };
    } catch (error) {
      throw webAuthnError;
    }
  },
};
