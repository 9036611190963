/**
 * This wrapper around localStorage exists so that localStorage can easily be mocked in unit
 * tests by mocking the methods of this module.
 */
export default {
  getItem(keyName) {
    try {
      return window.localStorage.getItem(keyName);
    } catch {
      return null;
    }
  },

  getValue(keyName) {
    let localStorageValue;

    try {
      localStorageValue = window.localStorage.getItem(keyName);
    } catch {
      localStorageValue = null;
    }

    let value = localStorageValue;

    if (localStorageValue === 'true') value = true;
    if (localStorageValue === 'false') value = false;

    return value;
  },

  removeItem(keyName) {
    try {
      return window.localStorage.removeItem(keyName);
    } catch {
      return null;
    }
  },

  setItem(keyName, keyValue) {
    try {
      return window.localStorage.setItem(keyName, keyValue);
    } catch {
      return null;
    }
  },
};
